// TODO
// If more languages appart from ES and EN were to be added to the translations dictionary,
// the current structure would not be scalable. A better approach would be to have all keys under each
// language ({'en': { a1317Users: '13-17', ... }, 'es': { a1317Users: '13-17', ... }, ...}), and the
// app would only load the dictionary for the current user's language, thus avoiding the load of all
// languages, when only one of them is being used.
const dataText = {
  '+15Impacts': {es: '+15 impactos', en:'+15 impacts'},
  '1-2Impacts': {es: '1 - 2 impactos', en: '1 - 2 impacts'},
  '12-17': { es: '13-17', en: '13-17' },
  '3-6Impacts': { es: '3 - 6 impactos', en: '3 - 6 impacts' },
  '7-15Impacts': { es: '7 - 15 impactos', en: '7 - 15 impacts' },
  a1317Users: { es: '13-17', en: '13-17' },
  a1825Users: { es: '18-25', en: '18-25' },
  a2640Users: { es: '26-40', en: '26-40' },
  a4155Users: { es: '41-55', en: '41-55' },
  a55Users: { es: '+55', en: '+55' },
  accept: {es: 'Aceptar', en:'Accept'},
  acceptPropose: { es: 'Aceptar propuesta', en: 'Accept propose' },
  actionConfirmMsg: {es: '¿Seguro que desea realizar esta acción?', en: 'Are you sure you want to do this?'},
  activate: { es: 'Activar', en: 'Activate' },
  activatedSectors: { es: 'Sectores activados', en: 'Activated sectors' },
  activateOrderLineConfirm: { es: '¿Seguro que desea activar esta linea de pedido?', en: 'Are you sure you want to activate this order line?' },
  active: { es: 'Activo', en: 'Active' },
  activeAudiences: { es: 'Audiencias activadas este mes', en: 'Audiences activated this month' },
  activeCampaigns: { es: 'Campañas activas', en: 'Active campaigns' },
  add: {es: 'Añadir', en: 'Add'},
  addBoxes: { es: 'Publicestas', en: 'Advertising boxes' },
  addCircuit: { es: 'Añadir circuito', en: 'Add circuit' },
  addFilter: { es: 'Aplicar filtros guardados', en: 'Apply saved filters' },
  addPois: { es: 'Añadir puntos de interés', en: 'Add POIs' },
  address: { es: 'Dirección', en: 'Address' },
  addSelection: {es: 'Añadir selección', en: 'Add selection'},
  addSchedule: { es: 'Añadir horario', en: 'Add schedule' },
  addSupports: { es: 'Añadir soportes', en: 'Add supports' },
  addSupportsToSelection: { es: 'Añade soportes a la selección', en: 'Add supports to selection' },
  addToCompare: {es: 'Añadir a la comparación', en: 'Add to compare'},
  addToNewCircuit: { es: 'Añadir a circuito nuevo', en: 'Add to new circuit' },
  addToExistingCircuit: { es: 'Añadir a circuito existente', en: 'Add to existing circuit' },
  addToInventory: { es: 'Añadir a inventario', en: 'Add to inventory' },
  addToInventoryText: { es: 'Este area aparecerá listada en inventario', en: 'This area will be listed in inventory' },
  adFormat: { es: 'Formato de anuncio', en: 'Ad format' },
  aditionalServices: { es: 'Servicios adicionales', en: 'Aditional services' },
  adServer: { es: 'Servidor anuncios', en: 'Ad server' },
  adTagType: { es: 'Tipo de tag de anuncio', en: 'Ad tag type' },
  adTagCode: { es: 'Código de tag de anuncio', en: 'Ad tag code' },
  advanced: { es: 'Avanzado', en: 'Advanced' },
  averageFrequency: { es: 'Frecuencia media de impactos', en: 'Average frequency of impacts' },
  advertiser: { es: 'Anunciante', en: 'Advertiser' },
  advertiserCategory: { es: "Categoría Anunciante", en: "Advertiser Category" },
  advertiserName: { es: 'Anunciante', en: 'Advertiser' },
  advertisers: { es: 'Anunciantes', en: 'Advertisers' },
  advertisersCampaigns: { es: 'Campañas por anunciantes', en: 'Campaigns per advertisers' },
  advertisesGroup: { es: 'Grupo de anuncios', en: 'Advertises group' },
  advertisingExposition: { es: 'Exposición publicitaria', en: 'Advertising exposition' },
  advertisingProfile: { es: 'Perfil publicitario', en: 'Advertising profile' },
  adultAlone: { es: 'Adulto solo', en: 'Adult alone' },
  adultCouple: { es: 'Pareja adultos', en: 'Adult couple' },
  affinity: { es: 'Afinidad', en: 'Affinity' },
  affinityAnalysis: { es: 'Análisis de afinidad', en: 'Affinity analysis' },
  AffinityBasedOnFilter: {es: 'Afinidad basada en el filtro', en: 'Affinity based on filter'},
  affinityDataTooltip: { es: 'Porcentaje del target inicial que ha pasado por el circuito de exterior y ha sido impactado', en: 'Percentage of the initial target that has passed through the outdoor circuit and has been impacted' },
  affinityValues: {
    max: { es: 'Máxima', en: 'Max' },
    optimal: { es: 'Óptima', en: 'Optimal' },
    medium: { es: 'Media', en: 'Medium' },
    low: {es: 'Baja', en: 'Low'}
  },
  after: {es: 'Después de', en:'After'},
  age: { es: 'Edad', en: 'Age' },
  ageBreakout: { es: 'Division por edades', en: 'Age Breakout' },
  agencies: { es: 'Agencias', en: 'Agencies' },
  agenciesCampaigns: { es: 'Campañas por agencias', en: 'Campaigns per agencies' },
  agency: { es: 'Agencia', en: 'Agency' },
  agencyAndAdvertiser: {es: 'Agencia y anunciante', en: 'Agency and advertiser'},
  agencyName: {es: 'Agencia', en: 'Agency'},
  ages: { es: 'Edades', en: 'Ages' },
  airport: { es: 'Aeropuerto', en: 'Airport' },
  AIRPORT: { es: 'Aeropuerto', en: 'Airport' },
  al: {es: 'Al', en: 'Until'},
  all: { es: 'Todos', en: 'All' },
  alcoholRestriction: { es: 'Restricción de alcohol', en: 'Alcohol restriction' },
  analizeAudienceWithFunnel: {es: 'Analiza tus audiencias con funnels de conversión', en:'Analize your audiences with conversion funnels'},
  apply: {es: 'Aplicar', en: 'Apply'},
  appNumber: { es: 'Nº Apps', en: 'Nº Apps' },
  apps: { es: 'Apps', en: 'Apps' },
  appsCategories: { es: 'Categorías de apps', en: 'Apps categories' },
  apiName: { es: 'Nombre de Api', en: 'Api name' },
  area: { es: 'Área', en: 'Area' },
  areaData: { es: 'Datos del área', en: 'Area data' },
  areaPopulation: { es: 'Población estimada del área', en: 'Area estimated population' },
  areas: { es: 'Áreas', en: 'Areas' },
  asignAgencyPrice: {es:'Asignar precio agencia', en: 'Asign agency price'},
  asignRestrictions: { es: 'Asignar restricciones', en: 'Asign restrictions' },
  asociatedZones: { es: 'Crear zonas asociadas', en: 'Create asociated zones' },
  assetCreationError: { es: 'Ha ocurrido un error creando uno de los activos', en:'An error ocurred creating one of the assets'},
  asterikFieldsRequired: {es: 'Los campos con * son obligatorios', en: 'Fields with * are required'},
  attribution: { es: 'Atribución', en: 'Attribution' },
  attributionEdition: { es: 'Edición de modelo de atribución', en: 'Attribution model edition' },
  attributionModels: {es: 'Modelos de atribución', en: 'Attribution models'},
  attributions: { es: 'Atribuciones', en: 'Attributions' },
  audience: { es: 'Audiencia', en: 'Audience' },
  audienceCoverage: {es: 'Cobertura de audiencia', en: 'Audience coverage'},
  audienceForActivation: { es:'Audiencias creadas para activar campaña', en:'Audiences created for campaign activation'},
  audiences: {es: 'Audiencias', en: 'Audiences'},
  audiencesDashboard: {es:'Dashboard de audiencias', en: 'Audiences dashboard'},
  audiencesHistoric: { es: 'Histórico de audiencias', en: 'Audiences historic' },
  audiencesList: { es: 'Listado de audiencias', en: 'Audiences list' },
  audiencesOverlapScore: { es: 'Overlap score de audiencias', en: 'Audiences overlap score' },
  audienceProfile: { es: 'Perfil de audiencia', en: 'Audience profile' },
  audiencesAnalysis: {es: 'Análisis de audiencias', en: 'Audiences analysis'},
  audienceType: { es: 'Tipo de audiencia', en: 'Audience type' },
  audienceVolume: { es: 'Vol. Audiencia', en: 'Audience Vol.' },
  availability: { es: 'Disponibilidad', en: 'Availability' },
  availableCampaignBudget: { es: 'Presupuesto de campaña disponible', en: 'Available campaign budget' },
  average: { es: 'Media', en: 'Average' },
  averagePassers: { es: 'Media de transeúntes', en: 'Average passers by' },
  averagePrice: {es: 'Precio medio', en: 'Average price'},
  averageTimeMin: { es: 'Tiempo medio (min)', en: 'Average time (min)' },
  averageUsersDetection: { es: 'Detección media de usuarios', en: 'Average users detection' },
  averageVisitors: {es: 'Media de visitantes', en: 'Average visitors'},
  background: {es: 'Fondo', en: 'Background'},
  bandwidth: { es: 'Bandwidth', en: 'Bandwidth' },
  banner: {es: 'Banner', en: 'Banner'},
  base: { es: 'Base', en: 'Base' },
  basic: {es: 'Básico', en: 'Basic'},
  basicSelection: {es: 'Selección básica', en: 'Basic selection'},
  before: {es: 'Antes de', en:'Before'},
  behavioralProfile: { es: 'Perfil comportamental', en: 'Behavioral profile' },
  benchmark: {es: 'Benchmark', en: 'Benchmark'},
  bestCampaignsPerformance: { es: 'Campañas activas con mejor performance', en: 'Top active performing campaigns' },
  bestDay: { es: 'Mejor día', en: 'Best day' },
  bestHour: { es: 'Mejor hora', en: 'Best hour' },
  bestPerformance: { es: 'Mejor performance', en: 'Best performance'},
  bestWeekDay: {es: 'Mejor día de la semana', en: 'Best week day'},
  bidPrice: { es: 'Precio de puja', en: 'Bid price' },
  big: {es: 'Grande', en:'Big'},
  billboard: {es: 'Billboard', en:'Billboard'},
  billboardsDiagonal: {es: 'Diagonal del soporte', en: "Support's diagonal"},
  billboardsDistance: {es: 'Distancia vallas', en: "Billboard's distance"},
  billboardType: { es: 'Tipo de soporte', en: 'Billboard type' },
  billTo: { es: 'Facturar a ', en: 'Bill to' },
  brandAttributionEvolution: { es: 'Evolución de atribución de marca', en: 'Brand attribution evolution' },
  brandAttributionHistoric: { es: 'Histórico de atribución de marca', en: 'Brand attribution historic' },
  brandAttributionPercentageVariation: { es: 'Variación porcentual de atribución de marca', en: 'Brand attribution percentage variation' },
  brandConsiderationEvolution: { es: 'Evolución de consideración de marca', en: 'Brand consideration evolution' },
  brandConsiderationHistoric: { es: 'Histórico de consideración de marca', en: 'Brand consideration historic' },
  brandConsiderationPercentageVariation: { es: 'Variación porcentual de consideración de marca', en: 'Brand consideration percentage variation' },
  brandEquity: { es: 'Brand equity', en: 'Brand equity'},
  brandResearch: {
    peopleFlowToSalesPoint: { es: 'De flujo de personas a tu PdV', en: 'flow of people to your Sales Point' },
    searchVolume: { es: 'De volumen de búsquedas', en: 'Searches volume' },
    overYourHistoricsFrom: { es: 'por encima de tus históricos de', en: 'over your historics from' },
    preCampaign: { es: 'PRE CAMPAÑA', en: 'PRE CAMPAIGN' },
    campaign: { es: 'CAMPAÑA', en: 'CAMPAIGN' },
    postCampaign: { es: 'POST CAMPAÑA', en: 'POST CAMPAIGN' },
  },
  brandResearchTool: { es: 'Brand Research Tool', en: 'Brand Research Tool' },
  briefing: { es: 'Briefing', en: 'Briefing' },
  browser: { es: 'Navegador', en: 'Browser' },
  browserVersion: { es: 'Versión del navegador', en: 'Browser version' },
  budget: { es: 'Presupuesto', en: 'Budget' },
  budgetChangeFrom: {es: 'El presupuesto cambia de', en: 'Budget changes from'},
  budgetConsumed: {es:'Presupuesto consumido', en: 'Consumed budget'},
  budgetType: { es: 'Tipo de presupuesto', en: 'Budget type' },
  bulkFileLabel: { es: 'Archivo zip/xls/txt', en: 'zip/xls/txt file' },
  BUS_STATION: { es: 'Estación de Autobuses', en: 'Bus Station' },
  buyModel: { es: 'Modelo de compra', en: 'Buy model' },
  byCity: {es: 'Por ciudad', en: 'By city'},
  byDay: { es: 'Por día', en: 'By day' },
  byHour: { es: 'Por hora', en: 'By hour' },
  byMonth: {es: 'Por mes', en: 'By month'},
  category: { es: 'Categoría', en: 'Category' },
  calculate: { es: 'Calcular', en: 'Calculate' },
  calculateAudienceConfirm: { es: '¿Seguro que desea calcular esta audiencia?', en: 'Are you sure you want to calculate this audience?' },
  calculateCampaign: {es: 'Publicar campaña', en: 'Publish campaign'},
  calculateCampaignConfirm: { es: '¿Confirma que deseas publicar esta campaña?', en: 'Confirm you want to publish this campaign?' },
  calculateDoohCampaignConfirm: { es: '¿Seguro que desea generar el reporting de esta campaña?', en: 'Are you sure you want to generate the reporting of this campaign?' },
  campaignAttribution: { es: 'Atribución de campaña', en: 'Campaign attribution' },
  campaignLine: {
    budget: { es: 'Presupuesto', en: 'Budget' },
    campaign: {
      advertiser: {
        agency: {
          name: {es: 'Agencia', en: 'Agency'}
        }
      }
    },
    en: 'Campaign line',
    es: 'Linea de campaña',
    name: { es: 'Nombre', en: 'Name' },
    startDate: { es: 'Fecha de inicio', en: 'Start date' },
    endDate: { es: 'Fecha de fin', en: 'End date' }

  },
  calculateSuccess: { es: 'Se ha calculado correctamente', en: 'Calculation success' },
  campaign: { es: 'Campaña', en: 'Campaign' },
  campaignBudget: {es: 'Presupuesto de campaña', en: 'Campaign budget'},
  campaignConfig: { es: 'Configuración de la campaña', en: 'Campaign configuration'},
  campaignData: {es: 'Datos de la campaña', en: 'Campaign data'},
  campaignDelivery: {es: 'Distribución de la campaña', en: 'Campaign delivery'},
  campaignDistribution: { es: 'Distribución de la campaña', en: 'Campaign delivery' },
  campaignDoubleImpact: { es: 'Campaña doble impacto', en: 'Double impact campaign' },
  campaignGlobalAttribution: { es: 'Atribución global de la campaña', en: 'Campaign global attribution' },
  campaignLineCreate: { es: 'Crear nuevo grupo de anuncios', en: 'Create new campaign line' },
  campaignLineCreateGuaranteed: { es: 'Crear nuevo grupo de anuncios garantizados', en: 'Create new campaign line guaranteed' },
  campaignLineCreateNotGuaranteed: { es: 'Crear nuevo grupo de anuncios no garantizados', en: 'Create new campaign line not guaranteed' },
  campaignLineFilter: {es:'Filtrado de grupo de anuncios', en: 'Campaign line filter'},
  campaignName: { es: 'Nombre de la campaña', en: 'Campaign name' },
  campaignReporting: { es: 'Reporte de campaña', en: 'Campaign reporting' },
  campaignResults: { es: 'Resultados de la campaña', en: 'Campaign results' },
  campaignReportGraphs: {
    impressions: { es: 'Impresiones', en: 'Impressions' },
    clicks: { es: 'Clicks', en: 'Clicks' },
    ctr: { es: 'CTR', en: 'CTR' },
    vtr: { es: 'VTR', en: 'VTR' },
    video_completes: { es: 'Vídeo finalizado', en: 'Video completes' },
    videoCompletes: { es: 'Vídeo finalizado', en: 'Video completes' },
    video_q1s: { es: 'Q1', en: 'Q1' },
    videoQ1s: { es: 'Q1', en: 'Q1' },
    video_midpoints: { es: 'Q2', en: 'Q2' },
    videoMidpoints: { es: 'Q2', en: 'Q2' },
    video_q3s: { es: 'Q3', en: 'Q3' },
    videoQ3s: { es: 'Q3', en: 'Q3' },
  },
  campaignRetargeting: { es: 'Campaña retargeting', en: 'Retargeting campaign' },
  campaignObjective: { es: 'Objetivo de campaña', en: 'Campaign objective' },
  campaignProfile: { es: 'Perfil de campaña', en: 'Campaign profile' },
  campaigns: { es: 'Campañas', en: 'Campaigns' },
  campaignsDashboard: {es: 'Dashboard de campañas', en:'Campaigns dashboard'},
  campaignsList: { es: 'Listado de campañas', en: 'Campaigns list' },
  campaignsSize: { es: 'Número de campañas', en: 'Campaigns Size' },
  campaignStatus: {es: 'Estado', en: 'Status'},
  campaignDoohStatus: {
    DRAFT: { es: 'Borrador', en: 'Draft' },
    PROPOSED: { es: 'Propuesta', en: 'Proposed' },
    READY: { es: 'Preparada', en: 'Ready' },
    RUNNING: { es: 'Corriendo', en: 'Running' },
    FINISHED: { es: 'Terminada', en: 'Finished' },
    PENDING_CREATIVITY: { es: 'Pendiente de creatividad', en: 'Pending creative' },
    PENDING_PLANNING: { es: 'Pendiente de planificación', en: 'Pending planning' },
    STOP_REQUESTED: { es: 'Parada solicitada', en: 'Stop requested' },
    STOPPED: { es: 'Parada', en: 'Stopped' },
    REJECTED: { es: 'Rechazada', en: 'Rejected' },
    PLANNED: { es: 'Planificada', en: 'Planned' }
  },
  campaignDooh: {
    volumeServed: { es: 'Impresiones servidas', en: 'Volume served' },
  },
  campaignTotal: {es:'Total campaña', en: 'Campaign total'},
  campaignValidation: {
    CAMPAIGN_NO_ORDER_LINE: { es: 'Campaña sin estrategias', en: 'Campaign without order lines' },
    CAMPAIGN_END_DATE: { es: 'Campaña con fecha de fin anterior a la fecha actual', en: 'Campaign with end date before current date' },
    ORDER_LINE_START_DATE: { es: 'Estrategia con fecha de inicio anterior a la fecha actual:', en: 'Order line with start date before current date:' },
    ORDER_LINE_BUDGET_BID_PRICE: { es: 'Estrategias sin budget/bidprice:', en: 'Order lines without budget/bidprice:' },
    ORDER_LINE_MANDATORY_PARAMETERS: { es: 'Estrategas sin campos obligatorios:', en: 'Order lines without mandatory fields:' },
    ORDER_LINE_NO_CREATIVE: {es: 'No hay creatividades asociadas para: ', en: 'No creatives attached to: '},
    SUCCESS: { es: 'La campaña cumple con todas las validaciones.', en: 'The campaign satisfies all the validations.' }
  },
  campaignLines: { es: 'Grupos de anuncios', en: 'Campaign lines' },
  cancel: {es: 'Cancelar', en: 'Cancel'},
  carrier: {es: 'Carrier', en: 'Carrier'},
  carriers: {es: 'Carriers', en: 'Carriers'},
  categories: { es: 'Categorías', en: 'Categories' },
  censusCode: { es: 'Código censal', en: 'Census code' },
  changeCreativity: { es: 'Cambiar creatividad', en: 'Change creativity' },
  changePassword: { es: 'Cambiar contraseña', en: 'Change password' },
  changeStatusDoohCampaignConfirm: { es: '¿Seguro que desea cambiar el estado de la campaña?', en: 'Are you sure you want to change this campaign status?' },
  changeToProcessed: { es: 'Cambiar a procesado', en: 'Switch to process' },
  changeToCompleted: { es: 'Cambiar a completado', en: 'Switch to completed' },
  chooseAdvertisingFormat: { es: 'Elige los formatos publicitarios favoritos de la audiencia', en: 'Choose the audience\'s favorite advertising formats' },
  chooseAdvertisingSaturation: {es: 'Elige la saturación publicitaria', en:'Choose advertising saturation'},
  chooseOneOrMany: {es: 'Elige uno o varios', en: 'Choose one or many'},
  chooseUsedByUserApps: { es: 'Elige una o varias Apps que haya usado el usuario', en: 'Choose one or many Apps used by the user' },
  circuit: { es: 'Circuito', en: 'Circuit' },
  circuits: { es: 'Circuitos', en: 'Circuits' },
  circuitSelection: { es: 'Selección de circuitos', en: 'Circuit selection' },
  cities: { es: 'Ciudades', en: 'Cities' },
  city: { es: 'Ciudad', en: 'City' },
  cityAnalytics: {es: 'Analítica por ciudad', en: 'City analytics'},
  classifyClusterBy: {es: 'Clasifica tu clúster de audiencia según', en:'Classify your audience cluster by'},
  click: { es: 'Click', en: 'Click' },
  clicks: { es: 'Clicks', en: 'Clicks' },
  clicksByUser: {es: 'Clicks por usuario', en: 'Clicks by user'},
  clicksFirstVisit: { es: 'Clicks primera visita', en: 'First visit clicks' },
  clicksUniqueUsers: { es: 'Clicks usuarios únicos', en: 'Clicks unique users' },
  clickThroughURL: { es: 'URL click', en: 'Clickthrough URL' },
  client: {es: 'Cliente', en: 'Client'},
  clients: {es: 'Clientes', en: 'Clients'},
  close: { es: 'Cerrar', en: 'Close' },
  closeShopCalculation: { es: 'Cálculo de cierre de tienda', en: 'Close shop calculation' },
  cluster: { es: 'Cluster', en: 'Cluster' },
  clusterAffinityProfile: { es: 'Afinidad al cluster', en: 'Cluster affinity' },
  clusterName: {es: 'Audiencia asignada', en: 'Asignated audience'},
  clusters: {es: 'Clusters', en: 'Clusters'},
  clusterProfile: { es: 'Perfilado de cluster', en: 'Cluster profile' },
  clusterStatus: { es: 'Estado', en: 'Status' },
  clusterType: { es: 'Tipo', en: 'Type' },
  clusterCircleLimit: { es: 'Limite de tamaño sobrepasado', en: 'Oversize limit reached' },
  clusterCirclehomeplace: { es: 'Area restante disponible para hogar', en: 'Available area left for homeplace' },
  clusterCircleworkplace: { es: 'Area restante disponible para trabajo', en: 'Available area left for homeplace' },
  clusterCirclevitalzone: { es: 'Area restante disponible para zona vital', en: 'Available area left for homeplace' },
  codes: { es: 'Códigos', en: 'Codes' },
  cohorts: {es: 'Cohorts', en: 'Cohorts'},
  color: { es: 'Color', en: 'Color' },
  column: { es: 'Columna', en: 'Column'},
  combineAudience: { es: 'Combinar audiencia', en: 'Combine audience' },
  combineCreatedAudiences: { es: 'Combina audiencias ya creadas', en: 'Combine created audiences' },
  compareWithAverage: {es: 'Comparar con la media', en: 'Compare with average'},
  complete: { es: 'Complete', en: 'Complete' },
  completePlanning: { es: 'Planificación completa', en: 'Complete planning' },
  completeView: { es: 'Visionado completado', en: 'Complete view' },
  completeViews: { es: 'Visionados completados', en: 'Complete views' },
  completed: { es: 'Completado', en: 'Completed' },
  completeCLuster: { es: 'Validar cluster', en: 'Validate cluster' },
  compression: {es: 'Compresión', en: 'Compression'},
  configuration: { es: 'Configuración', en: 'Configuration' },
  configurationPercent: { es: 'Porcentaje de configuración', en: 'Configuration percent' },
  confirm: {es: 'Confirmar', en: 'Confirm'},
  confirmBudgetChanges: {es: 'Confirmar cambios en el presupuesto', en: 'Confirm budget changes'},
  confirmWarningSchedule: {es: 'Aviso de selección de horario', en: 'Schedule selection warning'},
  confirmPointDelete: { es: '¿Está seguro de querer borrar este punto?', en:'Are you sure you want to delete this point?'},
  congrats: {es: '¡Enhorabuena!', en: 'Congratulations!'},
  consideration: { es: 'Consideración', en: 'Consideration' },
  content: { es: 'Contenido', en: 'Content' },
  contentCategory: { es: 'Categoría del contenido', en: 'Content category' },
  contentRating: { es: 'Rating del contenido', en: 'Content rating' },
  context: {es: 'Contexto', en: 'Context'},
  contextWarningMsg: { es: 'Escriba cada elemento separado por comas', en: 'Type each element comma separated' },
  continuesNextPage: {es: 'Continua en la siguiente página', en: 'Continues on next page'},
  contracted: { es: 'Contratado', en: 'Contracted' },
  contractedHits: { es: 'Impactos contratados', en: 'Contracted hits' },
  contractedImpressions: { es: 'Impresiones contratadas', en: 'Contracted impressions' },
  conversionAbsValue: { es: 'Conversión v. absoluto', en: 'Conversion absolute v.' },
  conversionFunnels: { es: 'Funnels de conversión', en: 'Conversion funnels' },
  conversionPerc: {es: 'Conversión %', en: 'Conversion %'},
  conversions: {es: 'Conversiones', en: 'Conversions'},
  coordinates: { es: 'Coordenadas', en: 'Coordinates' },
  cost: { es: 'Coste', en: 'Cost' },
  costImp: { es: 'C/Impresión', en: 'C/Impression' },
  costHit: { es: 'C/Impacto', en: 'C/Hit' },
  couldntFetchhomeplaceData: { es: 'Ha ocurrido un error obteniendo los datos de hogar', en: 'An error has ocurred fetching homeplace data' },
  couldntFetchvitalzoneData: { es: 'Ha ocurrido un error obteniendo los datos de zona vital', en: 'An error has ocurred fetching vital zone data' },
  couldntFetchworkplaceData: { es: 'Ha ocurrido un error obteniendo los datos de trabajo', en: 'An error has ocurred fetching workplace data' },
  count: { es: 'Contabilizados', en: 'Count' },
  countries: { es: 'Paises', en: 'Countries' },
  country: {
    AR: { es: 'Argentina', en: 'Argentina' },
    BR: { es: 'Brasil', en: 'Brasil' },
    CL: { es: 'Chile', en: 'Chile' },
    CO: { es: 'Colombia', en: 'Colombia' },
    CR: { es: 'Costa Rica', en: 'Costa Rica' },
    DO: { es: 'República Dominicana', en: 'República Dominicana' },
    EC: { es: 'Ecuador', en: 'Ecuador' },
    en: 'Country',
    es: 'País',
    ES: { es: 'España', en: 'Spain' },
    GT: { es: 'Guatemala', en: 'Guatemala' },
    HN: { es: 'Honduras', en: 'Honduras'},
    MX: { es: 'México', en: 'Mexico' },
    NI: { es: 'Nicaragua', en: 'Nicaragua' },
    PA: { es: 'Panamá', en: 'Panamá' },
    PE: { es: 'Perú', en: 'Peru' },
    PR: { es: 'Puerto Rico', en: 'Puerto Rico' },
    PT: { es: 'Portugal', en: 'Portugal'},
    PY: { es: 'Paraguay', en: 'Paraguay' },
    SV: { es: 'El Salvador', en: 'El Salvador' },
    US: { es: 'USA', en: 'USA' },
    UY: { es: 'Uruguay', en: 'Uruguay' }
  },
  county: { es: 'Condado', en: 'County' },
  counties: { es: 'Condados', en: 'Counties' },
  cpm: { es: 'CPM', en: 'CPM' },
  cpmHit: { es: 'CPM impacto', en: 'CPM hit' },
  cpmImp: {es:'CPM impresión', en: 'CPM impression'},
  cpmData: {es:'CPM Data', en: 'CPM Data'},
  create: { es: 'Crear', en: 'Create' },
  createAudience: { es: 'Crear nueva audiencia', en: 'Create new audiences' },
  created: { es: 'Creado', en: 'Created' },
  createdAt: { es: 'Fecha de creación', en: 'Created at'},
  createDate: { es: 'Fecha de Creación', en: 'Creation date' },
  createFilter: {es: 'Crear nuevo filtro', en: 'Create new filter'},
  createNewCircuit: {es: 'Crear circuito nuevo', en: 'Crete new circuit'},
  createNewFunnel: {es: 'Crear nuevo funnel', en: 'Create new funnel'},
  createOrApplyFilter: {es: 'Cree un nuevo filtro o aplique uno ya creado', en: 'Create or apply a filter'},
  createPlanning: {es: 'Crear planificación', en: 'Create planning'},
  createReporting: {es: 'Crear reporte', en:'Create reporting'},
  createSegment: {es: 'Crear segmento', en: 'Create segment'},
  createXandr: { es: 'Crear campaña Xandr', en: 'Create Xandr campaign' },
  createZone: { es: 'Crear zona', en: 'Create zone' },
  creative: { es: 'Creatividad', en: 'Creative' },
  creativeRule: { es: 'Regla creatividad', en: 'Creative rule' },
  creatives: { es: 'Creatividades', en: 'Creatives' },
  creativesAvailable: { es: 'Creatividades disponibles', en: 'Creatives available' },
  creativeCreationError: { es: 'Ha ocurrido un error creando la creatividad', en: 'An error ocurred creating the creative'},
  creativesDOOH: { es: 'Creatividades DOOH', en: 'Creatives DOOH' },
  creativesPromotions: { es: 'Creatividades', en: 'Creatives' },
  creativesSelected: {es: 'Creatividades seleccionadas', en: 'Creatives selected'},
  creativeType: { es: 'Tipo de creatividad', en: 'Creative type' },
  creationDate: { es: 'Fecha de consulta', en: 'Query date' },
  creativeUpdatingError: { es: 'Ha ocurrido un error actualizando la creatividad', en: 'An error ocurred updating the creative'},
  csvCoordinates: {es: 'CSV Coordenadas', en: 'CSV Coordinates'},
  csvProfile: { es: 'CSV Perfilado', en: 'CSV Profile' },
  ctr: {es: 'CTR', en: 'CTR'},
  ctrlGroup: {es: 'Grupo de control', en: 'Control group'},
  ctvAnalytics: {es: 'CTV Analytics', en: 'CTV Analytics'},
  currency: { es: 'Moneda', en: 'Currency' },
  currencySymbol: { es: '€', en: '$' },
  customReporting: {es: 'Reporting personalizado', en: 'Custom reporting'},
  customerJourney: {es: 'Customer Journey', en: 'Customer Journey'},
  daily: { es: 'Diaria', en: 'Daily' },
  dailyAverage: { es: 'Media diaria', en: 'Daily averages' },
  dailyBudget: {es: 'Presupuesto diario', en:'Daily budget'},
  dailyInsights: { es: 'Análisis diario', en: 'Daily insights' },
  dailyPeopleInShopAverage: { es: 'Media diaria de personas en tienda', en: 'Daily people in shop average' },
  dailyVisitorsAverage: { es: 'Media diaria de visitantes', en: 'Daily visitors average' },
  dashboard: { es: 'Dashboard', en: 'Dashboard' },
  dataCost: { es: 'Coste de datos', en: 'Data cost' },
  dataOverview: { es: 'Datos generales', en: 'Data overview' },
  dailyBudgetError: { es: 'El presupuesto diario no puede ser superior al presupuesto total', en: 'Daily budget cannot exceed total budget' },
  characterError: { es: 'Hay caracteres no válidos en el nombre', en: 'There are invalid characters in the name' },
  dailyReport: { es: 'Reporte diario', en: 'Daily report' },
  dataFee: {es: 'Data fee', en: 'Data fee'},
  date: {es: 'Fecha', en: 'Date'},
  dates: { es: 'Fechas', en: 'Dates' },
  datesRanges: {es: 'Rangos de fechas', en: 'Dates ranges'},
  day: { es: 'Día', en: 'Day' },
  days: {es: 'Días', en: 'Days'},
  deactivated: { es: 'Desactivado', en: 'Disabled' },
  decimalsNotAllowed: {es: 'No se permiten decimales', en: 'Decimals not allowed'},
  defineSpecs: {es: 'Definir specs', en: 'Define specs'},
  del: {es: 'Del', en: 'From'},
  delete: { es: 'Borrar', en: 'Delete' },
  deleteAreaConfirm: {es: '¿Seguro que desea borrar esta área?', en: 'Are you sure you want to delete this area?'},
  deleteAudienceConfirm: {es: '¿Seguro que desea borrar esta audiencia?', en: 'Are you sure you want to delete this audience?'},
  deleteCampaignConfirm: {es: '¿Seguro que desea borrar esta campaña?', en: 'Are you sure you want to delete this campaign?'},
  deleteCampaignLineConfirm: {es: '¿Seguro que desea borrar este grupo de anuncios?', en: 'Are you sure you want to delete this campaign line?'},
  deleteCreativeConfirm: {es: '¿Seguro que desea borrar esta creatividad?', en: 'Are you sure you want to delete this creative?'},
  deleteFilterConfirm: {es: '¿Seguro que desea borrar este filtro?', en: 'Are you sure you want to delete this filter?'},
  deleteOrderLineConfirm: {es: '¿Seguro que desea borrar esta linea de pedido?', en: 'Are you sure you want to delete this order line?'},
  deleteOutdoorCampaignConfirm: {es: '¿Seguro que desea borrar esta campaña?', en: 'Are you sure you want to delete this campaign?'},
  deleteUser: { es: 'Borrar usuario', en: 'Delete user' },
  deleteUsers: { es: 'Borrar usuarios', en: 'Delete users' },
  deleteUsersAudienceConfirm: {es: '¿Seguro que desea quitar todos los usuarios de esta audiencia?', en: 'Are you sure you want to delete all users from this audience?'},
  deleteCircuit: {es: 'Borrar circuito', en: 'Delete circuit'},
  deleted: { es: 'Borrado', en: 'Deleted' },
  deleteFileError: { es: 'Ha ocurrido un error borrando el archivo', en: 'An error ocurred deleting the file'},
  deleteFolder: { es: 'Borrar Carpeta', en: 'Delete folder' },
  deleteFolderConfirm: { es: '¿Seguro que desea borrar esta carpeta?', en: 'Are you sure you want to delete this folder?' },
  deletePreviousFileError: { es: 'Ha ocurrido un error borrando el archivo anterior', en:'An error ocurred deleting the previous file'},
  deletePoi: { es: 'Borrar puntos de interés', en: 'Delete POIs' },
  deletePoiMsg: { es: '¿Está seguro de quitar los puntos de interés del mapa?', en: 'Are you sure you want to remove POIs from map?' },
  deleteSelectArea: { es: 'Borrar área de selección', en: 'Delete select area' },
  deleteSupport: { es: 'Borrar soporte', en: 'Delete support' },
  deleteMarkers: { es: 'Borrar selección', en: 'Delete selection' },
  deleteVideoError: { es: 'Ha ocurrido un error borrando el vídeo', en: 'An error ocurred deleting video asset files'},
  delivered: { es: 'Entregado', en: 'Delivered'},
  deliveryCost: {es: 'Coste de entrega', en: 'Delivery Cost'},
  demographicProfile: { es: 'Perfil demográfico', en: 'Demographic profile' },
  detail: { es: 'Detalle', en: 'Detail' },
  describeAudience: {es: 'Describe tu audiencia', en: 'Describe your audience'},
  description: { es: 'Descripción', en: 'Description' },
  deviceMake: { es: 'Fabricante del dispositivo', en: 'Device Make' },
  deviceScreenSize: { es: 'Tamaño de pantalla del dispositivo', en: 'Device screen size' },
  deviceModel: { es: 'Modelo del dispositivo', en: 'Device model' },
  devices: { es: 'Dispositivos', en: 'Devices' },
  deviceType: { es: 'Tipo de dispositivo', en: 'Device type' },
  difference: { es: 'Diferencia', en: 'Difference' },
  digital: { es: 'Digital', en: 'Digital' },
  digitalCampaigns: { es: 'Campañas digitales', en: 'Digital campaigns' },
  digitalCampaign: { es: 'Campaña digital', en: 'Digital campaign' },
  digitalEvent: {es: 'Evento digital', en: 'Digital event'},
  digitalEvents: {es: 'Eventos digitales', en: 'Digital events'},
  digitalOutdoorValues: {es: 'Valores para Digital Exterior', en: 'Digital Outdoor Values'},
  digitalReporting: {es: 'Reporting digital', en: 'Digital reporting'},
  digitalSupportsProperties: {es: 'Características de soportes digitales', en: 'Digital supports properties'},
  dimensions: { es: 'Dimensiones', en: 'Dimensions' },
  distance: {es: 'Distancia', en: 'Distance'},
  distribution: {es: 'Buzoneo', en: 'Distribution'},
  domain: { es: 'Dominio', en: 'Domain' },
  domainNumber: { es: 'Nº Dominios', en: 'Nº Domains' },
  domains: { es: 'Dominios', en: 'Domains' },
  DomainsIncludeInfo: { es: 'Lista de Dominios separadas por comas', en: 'Domains comma-separated list' },
  domainWarning: {es: 'La URL debe comenzar por http:// o https:// y debe ser válida', en: 'URL must begin with http:// or https:// and must be valid'},
  download: { es: 'Descarga', en: 'Download' },
  downloadExcel: { es: 'Descargar excel', en: 'Download excel' },
  downloadOrder: { es: 'Descargar pedido', en: 'Download order' },
  downloads: { es: 'Descargas', en: 'Downloads'},
  downloadsManager: {es: 'Gestor de descargas', en: 'Downloads manager'},
  doohEmails: { es: 'DOOH Emails', en: 'DOOH Emails' },
  doohReportingLogoText: { es: 'Reporte de campaña', en: 'Campaign report' },
  doohReportingLogoTextLine: { es: 'Reporte de grupo de anuncios', en: 'Campaign line report' },
  doohSubcampaignReportingLogoTextLine: { es: 'Reporte de campaña', en: 'Campaign report' },
  draft: {es:'Borrador', en:'Draft'},
  drawZonePoint: { es: 'Dibuja un punto en el mapa', en: 'Draw a point in the map' },
  drawOrUploadPoints: { es: 'Dibuja puntos en el mapa o sube un fichero de puntos de interés', en: 'Draw points in the map or upload a Points of Interest file' },
  dspErrors: { es: 'Errores DSP', en: 'DSP errors' },
  dspInfo: {es: 'Información DSP', en: 'DSP info'},
  dspStatus: { es: 'Estado DSP', en: 'DSP status' },
  dts: {es: 'DTS', en: 'DTS'},
  duplicate: { es: 'Duplicar', en: 'Duplicate' },
  duplicateAudienceConfirm: { es: '¿Seguro que desea duplicar esta audiencia?', en: 'Are you sure you want to duplicate this audience?' },
  duplicateCampaignConfirm: { es: '¿Seguro que desea duplicar esta campaña?', en: 'Are you sure you want to duplicate this campaign?' },
  duplicateOrderLineConfirm: { es: '¿Seguro que desea duplicar esta linea de pedido?', en: 'Are you sure you want to duplicate this order line?' },
  duplicateUserConfirm: { es: '¿Seguro que desea duplicar este usuario?', en: 'Are you sure you want to duplicate this user?' },
  duration: {es: 'Duración', en: 'Duration'},
  ecommerceVisits: {es: 'Visitas a e-Commerce', en: 'E-Commerce visits'},
  edit: { es: 'Editar', en: 'Edit' },
  editAndView: { es: 'Editar/Ver', en: 'Edit/View' },
  editCreative: {es:'Editar creatividad', en: 'Edit creative'},
  editFormats: { es: 'Editar formatos', en: 'Edit formats' },
  editRanges: {es: 'Editar baremos', en: 'Edit ranges'},
  elevator: {es: 'Ascensor', en: 'Elevator'},
  email: { es: 'Email', en: 'Email' },
  emails: { es: 'Emails', en: 'Emails' },
  endDate: {es:'Fecha de fin', en:'End date'},
  engagementAverage: { es: 'Tiempo medio de visita', en: 'Engagement average' },
  enrichment: {es: 'Enrichment', en: 'Enrichment'},
  entity: { es: 'Entidad', en: 'Entity' },
  entities: { es: 'Entidades', en: 'Entities' },
  entityLogo: { es: 'Logo de la compañia', en: 'Company Logo' },
  environmentType: { es: 'Tipo de entorno', en: 'Environment Type' },
  errorCircuit: {es: 'Errores en el circuito', en: 'Circuit errors'},
  errorCircuits: {
    RAD: { es: 'El radio no tiene un valor válido', en: 'Radius does not have a valid value'},
    LATRANGE: {es: 'La latitud no tiene un valor válido', en: 'Latitude does not have a valid value'},
    LONRANGE: {es: 'La longitud no tiene un valor válido', en: 'Longitude does not have a valid value'},
    VALUES: { es: 'Los valores no son válidos', en: 'Values are not valid'},
    NULL: { es: 'Latitud y longitud tienen valor 0', en: 'Latitude and longitude have value 0'},
    UNKNOWN: { es: 'Error desconocido', en: 'Unknown error'},
    SEARCH_TYPE: { es: 'Tipo de búsqueda no tiene un valor válido', en: 'Search type does not have a valid value'},
    SUPPORT_ADVERTISEMENT_TYPE: { es: 'Tipo de anuncio no tiene un valor válido', en: 'Advertisement type does not have a valid value'},
    SUPPORT_VIEW: { es: 'Vista no tiene un valor válido', en: 'View does not have a valid value'},
    SUPPORT_STRUCTURE: { es: 'Estructura no tiene un valor válido', en: 'Structure does not have a valid value'}
  },
  error: { es: 'Error', en: 'Error' },
  errors: {

    associatedCreative: { es: 'No se puede eliminar la creatividad porque ya está asociada a alguna campaña.', en: 'Creative cannot be eliminated because it is already associated with a campaign.' },
    chooseSchedule: {es: 'Por favor, seleccione horario', en:'Please, choose schedule'},
    couldntDeleteFolder: {es: 'No se puede borrar la carpeta. Tiene creatividades', en:'Couldn´t delete folder. It has creatives'},
    couldntFetchCities: { es: 'No se han podido cargar las ciudades', en: "Couldn't fetch cities"},
    couldntFetchCountries: { es: 'No se han podido cargar los paises', en: "Couldn't fetch countries" },
    couldntFetchSchedules: {es: 'No se han podido cargar los horarios', en: "Couldn't fetch schedules"},
    couldntFetchDates: {es: 'No se han podido cargar las fechas', en: "Couldn't fetch dates"},
    fileNameExists: { es: 'El nombre de archivo ya existe', en: 'Filename already exists' },
    fillAllFields: { es: 'Rellena todos los campos', en: 'Fill all fields' },
    fillRequiredFields: { es: 'Rellena los campos obligatorios', en: 'Fill required fields' },
    forbiddenAction: {es: 'No tienes permisos para realizar esa acción', en: 'You do not have permissions to do that action' },
    genericErrorMsg: { es: 'Ha ocurrido un error. Inténtelo de nuevo más tarde.', en: 'An error has occurred. Try again later.'},
    lineBudgetExceeds: {es: 'El presupuesto de la línea excede el presupuesto de la campaña', en: 'Line budget exceeds campaign budget'},
    map: {es: 'Mapa', en: 'Map'},
    mapCorrectionError: { es: 'Se ha producido un error al procesar la corrección de los mapas', en: 'Some error ocurred processing map corrections' },
    noAdvertisersError: { es: 'No hay anunciantes para esta agencia', en: 'There is no advertisers for this agency'},
    noCreativesError: { es: 'Para poder enviar este grupo de anuncios necesita adjuntar una creatividad', en: 'To be able to send this ad group you need to attach a creativity'},
    noDataError: { es: 'No hay datos para esta consulta', en: 'There is no data for this query'},noSupportsError: {es: 'No hay soportes disponibles para esta consulta. Modifique los filtros.', en: 'There are no supports available for this query. Modify filters.'},
    sessionTimedOut: {es: 'Su sesión ha caducado. Por favor inicie sesión para seguir navegando por la plataforma.',
    en: 'Your session has timed out. Please login to continue using the platform.'},
    sumTotals: { es: 'La suma de los totales no es 100', en: 'The sum of totals is not 100'},
    userEmailExists: {es: 'El email del usuario ya existe', en: 'User email already exists'},
    userNameExists: {es: 'El nombre de usuario ya existe', en: 'Username already exists'},
    noAdvertisers: { es: 'El usuario no tiene anunciantes asociados', en: 'User doesn not have advertisers' },
    passwordChange: { es: 'La nueva contraseña tiene que tener 6 caracteres, una mayuscula, una minuscula y un numero al menos', en: 'New password must be 6 characters long with uppercase, lowercase and a number at least'},
    profileInTwoDays: { es: 'Los datos de perfilado estarán disponibles 48 horas después del fin de la campaña', en: 'Profile data will be available 48 hours after campaign has finished' },
    profileWithoutData: { es: 'No hay información suficiente para mostrar los datos de Perfil', en: 'There is not enough information to display the Profile data' },
    repeatPassword: { es: 'Las contraseñas introducidas no son iguales', en: 'The entered passwords are not the same' },
    saveFirst: { es: 'Debe guardar primero', en: 'Save first' },
    wrongPercentageValue: { es: 'El valor introducido tiene que estar entre 0 y 100.', en: 'The value entered must be between 0 and 100.' }
  },
  errorAccess: {
    text1: { es: 'No se puede acceder a la página.', en: 'Unable to Access Page.' },
    text2: { es: 'La página a la que intenta acceder no existe o no es accesible.', en: 'The page you are trying to access does not exist or is not accessible.' }
  },
  estimatedCoverage: {es:'Cobertura estimada', en:'Estimated coverage'},
  estimatedPopulation: { es: 'Población estimada', en: 'Estimated population' },
  estimatedRate: { es: 'Tarifa estimada', en: 'Estimated rate' },
  estimatedBudget: { es: 'Presupuesto estimado', en: 'Estimated budget' },
  estimations: { es: 'Estimaciones', en: 'Estimations' },
  expectedLift: { es: 'Lift esperado', en: 'Expected Lift' },
  exportToPdf: { es: 'Exportar a PDF', en: 'Export to PDF' },
  extraColor: {es: 'Color extra', en: 'Extra color'},
  evaluatedSupports: {es: 'Soportes evaluados', en: 'Evaluated supports'},
  evaluationDates: { es: 'Fechas de la evaluación', en: 'Evaluation dates' },
  eventClick: {es: 'Evento click', en: 'Click event'},
  eventView: {es: 'Evento view', en: 'View event'},
  events: { es: 'Eventos', en: 'Events' },
  eventTrackers3rdParty: { es: 'Rastreadores de eventos de terceros', en: '3rd party event trackers' },
  every: { es: 'Cada', en: 'Every' },
  evolution: {es: 'Evolución', en: 'Evolution'},
  exclude: { es: 'Excluir', en: 'Exclude' },
  executionFrequency: {es: 'Frecuencia de ejecución', en:'Execution frequency'},
  executionTime: {es: 'Momento de ejecución', en:'Execution time'},
  excelIncorrectFormat: { es: 'El fichero Excel no tiene el formato esperado', en: 'Excel file does not have expected format' },
  expandable: { es: 'Ampliable', en: 'Expandable' },
  extrapolationQuery: {es: 'Evaluador de circuitos', en: 'Circuits evaluator'},
  extrapolationDailyQuery: { es: 'Evaluador de circuitos diario', en: 'Daily circuits evaluator' },
  extrapolationWarningMsg: {es: 'Las zonas resaltadas tienen soportes que no son evaluables. Pinche sobre ellas para ver detalle', en:'Highlighted zones have unevaluable supports. Click on them to see detail'},
  factorEstimation: { es: 'Factor de estimación de visitas', en: 'Factor visits estimation' },
  factorModel: { es: 'Factor del modelo', en: 'Model factor' },
  families: { es: 'Familias', en: 'Families' },
  faces: { es: 'Caras', en: 'Faces' },
  facesSelected: { es: 'Caras seleccionadas', en: 'Selected faces' },
  female: { es: 'Mujeres', en: 'Female' },
  femaleUsers: { es: 'Mujeres', en: 'Females' },
  fields: { es: 'Campos', en: 'Fields' },
  fileType: { es: 'Tipo de fichero', en: 'File type' },
  fileUploadError: { es: 'Ha ocurrido un error subiendo los archivos' , en:'An error ocurred uploading the new files'},
  fillAllFields: { es: 'Rellena todos los campos', en: 'Fill all fields' },
  fillZoneName: { es: 'Especifica un nombre para la zona', en: 'Fill in the zone name' },
  filterByName: {es: 'Filtrar por nombre', en: 'Filter by name'},
  filterByPreviouslyCreatedAreas: { es: 'Filtrar por áreas previamente creadas', en: 'Filter by previously created areas' },
  filterByType: { es: 'Filtrar por tipo', en: 'Filter by type'},
  filters: { es: 'Filtros', en: 'Filters'},
  filterPanel: { es: 'Panel de filtros', en: 'Filter panel'},
  finalized: { es: 'Finalizado', en: 'Finalized' },
  firstQuartile: { es: 'Primer cuartil', en: 'First quartile' },
  folder: { es: 'Carpeta', en: 'Folder' },
  folders: { es: 'Carpetas', en: 'Folders' },
  form: {
    checkedFieldRequired: { es: 'Debes aceptar este campo', en: 'You must accept this field' },
    valueFieldRequired: { es: 'Debes rellenar este campo', en: 'You must fill in this field' },
    emailFieldRequired: { es: 'Debes rellenar el email', en: 'You must fill the email' },
    emailWrongFormat: { es: 'El formato del email es erroneo', en: 'Wrong email format' },
    startDateBeforeEndDate: { es: 'La fecha de inicio debe ser previa a la de fin', en: 'Start date must be before end date' },
  },
  format:{es:'Formato', en:'Format'},
  formatsAffinity: { es: 'Afinidad de formatos', en: 'Formats affinity' },
  formatSize: { es: 'Tamaño de formato', en: 'Format size' },
  formatType: {es: 'Tipo de formato', en: 'Format type'},
  fourMembers: {es: 'Cuatro miembros', en: 'Four members'},
  fiveMembers: {es: 'Cinco miembros', en: 'Five members'},
  frequency: {es:'Frecuencia', en: 'Frequency'},
  friday: { es: 'Viernes', en: 'Friday' },
  from: { es: 'Desde', en: 'From' },
  fromCircuit: {es: 'Del circuito', en: 'From circuit'},
  fromEstimation: { es: 'Estimación desde', en: 'Estimation from' },
  fromHour: { es: 'Desde la hora', en: 'From hour' },
  fromModel: { es: 'Modelo desde', en: 'Model from' },
  fulfillment: { es: 'Cumplimiento', en: 'Fulfillment' },
  fullscreen: { es: 'Pantalla completa', en: 'Fullscreen' },
  funnel: { es: 'Funnel', en: 'Funnel'},
  funnelView: { es: 'Vista del funnel', en: 'Funnel view'},
  gender: { es: 'Género', en: 'Gender' },
  genders: { es: 'Géneros', en: 'Genders' },
  generalData: { es: 'Datos generales', en: 'General data' },
  generalUsersThroughCircuit: {es: 'Usuarios generales que pasan por el circuito', en:'General users through the circuit'},
  generate: {es:'Generar', en: 'Generate'},
  genericErrorMsg: { es: 'Ha ocurrido un error. Inténtelo de nuevo más tarde.', en: 'An error has occurred. Try again later.'},
  generateCampaign: { es: 'Generar campaña', en: 'Generate campaign' },
  generateReport: { es: 'Generar reporte', en: 'Generate report' },
  generateRequest: {es: 'Generar consulta', en: 'Generate request'},
  generateMetrics: {es: 'Generar metricas', en: 'Generate metrics'},
  generatingReport: {es: 'El reporte se generará en los próximos minutos', en:'Generating report in the next minutes'},
  geo: { es: 'Geo', en: 'Geo' },
  geocontextualAnalytics: {es: 'Analítica geocontextual', en: 'Geocontextual analytics' },
  geographicTarget: { es: 'Target geográfico', en: 'Geographic target' },
  globalAnalytic: { es: 'Analitica global', en: 'Global analytic' },
  globalDataAnalysis: { es: 'Analisis de datos globales', en: 'Global data analysis' },
  globalConversion: { es: 'Conversión global', en: 'Global conversion' },
  goal: {es:'Objetivo', en: 'Goal'},
  goalType: { es: 'Tipo de objetivo', en: 'Goal type' },
  goingToRemoveSupport: {es: 'Va a eliminar el soporte', en:'You are going to remove'},
  goToCampaign: { es: 'Ir a campaña', en: 'Go to campaign' },
  goBack: { es: 'Volver atrás', en: 'Go back' },
  grp: { es: 'GRP', en: 'GRP' },
  groupedMarkersWarning: { es: 'Existen marcadores agrupados fuera de la selección. ¿Quiere añadir marcadores agrupados automáticamente?', en: 'There are grouped markers not included in the selection. Do you want to add all automatically?' },
  groupedMarkersWarningHeader: { es: 'Marcadores agrupados detectados', en: 'Grouped markers detected' },
  groupBy: { es: 'Agrupar por', en: 'Group by' },
  guidelineCities: { es: 'Ciudades pauta', en: 'Guideline cities' },
  halfpage: {es: 'Halfpage', en: 'Halfpage'},
  height: { es: 'Alto', en: 'Height' },
  heightResolutionPx: {es: 'Resolución alto (px)', en: 'Height resolution (px)'},
  high: { es: 'Vivenda alta', en: 'High house' },
  hits: {es: 'Impactos', en: 'Hits'},
  hitsServed: { es: 'Impactos servidos', en: 'Served Hits' },
  hiredHits: { es: 'Impac/C', en: 'Impac/C' },
  hiredHitsLong: { es: 'Impactos contratados', en: 'Hired Hits' },
  homeGeo: { es: 'Hogar', en: 'Home'},
  homeplace: { es: 'Hogar', en: 'Home' },
  horizontal: {es: 'Horizontal', en: 'Horizontal'},
  hour: {es: 'Hora', en: 'Hour'},
  houseAge: {es: 'Edad de la vivienda', en: 'House age'},
  houseHeight: { es: 'Tipo de la vivienda', en: 'House type' },
  household: { es: 'Household', en: 'Household' },
  householdVolume: { es: 'Vol. Household', en: 'Household vol.' },
  householdType: { es: 'Tipo de familia', en: 'Household type' },
  householdSize: { es: 'Tamaño familiar', en: 'Household size' },
  householdIncome: { es: 'Ingresos familiares', en: 'Household income' },
  householdProfile: { es: 'Perfil familiar', en: 'Household profile' },
  html5: { es: 'HTML5', en: 'HTML5' },
  iaa:{es: 'IAA', en: 'IAA'},
  IAB: { es: 'IAB', en: 'IAB' },
  iab: { es: 'Sector', en: 'Sector'},
  iab1: { es: 'Tier 1', en: 'Tier 1'},
  iab2: { es: 'Tier 2', en: 'Tier 2'},
  iabsList: {
    IAB1: { es: 'Arts & Entertainment', en: 'Arts & Entertainment' },
    IAB1_1: { es: 'Books & Literature', en: 'Books & Literature' },
    IAB1_2: { es: 'Celebrity Fan/Gossip', en: 'Celebrity Fan/Gossip' },
    IAB1_3: { es: 'Fine Art', en: 'Fine Art' },
    IAB1_4: { es: 'Humor', en: 'Humor' },
    IAB1_5: { es: 'Movies', en: 'Movies' },
    IAB1_6: { es: 'Music', en: 'Music' },
    IAB1_7: { es: 'Television', en: 'Television' },
    IAB2: { es: 'Automotive', en: 'Automotive' },
    IAB2_1: { es: 'Auto Parts', en: 'Auto Parts' },
    IAB2_2: { es: 'Auto Repair', en: 'Auto Repair' },
    IAB2_3: { es: 'Buying/Selling Cars', en: 'Buying/Selling Cars' },
    IAB2_4: { es: 'Car Culture', en: 'Car Culture' },
    IAB2_5: { es: 'Certified Pre-Owned', en: 'Certified Pre-Owned' },
    IAB2_6: { es: 'Convertible', en: 'Convertible' },
    IAB2_7: { es: 'Coupe', en: 'Coupe' },
    IAB2_8: { es: 'Crossover', en: 'Crossover' },
    IAB2_9: { es: 'Diesel', en: 'Diesel' },
    IAB2_10: { es: 'Electric Vehicle', en: 'Electric Vehicle' },
    IAB2_11: { es: 'Hatchback', en: 'Hatchback' },
    IAB2_12: { es: 'Hybrid', en: 'Hybrid' },
    IAB2_13: { es: 'Luxury', en: 'Luxury' },
    IAB2_14: { es: 'MiniVan', en: 'MiniVan' },
    IAB2_15: { es: 'Mororcycles', en: 'Mororcycles' },
    IAB2_16: { es: 'Off-Road Vehicles', en: 'Off-Road Vehicles' },
    IAB2_17: { es: 'Performance Vehicles', en: 'Performance Vehicles' },
    IAB2_18: { es: 'Pickup', en: 'Pickup' },
    IAB2_19: { es: 'Road-Side Assistance', en: 'Road-Side Assistance' },
    IAB2_20: { es: 'Sedan', en: 'Sedan' },
    IAB2_21: { es: 'Trucks & Accessories', en: 'Trucks & Accessories' },
    IAB2_22: { es: 'Vintage Cars', en: 'Vintage Cars' },
    IAB2_23: { es: 'Wagon', en: 'Wagon' },
    IAB3: { es: 'Business', en: 'Business' },
    IAB3_1: { es: 'Advertising', en: 'Advertising' },
    IAB3_2: { es: 'Agriculture', en: 'Agriculture' },
    IAB3_3: { es: 'Biotech/Biomedical', en: 'Biotech/Biomedical' },
    IAB3_4: { es: 'Business Software', en: 'Business Software' },
    IAB3_5: { es: 'Construction', en: 'Construction' },
    IAB3_6: { es: 'Forestry', en: 'Forestry' },
    IAB3_7: { es: 'Government', en: 'Government' },
    IAB3_8: { es: 'Green Solutions', en: 'Green Solutions' },
    IAB3_9: { es: 'Human Resources', en: 'Human Resources' },
    IAB3_10: { es: 'Logistics', en: 'Logistics' },
    IAB3_11: { es: 'Marketing', en: 'Marketing' },
    IAB3_12: { es: 'Metals', en: 'Metals' },
    IAB4: { es: 'Careers', en: 'Careers' },
    IAB4_1: { es: 'Career Planning', en: 'Career Planning' },
    IAB4_2: { es: 'College', en: 'College' },
    IAB4_3: { es: 'Financial Aid', en: 'Financial Aid' },
    IAB4_4: { es: 'Job Fairs', en: 'Job Fairs' },
    IAB4_5: { es: 'Job Search', en: 'Job Search' },
    IAB4_6: { es: 'Resume Writing/Advice', en: 'Resume Writing/Advice' },
    IAB4_7: { es: 'Nursing', en: 'Nursing' },
    IAB4_8: { es: 'Scholarships', en: 'Scholarships' },
    IAB4_9: { es: 'Telecommuting', en: 'Telecommuting' },
    IAB4_10: { es: 'U.S. Military', en: 'U.S. Military' },
    IAB4_11: { es: 'Career Advice', en: 'Career Advice' },
    IAB5: { es: 'Education', en: 'Education' },
    IAB5_1: { es: '7-12 Education', en: '7-12 Education' },
    IAB5_2: { es: 'Adult Education', en: 'Adult Education' },
    IAB5_3: { es: 'Art History', en: 'Art History' },
    IAB5_4: { es: 'Colledge Administration', en: 'Colledge Administration' },
    IAB5_5: { es: 'College Life', en: 'College Life' },
    IAB5_6: { es: 'Distance Learning', en: 'Distance Learning' },
    IAB5_7: { es: 'English as a 2nd Language', en: 'English as a 2nd Language' },
    IAB5_8: { es: 'Language Learning', en: 'Language Learning' },
    IAB5_9: { es: 'Graduate School', en: 'Graduate School' },
    IAB5_10: { es: 'Homeschooling', en: 'Homeschooling' },
    IAB5_11: { es: 'Homework/Study Tips', en: 'Homework/Study Tips' },
    IAB5_12: { es: 'K-6 Educators', en: 'K-6 Educators' },
    IAB5_13: { es: 'Private School', en: 'Private School' },
    IAB5_14: { es: 'Special Education', en: 'Special Education' },
    IAB5_15: { es: 'Studying Business', en: 'Studying Business' },
    IAB6: { es: 'Family & Parenting', en: 'Family & Parenting' },
    IAB6_1: { es: 'Adoption', en: 'Adoption' },
    IAB6_2: { es: 'Babies & Toddlers', en: 'Babies & Toddlers' },
    IAB6_3: { es: 'Daycare/Pre School', en: 'Daycare/Pre School' },
    IAB6_4: { es: 'Family Internet', en: 'Family Internet' },
    IAB6_5: { es: 'Parenting - K-6 Kids', en: 'Parenting - K-6 Kids' },
    IAB6_6: { es: 'Parenting teens', en: 'Parenting teens' },
    IAB6_7: { es: 'Pregnancy', en: 'Pregnancy' },
    IAB6_8: { es: 'Special Needs Kids', en: 'Special Needs Kids' },
    IAB6_9: { es: 'Eldercare', en: 'Eldercare' },
    IAB7: { es: 'Health & Fitness', en: 'Health & Fitness' },
    IAB7_1: { es: 'Exercise', en: 'Exercise' },
    IAB7_2: { es: 'A.D.D.', en: 'A.D.D.' },
    IAB7_3: { es: 'AidS/HIV', en: 'AidS/HIV' },
    IAB7_4: { es: 'Allergies', en: 'Allergies' },
    IAB7_5: { es: 'Alternative Medicine', en: 'Alternative Medicine' },
    IAB7_6: { es: 'Arthritis', en: 'Arthritis' },
    IAB7_7: { es: 'Asthma', en: 'Asthma' },
    IAB7_8: { es: 'Autism/PDD', en: 'Autism/PDD' },
    IAB7_9: { es: 'Bipolar Disorder', en: 'Bipolar Disorder' },
    IAB7_10: { es: 'Brain Tumor', en: 'Brain Tumor' },
    IAB7_11: { es: 'Cancer', en: 'Cancer' },
    IAB7_12: { es: 'Cholesterol', en: 'Cholesterol' },
    IAB7_13: { es: 'Chronic Fatigue Syndrome', en: 'Chronic Fatigue Syndrome' },
    IAB7_14: { es: 'Chronic Pain', en: 'Chronic Pain' },
    IAB7_15: { es: 'Cold & Flu', en: 'Cold & Flu' },
    IAB7_16: { es: 'Deafness', en: 'Deafness' },
    IAB7_17: { es: 'Dental Care', en: 'Dental Care' },
    IAB7_18: { es: 'Depression', en: 'Depression' },
    IAB7_19: { es: 'Dermatology', en: 'Dermatology' },
    IAB7_20: { es: 'Diabetes', en: 'Diabetes' },
    IAB7_21: { es: 'Epilepsy', en: 'Epilepsy' },
    IAB7_22: { es: 'GERD/Acid Reflux', en: 'GERD/Acid Reflux' },
    IAB7_23: { es: 'Headaches/Migraines', en: 'Headaches/Migraines' },
    IAB7_24: { es: 'Heart Disease', en: 'Heart Disease' },
    IAB7_25: { es: 'Herbs for Health', en: 'Herbs for Health' },
    IAB7_26: { es: 'Holistic Healing', en: 'Holistic Healing' },
    IAB7_27: { es: 'IBS/Crohn\'s Disease', en: 'IBS/Crohn\'s Disease' },
    IAB7_28: { es: 'Incest/Abuse Support', en: 'Incest/Abuse Support' },
    IAB7_29: { es: 'Incontinence', en: 'Incontinence' },
    IAB7_30: { es: 'Infertility', en: 'Infertility' },
    IAB7_31: { es: 'Men\'s Health', en: 'Men\'s Health' },
    IAB7_32: { es: 'Nutrition', en: 'Nutrition' },
    IAB7_33: { es: 'Orthopedics', en: 'Orthopedics' },
    IAB7_34: { es: 'Panic/Anxiety Disorders', en: 'Panic/Anxiety Disorders' },
    IAB7_35: { es: 'Pediatrics', en: 'Pediatrics' },
    IAB7_36: { es: 'Physical Therapy', en: 'Physical Therapy' },
    IAB7_37: { es: 'Psychology/Psychiatry', en: 'Psychology/Psychiatry' },
    IAB7_38: { es: 'Senor Health', en: 'Senor Health' },
    IAB7_39: { es: 'Sexuality', en: 'Sexuality' },
    IAB7_40: { es: 'Sleep Disorders', en: 'Sleep Disorders' },
    IAB7_41: { es: 'Smoking Cessation', en: 'Smoking Cessation' },
    IAB7_42: { es: 'Substance Abuse', en: 'Substance Abuse' },
    IAB7_43: { es: 'Thyroid Disease', en: 'Thyroid Disease' },
    IAB7_44: { es: 'Weight Loss', en: 'Weight Loss' },
    IAB7_45: { es: 'Women\'s Health', en: 'Women\'s Health' },
    IAB8: { es: 'Food & Drink', en: 'Food & Drink' },
    IAB8_1: { es: 'American Cuisine', en: 'American Cuisine' },
    IAB8_2: { es: 'Barbecues & Grilling', en: 'Barbecues & Grilling' },
    IAB8_3: { es: 'Cajun/Creole', en: 'Cajun/Creole' },
    IAB8_4: { es: 'Chinese Cuisine', en: 'Chinese Cuisine' },
    IAB8_5: { es: 'Cocktails/Beer', en: 'Cocktails/Beer' },
    IAB8_6: { es: 'Coffee/Tea', en: 'Coffee/Tea' },
    IAB8_7: { es: 'Cuisine-Specific', en: 'Cuisine-Specific' },
    IAB8_8: { es: 'Desserts & Baking', en: 'Desserts & Baking' },
    IAB8_9: { es: 'Dining Out', en: 'Dining Out' },
    IAB8_10: { es: 'Food Allergies', en: 'Food Allergies' },
    IAB8_11: { es: 'French Cuisine', en: 'French Cuisine' },
    IAB8_12: { es: 'Health/Lowfat Cooking', en: 'Health/Lowfat Cooking' },
    IAB8_13: { es: 'Italian Cuisine', en: 'Italian Cuisine' },
    IAB8_14: { es: 'Japanese Cuisine', en: 'Japanese Cuisine' },
    IAB8_15: { es: 'Mexican Cuisine', en: 'Mexican Cuisine' },
    IAB8_16: { es: 'Vegan', en: 'Vegan' },
    IAB8_17: { es: 'Vegetarian', en: 'Vegetarian' },
    IAB8_18: { es: 'Wine', en: 'Wine' },
    IAB9: { es: 'Hobbies & Interests', en: 'Hobbies & Interests' },
    IAB9_1: { es: 'Art/Technology', en: 'Art/Technology' },
    IAB9_2: { es: 'Arts & Crafts', en: 'Arts & Crafts' },
    IAB9_3: { es: 'Beadwork', en: 'Beadwork' },
    IAB9_4: { es: 'Birdwatching', en: 'Birdwatching' },
    IAB9_5: { es: 'Board Games/Puzzles', en: 'Board Games/Puzzles' },
    IAB9_6: { es: 'Candle & Soap Making', en: 'Candle & Soap Making' },
    IAB9_7: { es: 'Card Games', en: 'Card Games' },
    IAB9_8: { es: 'Chess', en: 'Chess' },
    IAB9_9: { es: 'Cigars', en: 'Cigars' },
    IAB9_10: { es: 'Collecting', en: 'Collecting' },
    IAB9_11: { es: 'Comic Books', en: 'Comic Books' },
    IAB9_12: { es: 'Drawing/Sketching', en: 'Drawing/Sketching' },
    IAB9_13: { es: 'Freelance Writing', en: 'Freelance Writing' },
    IAB9_14: { es: 'Geneaology', en: 'Geneaology' },
    IAB9_15: { es: 'Getting Published', en: 'Getting Published' },
    IAB9_16: { es: 'Guitar', en: 'Guitar' },
    IAB9_17: { es: 'Home Recording', en: 'Home Recording' },
    IAB9_18: { es: 'Investors & Patents', en: 'Investors & Patents' },
    IAB9_19: { es: 'Jewelry Making', en: 'Jewelry Making' },
    IAB9_20: { es: 'Magic & Illusion', en: 'Magic & Illusion' },
    IAB9_21: { es: 'Needlework', en: 'Needlework' },
    IAB9_22: { es: 'Painting', en: 'Painting' },
    IAB9_23: { es: 'Photography', en: 'Photography' },
    IAB9_24: { es: 'Radio', en: 'Radio' },
    IAB9_25: { es: 'Roleplaying Games', en: 'Roleplaying Games' },
    IAB9_26: { es: 'Sci-Fi & Fantasy', en: 'Sci-Fi & Fantasy' },
    IAB9_27: { es: 'Scrapbooking', en: 'Scrapbooking' },
    IAB9_28: { es: 'Screenwriting', en: 'Screenwriting' },
    IAB9_29: { es: 'Stamps & Coins', en: 'Stamps & Coins' },
    IAB9_30: { es: 'Video & Computer Games', en: 'Video & Computer Games' },
    IAB9_31: { es: 'Woodworking', en: 'Woodworking' },
    IAB10: { es: 'Home & Garden', en: 'Home & Garden' },
    IAB10_1: { es: 'Appliances', en: 'Appliances' },
    IAB10_2: { es: 'Entertaining', en: 'Entertaining' },
    IAB10_3: { es: 'Environmental Safety', en: 'Environmental Safety' },
    IAB10_4: { es: 'Gardening', en: 'Gardening' },
    IAB10_5: { es: 'Home Repair', en: 'Home Repair' },
    IAB10_6: { es: 'Home Theater', en: 'Home Theater' },
    IAB10_7: { es: 'Interior Decorating', en: 'Interior Decorating' },
    IAB10_8: { es: 'Landscaping', en: 'Landscaping' },
    IAB10_9: { es: 'Remodeling & Construction', en: 'Remodeling & Construction' },
    IAB11: { es: 'Law', en: 'Law' },
    IAB11_1: { es: 'Immigration', en: 'Immigration' },
    IAB11_2: { es: 'Legal Issues', en: 'Legal Issues' },
    IAB11_3: { es: 'U.S. Government Resources', en: 'U.S. Government Resources' },
    IAB11_4: { es: 'Politics', en: 'Politics' },
    IAB11_5: { es: 'Commentary', en: 'Commentary' },
    IAB12: { es: 'News', en: 'News' },
    IAB12_1: { es: 'International News', en: 'International News' },
    IAB12_2: { es: 'National News', en: 'National News' },
    IAB12_3: { es: 'Local News', en: 'Local News' },
    IAB13: { es: 'Personal Finance', en: 'Personal Finance' },
    IAB13_1: { es: 'Beginning Investing', en: 'Beginning Investing' },
    IAB13_2: { es: 'Credit/Debt & Loans', en: 'Credit/Debt & Loans' },
    IAB13_3: { es: 'Financial News', en: 'Financial News' },
    IAB13_4: { es: 'Financial Planning', en: 'Financial Planning' },
    IAB13_5: { es: 'Hedge Fund', en: 'Hedge Fund' },
    IAB13_6: { es: 'Insurance', en: 'Insurance' },
    IAB13_7: { es: 'Investing', en: 'Investing' },
    IAB13_8: { es: 'Mutual Funds', en: 'Mutual Funds' },
    IAB13_9: { es: 'Options', en: 'Options' },
    IAB13_10: { es: 'Retirement Planning', en: 'Retirement Planning' },
    IAB13_11: { es: 'Stocks', en: 'Stocks' },
    IAB13_12: { es: 'Tax Planning', en: 'Tax Planning' },
    IAB14: { es: 'Society', en: 'Society' },
    IAB14_1: { es: 'Dating', en: 'Dating' },
    IAB14_2: { es: 'Divorce Support', en: 'Divorce Support' },
    IAB14_3: { es: 'Gay Life', en: 'Gay Life' },
    IAB14_4: { es: 'Marriage', en: 'Marriage' },
    IAB14_5: { es: 'Senior Living', en: 'Senior Living' },
    IAB14_6: { es: 'Teens', en: 'Teens' },
    IAB14_7: { es: 'Weddings', en: 'Weddings' },
    IAB14_8: { es: 'Ethnic Specific', en: 'Ethnic Specific' },
    IAB15: { es: 'Science', en: 'Science' },
    IAB15_1: { es: 'Astrology', en: 'Astrology' },
    IAB15_2: { es: 'Biology', en: 'Biology' },
    IAB15_3: { es: 'Chemistry', en: 'Chemistry' },
    IAB15_4: { es: 'Geology', en: 'Geology' },
    IAB15_5: { es: 'Paranormal Phenomena', en: 'Paranormal Phenomena' },
    IAB15_6: { es: 'Physics', en: 'Physics' },
    IAB15_7: { es: 'Space/Astronomy', en: 'Space/Astronomy' },
    IAB15_8: { es: 'Geography', en: 'Geography' },
    IAB15_9: { es: 'Botany', en: 'Botany' },
    IAB15_10: { es: 'Weather',  en: 'Weather' },
    IAB16: { es: 'Pets', en: 'Pets' },
    IAB16_1: { es: 'Aquariums', en: 'Aquariums' },
    IAB16_2: { es: 'Birds', en: 'Birds' },
    IAB16_3: { es: 'Cats', en: 'Cats' },
    IAB16_4: { es: 'Dogs', en: 'Dogs' },
    IAB16_5: { es: 'Large Animals', en: 'Large Animals' },
    IAB16_6: { es: 'Reptiles', en: 'Reptiles' },
    IAB16_7: { es: 'Veterinary Medicine', en: 'Veterinary Medicine' },
    IAB17: { es: 'Sports', en: 'Sports' },
    IAB17_1: { es: 'Auto Racing', en: 'Auto Racing' },
    IAB17_2: { es: 'Baseball', en: 'Baseball' },
    IAB17_3: { es: 'Bicycling', en: 'Bicycling' },
    IAB17_4: { es: 'Bodybuilding', en: 'Bodybuilding' },
    IAB17_5: { es: 'Boxing', en: 'Boxing' },
    IAB17_6: { es: 'Canoeing/Kayaking', en: 'Canoeing/Kayaking' },
    IAB17_7: { es: 'Cheerleading', en: 'Cheerleading' },
    IAB17_8: { es: 'Climbing', en: 'Climbing' },
    IAB17_9: { es: 'Cricket', en: 'Cricket' },
    IAB17_10: { es: 'Figure Skating', en: 'Figure Skating' },
    IAB17_11: { es: 'Fly Fishing', en: 'Fly Fishing' },
    IAB17_12: { es: 'Football', en: 'Football' },
    IAB17_13: { es: 'Freshwater Fishing', en: 'Freshwater Fishing' },
    IAB17_14: { es: 'Game & Fish', en: 'Game & Fish' },
    IAB17_15: { es: 'Golf', en: 'Golf' },
    IAB17_16: { es: 'Horse Racing', en: 'Horse Racing' },
    IAB17_17: { es: 'Horses', en: 'Horses' },
    IAB17_18: { es: 'Hunting/Shooting', en: 'Hunting/Shooting' },
    IAB17_19: { es: 'Inline Skating', en: 'Inline Skating' },
    IAB17_20: { es: 'Martial Arts', en: 'Martial Arts' },
    IAB17_21: { es: 'Mountain Biking', en: 'Mountain Biking' },
    IAB17_22: { es: 'NASCAR Racing', en: 'NASCAR Racing' },
    IAB17_23: { es: 'Olympics', en: 'Olympics' },
    IAB17_24: { es: 'Paintball', en: 'Paintball' },
    IAB17_25: { es: 'Power & Motorcycles', en: 'Power & Motorcycles' },
    IAB17_26: { es: 'Pro Basketball', en: 'Pro Basketball' },
    IAB17_27: { es: 'Pro Ice Hockey', en: 'Pro Ice Hockey' },
    IAB17_28: { es: 'Rodeo', en: 'Rodeo' },
    IAB17_29: { es: 'Rugby', en: 'Rugby' },
    IAB17_30: { es: 'Running/Jogging', en: 'Running/Jogging' },
    IAB17_31: { es: 'Sailing', en: 'Sailing' },
    IAB17_32: { es: 'Saltwater Fishing', en: 'Saltwater Fishing' },
    IAB17_33: { es: 'Scuba Diving', en: 'Scuba Diving' },
    IAB17_34: { es: 'Skateboarding', en: 'Skateboarding' },
    IAB17_35: { es: 'Skiing', en: 'Skiing' },
    IAB17_36: { es: 'Snowboarding', en: 'Snowboarding' },
    IAB17_37: { es: 'Surfing/Bodyboarding', en: 'Surfing/Bodyboarding' },
    IAB17_38: { es: 'Swimming', en: 'Swimming' },
    IAB17_39: { es: 'Table Tennis/Ping-Pong', en: 'Table Tennis/Ping-Pong' },
    IAB17_40: { es: 'Tennis', en: 'Tennis' },
    IAB17_41: { es: 'Volleyball', en: 'Volleyball' },
    IAB17_42: { es: 'Walking', en: 'Walking' },
    IAB17_43: { es: 'Waterski/Wakeboard', en: 'Waterski/Wakeboard' },
    IAB17_44: { es: 'World Soccer', en: 'World Soccer' },
    IAB18: { es: 'Style & Fashion', en: 'Style & Fashion' },
    IAB18_1: { es: 'Beauty', en: 'Beauty' },
    IAB18_2: { es: 'Body Art', en: 'Body Art' },
    IAB18_3: { es: 'Fashion', en: 'Fashion' },
    IAB18_4: { es: 'Jewelry', en: 'Jewelry' },
    IAB18_5: { es: 'Clothing', en: 'Clothing' },
    IAB18_6: { es: 'Accessories', en: 'Accessories' },
    IAB19: { es: 'Technology & Computing', en: 'Technology & Computing' },
    IAB19_1: { es: '3-D Graphics', en: '3-D Graphics' },
    IAB19_2: { es: 'Animation', en: 'Animation' },
    IAB19_3: { es: 'Antivirus Software', en: 'Antivirus Software' },
    IAB19_4: { es: 'C/C++', en: 'C/C++' },
    IAB19_5: { es: 'Cameras & Camcorders', en: 'Cameras & Camcorders' },
    IAB19_6: { es: 'Cell Phones', en: 'Cell Phones' },
    IAB19_7: { es: 'Computer Certification', en: 'Computer Certification' },
    IAB19_8: { es: 'Computer Networking', en: 'Computer Networking' },
    IAB19_9: { es: 'Computer Peripherals', en: 'Computer Peripherals' },
    IAB19_10: { es: 'Computer Reviews', en: 'Computer Reviews' },
    IAB19_11: { es: 'Data Centers', en: 'Data Centers' },
    IAB19_12: { es: 'Databases', en: 'Databases' },
    IAB19_13: { es: 'Desktop Publishing', en: 'Desktop Publishing' },
    IAB19_14: { es: 'Desktop Video', en: 'Desktop Video' },
    IAB19_15: { es: 'Email', en: 'Email' },
    IAB19_16: { es: 'Graphics Software', en: 'Graphics Software' },
    IAB19_17: { es: 'Home Video/DVD', en: 'Home Video/DVD' },
    IAB19_18: { es: 'Internet Technology', en: 'Internet Technology' },
    IAB19_19: { es: 'Java', en: 'Java' },
    IAB19_20: { es: 'Javascript', en: 'Javascript' },
    IAB19_21: { es: 'Mac Support', en: 'Mac Support' },
    IAB19_22: { es: 'MP3/MidI', en: 'MP3/MidI' },
    IAB19_23: { es: 'Net Conferencing', en: 'Net Conferencing' },
    IAB19_24: { es: 'Net for Beginners', en: 'Net for Beginners' },
    IAB19_25: { es: 'Network Security', en: 'Network Security' },
    IAB19_26: { es: 'Palmtops/PDAs', en: 'Palmtops/PDAs' },
    IAB19_27: { es: 'PC Support', en: 'PC Support' },
    IAB19_28: { es: 'Portable', en: 'Portable' },
    IAB19_29: { es: 'Entertainment', en: 'Entertainment' },
    IAB19_30: { es: 'Shareware/Freeware', en: 'Shareware/Freeware' },
    IAB19_31: { es: 'Unix', en: 'Unix' },
    IAB19_32: { es: 'Visual Basic', en: 'Visual Basic' },
    IAB19_33: { es: 'Web Clip Art', en: 'Web Clip Art' },
    IAB19_34: { es: 'Web Design/HTML', en: 'Web Design/HTML' },
    IAB19_35: { es: 'Web Search', en: 'Web Search' },
    IAB19_36: { es: 'Windows', en: 'Windows' },
    IAB20: { es: 'Travel', en: 'Travel' },
    IAB20_1: { es: 'Adventure Travel', en: 'Adventure Travel' },
    IAB20_2: { es: 'Africa', en: 'Africa' },
    IAB20_3: { es: 'Air Travel', en: 'Air Travel' },
    IAB20_4: { es: 'Australia & New Zealand', en: 'Australia & New Zealand' },
    IAB20_5: { es: 'Bed & Breakfasts', en: 'Bed & Breakfasts' },
    IAB20_6: { es: 'Budget Travel', en: 'Budget Travel' },
    IAB20_7: { es: 'Business Travel', en: 'Business Travel' },
    IAB20_8: { es: 'By US Locale', en: 'By US Locale' },
    IAB20_9: { es: 'Camping', en: 'Camping' },
    IAB20_10: { es: 'Canada', en: 'Canada' },
    IAB20_11: { es: 'Caribbean', en: 'Caribbean' },
    IAB20_12: { es: 'Cruises', en: 'Cruises' },
    IAB20_13: { es: 'Eastern Europe', en: 'Eastern Europe' },
    IAB20_14: { es: 'Europe', en: 'Europe' },
    IAB20_15: { es: 'France', en: 'France' },
    IAB20_16: { es: 'Greece', en: 'Greece' },
    IAB20_17: { es: 'Honeymoons/Getaways', en: 'Honeymoons/Getaways' },
    IAB20_18: { es: 'Hotels', en: 'Hotels' },
    IAB20_19: { es: 'Italy', en: 'Italy' },
    IAB20_20: { es: 'Japan', en: 'Japan' },
    IAB20_21: { es: 'Mexico & Central America', en: 'Mexico & Central America' },
    IAB20_22: { es: 'National Parks', en: 'National Parks' },
    IAB20_23: { es: 'South America', en: 'South America' },
    IAB20_24: { es: 'Spas', en: 'Spas' },
    IAB20_25: { es: 'Theme Parks', en: 'Theme Parks' },
    IAB20_26: { es: 'Traveling with Kids', en: 'Traveling with Kids' },
    IAB20_27: { es: 'United Kingdom', en: 'United Kingdom' },
    IAB21: { es: 'Real Estate', en: 'Real Estate' },
    IAB21_1: { es: 'Apartments', en: 'Apartments' },
    IAB21_2: { es: 'Architects', en: 'Architects' },
    IAB21_3: { es: 'Buying/Selling Homes', en: 'Buying/Selling Homes' },
    IAB22: { es: 'Shopping', en: 'Shopping' },
    IAB22_1: { es: 'Contests & Freebies', en: 'Contests & Freebies' },
    IAB22_2: { es: 'Couponing', en: 'Couponing' },
    IAB22_3: { es: 'Comparison', en: 'Comparison' },
    IAB22_4: { es: 'Engines', en: 'Engines' },
    IAB23: { es: 'Religion & Spirituality', en: 'Religion & Spirituality' },
    IAB23_1: { es: 'Alternative Religions', en: 'Alternative Religions' },
    IAB23_2: { es: 'Atheism/Agnosticism', en: 'Atheism/Agnosticism' },
    IAB23_3: { es: 'Buddhism', en: 'Buddhism' },
    IAB23_4: { es: 'Catholicism', en: 'Catholicism' },
    IAB23_5: { es: 'Christianity', en: 'Christianity' },
    IAB23_6: { es: 'Hinduism', en: 'Hinduism' },
    IAB23_7: { es: 'Islam', en: 'Islam' },
    IAB23_8: { es: 'Judaism', en: 'Judaism' },
    IAB23_9: { es: 'Latter-Day Saints', en: 'Latter-Day Saints' },
    IAB23_10: { es: 'Paga/Wiccan', en: 'Paga/Wiccan' },
    IAB25_1: { es: 'Unmoderated UGC', en: 'Unmoderated UGC' },
    IAB25_2: { es: 'Extreme Graphic/Explicit Violence', en: 'Extreme Graphic/Explicit Violence' },
    IAB25_3: { es: 'Pornography', en: 'Pornography' },
    IAB25_4: { es: 'Profane Content', en: 'Profane Content' },
    IAB25_5: { es: 'Hate Content', en: 'Hate Content' },
    IAB25_6: { es: 'Under Construction', en: 'Under Construction' },
    IAB25_7: { es: 'Incentivized', en: 'Incentivized' },
    IAB26_1: { es: 'Illegal Content', en: 'Illegal Content' },
    IAB26_2: { es: 'Warez', en: 'Warez' },
    IAB26_3: { es: 'Spyware/Malware', en: 'Spyware/Malware' },
    IAB26_4: { es: 'Copyright Infringement', en: 'Copyright Infringement' }
  },
  iabAffinities: { es: 'Afinidad de IABs', en: 'IABs affinity' },
  iabImpacts: { es: 'Impactos IAB', en: 'IAB impacts' },
  iabList: {es: 'Listado IABs', en: "IAB's list"},
  iabPerformance: { es: 'Performance IAB', en: 'IAB performance' },
  iabs: { es: 'IABs', en: 'IABs' },
  ifNotIncludedInCsv: { es: 'Si no está incluido en CSV', en: 'If not included in CSV' },
  ifNotIncludedInExcel: { es: 'Si no está incluido en Excel', en: 'If not included in Excel' },
  image: { es: 'Imagen', en: 'Image' },
  imageData: {es: 'Datos de la imagen', en: 'Image data'},
  images: { es: 'Imagenes', en: 'Images' },
  impactedUsers: { es: 'Usuarios impactados', en: 'Impacted users' },
  impactedVisits: { es: 'Visitas impactadas', en: 'Impacted visits' },
  impacts: { es: 'Impactos', en: 'impacts' },
  importAudience: {es: 'Importar audiencia', en:'Import audience'},
  importFromCluster: {es: 'Importar desde cluster', en: 'Import from cluster'},
  importYourAudience: { es: 'Importa tu audiencia', en: 'Import your audience' },
  impression: { es: 'Impresión', en: 'Impression' },
  impressions: { es: 'Impresiones', en: 'Impressions' },
  impressionsByUser: {es: 'Impresiones por usuario', en:'Impressions by user'},
  impressionsFirstVisit: { es: 'Impresiones primera visita', en: 'First visit impressions' },
  impressionsUniqueUsers: { es: 'Impresiones usuarios únicos', en: 'Impressions unique users' },
  improvementRatio: { es: 'Ratio de mejora', en: 'Improvement ratio'},
  info: { es: 'Información', en: 'Information'},
  inaccesible: { es: 'Inaccesible', en: 'Inaccesible'},
  inactive: { es: 'Inactivo', en: 'Inactive' },
  include: { es: 'Incluir', en: 'Include' },
  includeZoneProfiling: { es: 'Incluir detalle por zona', en: 'Include detail by zone' },
  incomeAUsers: { es: 'Alto', en: 'High' },
  incomeBUsers: { es: 'M.A.', en: 'M.H.' },
  incomeCUsers: { es: 'Medio', en: 'Medium' },
  incomeDUsers: { es: 'M.B.', en: 'M.L.' },
  incomeEUsers: { es: 'Bajo', en: 'Low' },
  incomeLevels: { es: 'Nivel socioeconómico', en: 'Socieconomic level'},
  income_A: { es: 'Alto', en: 'High' },
  income_B: { es: 'M.A.', en: 'M.H.' },
  income_C: { es: 'Medio', en: 'Medium' },
  income_D: { es: 'M.B.', en: 'M.L.' },
  income_E: { es: 'Bajo', en: 'Low' },
  incorrectValue: { es: 'El valor introducido no es correcto', en: 'The value entered is not correct' },
  indoor: {es: 'Indoor', en:'Indoor'},
  indoorAnalytics: { es: 'Analíticas indoor', en: 'Indoor analytics' },
  infiniaUnification: { es: 'Infinia unificación', en: 'Infinia unification' },
  infiniaAreas: { es: 'Areas Infinia', en: 'Infinia Areas'},
  inhouseCampaigns: { es: 'Campañas Inhouse', en: 'Inhouse campaigns' },
  inLines: {es: ' en las líneas', en:' in lines'},
  installationType: { es: 'Tipo de instalación', en: 'Installation type' },
  insufficientOverplan: { es: 'Soportes mínimos recomendados', en: 'Minimun recommended supports' },
  interaction: { es: 'Interacción', en: 'Interaction' },
  interest: {es: 'Intereses', en: 'Interests'},
  intersection: {es: 'Intersección', en:'Intersection'},
  interstitial: { es: 'Interstitial', en: 'Interstitial' },
  inventory: {es: 'Inventario', en: 'Inventory'},
  investment: {es: 'Inversión', en: 'Investment'},
  keywords: { es: 'Keywords', en: 'Keywords'},
  knownUsers: { es: 'Usuarios conocidos', en: 'Known users' },
  kpi: {es: 'KPI', en: 'KPI'},
  kpis: { es: 'Tráfico al PdV', en: 'Sale Point Traffic' },
  label: {es: 'Etiqueta', en:'Label'},
  language: {
    CC: { es: 'Criss Cross', en: 'Criss Cross' },
    es: 'Idioma',
    ES: { es: 'Español', en: 'Spanish' },
    en: 'Language',
    EN: { es: 'Inglés', en: 'English' }
  },
  languages: { es: 'Idiomas', en: 'Languages' },
  lastAccess: { es: 'Último acceso', en: 'Last access' },
  lastExecution: { es: 'Última ejecución', en: 'Last execution' },
  latitude: { es: 'Latitud', en: 'Latitude' },
  launchPreconfiguration: { es: 'Lanzar preconfiguración', en: 'Launch preconfiguration' },
  launchReporting: { es: 'Lanzar reporte', en: 'Launch reporting' },
  launchReportingConfirm: { es: '¿Seguro que desea generar el reporte de esta campaña?', en: 'Are you sure you want to generate the report for this campaign?' },
  launchReportingMessage: { es: 'El reporte de la campaña ha sido lanzado.', en: 'Campaign report has been released.' },
  level: {es: 'Nivel', en: 'Level'},
  lifetime: { es: 'De por vida', en: 'Lifetime' },
  line: { es: 'Línea', en: 'Line' },
  lineBudget: {es: 'Presupuesto de grupo de anuncios', en: 'Campaign line budget'},
  list: { es: 'Listado', en: 'List' },
  listData: {es: 'Datos del listado', en:'List data'},
  load: {es: 'Carga', en:'Load'},
  loadExcel: { es: 'Cargar Excel', en: 'Load Excel' },
  loadCSV: { es: 'Cargar CSV', en: 'Load CSV' },
  loadFromExcel: { es: 'Cargar desde Excel', en: 'Load from Excel' },
  loadFromFile: { es: 'Cargar desde archivo', en: 'Load from archivo' },
  loadFromCSV: { es: 'Cargar desde CSV', en: 'Load from CSV' },
  loadInventory: {es: 'Carga y geolocaliza el inventario', en: 'Load and geolocate inventory'},
  loadingDOOHLine: { es: 'Cargando datos de grupo de anuncios', en: 'Loading campaign line data' },
  login: { es: 'Acceder', en: 'Login' },
  location: { es: 'Localización', en: 'Location' },
  locationTypes: { es: 'Tipos de emplazamiento', en: 'Location types' },
  locationAffinity: { es: 'Afinidad de localización', en: 'Location affinity'},
  locations: { es: 'Localizaciones', en: 'Locations' },
  locationsSize: { es: 'Número de puntos/polígonos', en: 'Number of points/polygons' },
  locality: { es: 'Localidad', en: 'Locality' },
  locName: { es: 'Nombre', en: 'Name' },
  loginErr: { es: 'Usuario o contraseña incorrectos', en: 'Wrong username or password' },
  logo: { es: 'Logo', en: 'Logo' },
  logout: { es: 'Cerrar sesión', en: 'Log out' },
  longitude: { es: 'Longitud', en: 'Longitude' },
  lookalike: { es: 'Lookalike', en: 'Lookalike' },
  lookalikeLevel: {es: 'Nivel de lookalike', en: 'Lookalike level'},
  low: { es: 'Vivienda baja', en: 'Low house' },
  loyaltyAverage: { es: 'Media de lealtad', en: 'Loyalty average' },
  macAddress: { es: 'Dirección MAC', en: 'MAC address' },
  macroExamples: { es: 'Ejemplos de macro', en: 'Macro examples' },
  mailingCampaigns: { es: 'Campañas buzoneo', en: 'Mailing campaigns' },
  mailTo: {es: 'Destinatario', en:'Mail to'},
  mailSubject: {es: 'Asunto', en:'Subject'},
  mailBody: {es: 'Cuerpo del correo', en:'Body'},
  main: { es: 'Principal', en: 'Main' },
  mainColor: {es: 'Color principal', en: 'Main color'},
  mainFunnel: {es: 'Funnel principal', en: 'Main funnel'},
  major: { es: 'Mayor', en: 'Major' },
  male: { es: 'Hombres', en: 'Male' },
  maleUsers: { es: 'Hombres', en: 'Male' },
  mall: { es: 'Centro comercial', en: 'Mall' },
  MALL: { es: 'Centros comerciales', en: 'Mall' },
  management: { es: 'Gestión', en: 'Management' },
  manageUsers: { es: 'Gestión de usuarios', en: 'Manage users' },
  map: { es: 'Mapa', en: 'Map' },
  mapCorrectionCheckValues: { es: 'Todos los valores deben ser enteros', en: 'All values must be integers' },
  mapCorrectionError: { es: 'Se ha producido un error al procesar la corrección de los mapas', en: 'Some error ocurred processing map corrections' },
  mapCorrectionSuccess: { es: 'Procesando corrección de mapas. Los cambios estarán disponibles en unos 30 minutos.', en: 'Processing map corrections. Changes will be available in about 30 minutes.' },
  markups: { es: 'Recargos', en: 'Markups' },
  marriedCoupleFamilies: { es: 'Parejas y casados', en: 'Married-couple families' },
  massiveEdition: {es: 'Edición masiva', en: 'Massive edition'},
  maxAffinity: { es: 'Máxima afinidad', en: 'Max affinity' },
  maxBannerFormatSpecs: { es: 'Formato del banner', en: 'Banner format' },
  maxFileSizeAllowedIs: { es: 'El tamaño máximo de fichero permitido es', en: 'Maximum file size allowed is' },
  maxVideoFormatSpecs: { es: 'Formato del vídeo', en: 'Video format' },
  maxWeightMB: {es: 'Máximo peso (en MB)', en: 'Maximun weight (MB)'},
  meanEstimation: {es:'Media de estimación de visitas', en: 'Mean visits estimation'},
  meanModel: {es:'Media del modelo', en: 'Mean model'},
  meanReach: {es:'Alcance medio', en: 'Mean reach'},
  mediaType: { es: 'Tipo de medio', en: 'Media type' },
  medium: {es: 'Mediano', en: 'Medium'},
  medianSale: {es: 'Ahorros medios', en: 'Median SEL'},
  medianAge: {es: 'Edad media', en: 'Median age'},
  mediumAge: { es: 'Vivienda antigüedad media', en: 'Middle-aged house' },
  mediumHeight: { es: 'Vivienda media', en: 'Middle house' },
  menuBackground: { es: 'Fondo del menú', en: 'Menu background' },
  menuIconsColor: { es: 'Color de iconos del menú', en: 'Menu icons color' },
  menuSecondaryColor: { es: 'Color secundario del menú', en: 'Menu secondary color' },
  menuTextColor: { es: 'Color de texto del menú', en: 'Menu text color' },
  messages: {es: 'Mensajes', en: 'Messages'},
  metrics: { es: 'Métricas', en: 'Metrics' },
  metricsData: { es:'Datos de métricas', en: 'Metrics data'},
  endpointGraph: { es:'Top 10 endpoints', en: 'Top 10 endpoints'},
  metro: { es: 'Metro', en: 'Subway' },
  midpoint: { es: 'Mitad', en: 'Midpoint' },
  mins: {es: 'Mins', en: 'Mins'},
  minor: { es: 'Menor', en: 'Minor' },
  minute: { es: 'Minuto', en: 'Minute' },
  minutes: {es: 'Minutos', en: 'Minutes'},
  minWeightMB: {es: 'Peso mínimo (en MB)', en: 'Minimun weight (MB)'},
  missingRequired: { es:'Faltan campos obligatorios', en: 'Missing required fields'},
  mobile: { es: 'Móvil', en: 'Mobile' },
  mobileApp: { es: 'App móvil', en: 'App mobile' },
  MobileAppIncludeInfo: { es: 'Lista de Apps separadas por comas', en: 'App comma-separated list' },
  modelAttribution: { es: 'Modelo de atribución', en: 'Model Attribution' },
  modelEstimation: { es: 'Estimación de modelo', en: 'Model estimation' },
  modelName: { es: 'Nombre del modelo', en: 'Model name' },
  modifyLocations: { es: 'Modificar localizaciones para consulta', en: 'Modify locations for request' },
  monday: { es: 'Lunes', en: 'Monday' },
  monitoring: {es: 'Monitorización', en: 'Monitoring'},
  month: { es: 'Mes', en: 'Month' },
  monthDifferenceEqual: { es: 'Igual que el mes anterior', en: 'Same than previous month' },
  monthDifferenceLess: { es: ' menos que el mes anterior', en: ' less than previous month' },
  monthDifferenceMore: { es: ' más que el mes anterior', en: ' more than previous month' },
  monthly: { es: 'Mensual', en: 'Monthly' },
  monthsByIndex: {
    long: {
      0: { es: 'Enero', en: 'January' },
      1: { es: 'Febrero', en: 'February' },
      2: { es: 'Marzo', en: 'March' },
      3: { es: 'Abril', en: 'April' },
      4: { es: 'Mayo', en: 'May' },
      5: { es: 'Junio', en: 'June' },
      6: { es: 'Julio', en: 'July' },
      7: { es: 'Agosto', en: 'August' },
      8: { es: 'Septiembre', en: 'September' },
      9: { es: 'Octubre', en: 'October' },
      10: { es: 'Noviembre', en: 'November' },
      11: { es: 'Diciembre', en: 'December' },
    },
    short: {
      0: { es: 'Ene', en: 'Jan' },
      1: { es: 'Feb', en: 'Feb' },
      2: { es: 'Mar', en: 'Mar' },
      3: { es: 'Abr', en: 'Apr' },
      4: { es: 'May', en: 'May' },
      5: { es: 'Jun', en: 'Jun' },
      6: { es: 'Jul', en: 'Jul' },
      7: { es: 'Ago', en: 'Aug' },
      8: { es: 'Sep', en: 'Sep' },
      9: { es: 'Oct', en: 'Oct' },
      10: { es: 'Nov', en: 'Nov' },
      11: { es: 'Dic', en: 'Dec' },
    },
  },
  moreThanSixMembers: {es: 'Seis o más miembros', en: 'Six or more members'},
  mpu: {es: 'Robapáginas', en: 'MPU'},
  munCodes: {es: 'Códigos', en: 'Codes'},
  municipality: { es: 'Municipio', en: 'Municipality' },
  mupi: {es:'Mupi', en: 'Mupi'},
  mute: { es: 'Mute', en: 'Mute' },
  myBrand: { es: 'Mi marca', en: 'My brand' },
  myDownloads: { es: 'Mis descargas', en: 'My downloads' },
  myUser: { es: 'Mi usuario', en: 'My user' },
  name: { es: 'Nombre', en: 'Name' },
  narrowDownAudienceByCampaign: { es: 'Acota tu audiencia según el comportamiento en campañas', en: 'Narrow down your audience by the behavior in campaigns' },
  narrowDownAudienceByTrackers: { es: 'Acota tu audiencia por detección en el punto de venta', en: 'Narrow down your audience by detection at point of sale' },
  national: { es: 'Nacional', en: 'National' },
  nationality: { es: 'Nacionalidad', en: 'Nationality' },
  nationalShare: { es: 'Share nacional', en: 'National share' },
  native: { es: 'Nativo', en: 'Native' },
  native_ad: { es: 'Native ad', en: 'Native ad' },
  newAudience: {es: 'Nueva audiencia', en: 'New audience'},
  newCampaign: { es: 'Nueva campaña', en: 'New campaign' },
  newXandrCampaign: { es: 'Nueva campaña Xandr', en: 'New Xandr campaign' },
  newFolder: { es: 'Nueva carpeta', en: 'New folder' },
  newImage: { es: 'Nueva imagen', en: 'New image' },
  newPassword: { es: 'Nueva contraseña', en: 'New password' },
  newPromotion: { es: 'Nueva promoción', en: 'New promotion' },
  newOrderLine: { es: 'Nueva línea de pedido', en: 'New order line' },
  newReporting: {es: 'Nuevo reporting', en: 'New reporting'},
  newScheduledReport: { es: 'Nuevo reporte programado', en: 'New scheduled report' },
  newSegment: {es: 'Nuevo segmento', en: 'New segment'},
  NIGHTLIFE: { es: 'Bar', en: 'Nightlife' },
  no: { es: 'No', en: 'No' },
  noActive: { es: 'No activo', en: 'No active' },
  noAddress: { es: 'Sin dirección', en: 'No address'},
  noCircuit: {es: 'Sin circuito', en: 'No circuit'},
  noCircuitsForVendor: {es: 'Vendor sin circuitos', en: 'Vendor without circuits'},
  noVendorForCountry: {es: 'País sin vendors', en: 'Country without vendors'},
  noData: { es: 'No hay datos', en: 'No data' },
  noDataFor: { es: 'No hay datos para ', en: 'No data for ' },
  noDspErrors: { es: 'La campaña no tiene errores de envío a DSP', en: 'The campaign has no errors in sending to DSP' },
  noFilter: {es: 'Sin filtro', en: 'No filter'},
  noMandatoryName: {es: '* En caso de no introducir un valor, se obtiene el nombre del archivo', en: 'If you do not enter a value, file name is obtained'},
  noMessages: {es: 'No hay mensajes', en: 'No messages'},
  none: { es: 'Ninguno', en: 'None' },
  nonFamilyHousehold: { es: 'Familia sin parentesco', en: 'Non family household' },
  noPacing: { es: 'Sin ritmo', en: 'No pacing' },
  noPoisFound: { es: 'No se han encontrado POIs', en: 'No POIs found'},
  noRepetition: { es: 'Sin repetición', en: 'No repetitions'},
  notSelected: { es: 'No seleccionados', en: 'Not selected'},
  nse: { es: 'Nivel socioeconómico', en: 'Socioeconomic level' },
  numberSupports: {es: 'Nº Soportes', en: "Support's number"},
  numCircuits: { es: 'Nº circuitos', en: '# circuits' },
  method: { es: 'Método', en: 'Method' },
  objectiveUniverse: {es: 'Universo objetivo', en: 'Objective universe'},
  of: { es: 'de', en: 'of' },
  ofTheCity: {es: 'de la localidad', en: 'of the city'},
  offlineVisits: {es: 'Visitas offline', en: 'Offline visits'},
  old: { es: 'Vivienda antigua', en: 'Old house' },
  oldPassword: { es: 'Contraseña antigua', en: 'Old password' },
  OOH: { es: 'Circuitos', en: 'Circuits' },
  oneMember: { es: 'Un miembro', en: 'One member' },
  oohCampaigns: {es: 'Campañas OOH', en:'OOH Campaigns'},
  oohSubcampaigns: {es: 'Campañas vendor', en:'Vendor campaigns'},
  optimal: { es: 'Óptima', en: 'Optimal'},
  orderCode: { es: 'Código de pedido', en: 'Order code' },
  orderLine: {
    configuration:{es:'Configuración de la línea de pedido', en: 'Order line configuration'},
    data:{es:'Datos de la línea de pedido', en: 'Order line data'},
    en: 'Order line',
    es: 'Línea de pedido',
    name: {es:'Nombre de la línea de pedido', en: 'Order line name'}
  },
  orderLineData: { es: 'Datos de la línea de pedido', en: 'Order line data' },
  orderLines: { es: 'Líneas de pedido', en: 'Order lines' },
  orders: { es: 'Pedidos', en: 'Orders' },
  orientation: {es: 'Orientación', en: 'Orientation'},
  origin: {es: 'Origen', en:'Origin'},
  os: { es: 'Sistema Operativo', en: 'Operating System' },
  osVersion: { es: 'Versión del sistema operativo', en: 'Operating system version' },
  other: { es: 'Otro', en: 'Other' },
  otherCost: { es: 'Otro coste', en: 'Other cost' },
  otherData: { es: 'Otros datos', en: 'Other data' },
  others: { es: 'Otros', en: 'Others' },
  outdoor: {es: 'Outdoor', en: 'Outdoor'},
  outdoorCampaigns: { es: 'Campañas de outdoor', en: 'Outdoor campaigns' },
  outdoorCampaignsReporting: {es: 'Reporte de campañas outdoor', en: 'Outdoor campaigns reporting'},
  outdoorUsers: { es: 'Usuarios detectados en el periodo de campaña exterior en las localizaciones del circuito', en: 'Users detected in the outdoor campaign period at the locations of the circuit' },
  overPlanning: { es: 'Sobreplanificación', en: 'Overplanning' },
  overPlanningWarningText: { es: 'Seleccione un porcentaje de sobreplanificación de impactos para intentar consumir todo el presupuesto de su línea de campaña', en: 'Select a overplanning percentage in order to try to consume all the budget from the campaign line' },
  pacing: { es: 'Pacing', en: 'Pacing' },
  pageResults : {es:'Resultados por página', en:'Page results'},
  parallax: { es: 'Parallax', en: 'Parallax' },
  PARKING: {es: 'Aparcamiento', en: 'Parking'},
  partialDuplicate: { es: 'Duplicado parcial', en: 'Partial duplicate' },
  partialDuplicateAudienceConfirm: { es: '¿Seguro que desea duplicar parcialmente esta audiencia?', en: 'Are you sure you want to partially duplicate this audience?' },
  partnerId: { es: 'Partner Id', en: 'Partner Id' },
  partOfGroupMarker: { es: 'GRUPO DE MARCADORES', en: 'MARKERS GROUP' },
  passersBy: { es: 'Transeúntes', en: 'Passers by' },
  passPerHour: { es: 'Pases/hora', en: 'Pass/hour' },
  password: { es: 'Contraseña', en: 'Password' },
  path: { es: 'Ruta', en: 'Path' },
  pause: { es: 'Pausa', en: 'Pause' },
  pending: { es: 'Pendiente', en: 'Pending' },
  peopleDetected: { es: 'Personas detectadas', en: 'People detected' },
  peopleFlow: { es: 'Flujo de personas', en: 'Flow of people' },
  peopleInShop: { es: 'Personas en tienda', en: 'People in shop' },
  percCompleted: { es: 'Porcentaje completado', en: 'Percentage completed' },
  percentage: { es: 'Porcentaje', en: 'Percentage' },
  performance: {es:'Performance', en:'Performance'},
  performanceByCity: {es: 'Performance por municipio', en: 'Performance by city'},
  performanceByDayOfWeek: { es: 'Performance / día de la semana', en: 'Performance / day of week' },
  performanceByHour: { es: 'Performance / hora', en: 'Performance / hour' },
  performanceByOS: { es: "Performance por SO", en: "Performance by OS" },
  performanceByRegion: {es: 'Performance por región', en: 'Performance by region'},
  performanceByShop: {es: 'Performance por tienda', en: 'Performance by shop'},
  period: {es:'Periodo', en:'Period'},
  periodAndDates: {es:'Periodo y fechas', en:'Period and dates'},
  permissions: { es: 'Permisos', en: 'Permissions' },
  peruExtrapolation: { es: 'Extrapolación Perú', en: 'Peru Extrapolation'},
  physicalAttribution: {
    factor: {
      low: {
        distance: { es: 'Favorecer distancia cercana', en: 'Favouring close distance'},
        nse: { es: 'Favorecer NSE bajo', en: 'Favouring low NSE'},
        age: { es: 'Favorecer edad baja', en: 'Favouring low age'},
        houseHeight: { es: 'Favorecer vivienda baja', en: 'Favouring small house'},
        houseAge: { es: 'Favorecer vivienda nueva', en: 'Favouring new house'},
        populationDensity: { es: 'Favorecer densidad baja', en: 'Favouring low density'}
      },
      high: {
        distance: { es: 'Favorecer distancia lejana', en: 'Favouring far distance'},
        nse: { es: 'Favorecer NSE alto', en: 'Favouring high NSE'},
        age: { es: 'Favorecer edad alta', en: 'Favouring high age'},
        houseHeight: { es: 'Favorecer vivienda alta', en: 'Favouring tall house'},
        houseAge: { es: 'Favorecer vivienda vieja', en: 'Favouring old house'},
        populationDensity: { es: 'Favorecer densidad alta', en: 'Favouring high density'}
      }
    },
    coefficient: {
      lowest: { es:'Nulo', en:'Null'},
      low: { es:'Bajo', en:'Low'},
      medium: { es:'Medio', en:'Medium'},
      high: { es:'Alto', en:'High'},
      extreme: { es:'Extremo', en:'Extreme'},
    }
  },
  pixel3rdParty: { es: 'Pixel de terceros', en: 'Third party Pixel' },
  PORT: { es: 'Puerto', en: 'Port' },
  RAIL_STATION: { es: 'Estación de tren', en: 'Rail Station' },
  STREET_FURNITURE: { es: 'Calle', en: 'Street furniture' },
  SUPERMARKET: { es: 'Supermercados', en: 'Supermarket' },
  THEATER: { es: 'Cines/Teatros', en: 'Theather' },
  BANK: { es: 'Banco', en: 'Bank' },
  TAXI: { es: 'Taxi', en: 'Taxi' },
  BUSES: { es: 'Buses', en: 'Buses' },
  UNIVERSITIES: { es: 'Universidades y colegios', en: 'Colleges & Universities' },
  DOCTORS_OFFICE: { es: 'Consultorios médicos', en: 'Doctor\'s Offices' },
  GAS_STATIONS: { es: 'Gasolineras', en: 'Gas Stations' },
  OFFICE_BUILDINGS: { es: 'Oficinas', en: 'Office Buildings' },
  SALONS: { es: 'Peluquerías', en: 'Salons' },
  UNKNOWN: { es: 'Desconocido', en: 'Unknown' },
  planification: { es: 'Planificación', en: 'Planification' },
  planned: { es: 'Planificado', en: 'Planned' },
  planner: { es: 'Planificador', en: 'Planner' },
  platform: { es: 'Plataforma', en: 'Platform' },
  locationType: { es: 'Emplazamiento', en: 'Placement' },
  placementType: { es: 'Emplazamiento', en: 'Placement' },
  playoutsPerHour: { es: 'Reproducciones por hora', en: 'Playouts per hour'},
  pointRadius: { es: 'Punto-radio', en: 'Point-radius'},
  POINT: { es: 'Punto-radio', en: 'Point-radius' },
  pois: { es: 'Puntos de interés', en: 'Points of interest' },
  POI: { es: 'Puntos de interés', en: 'Points of interest' },
  points: { es: 'Puntos', en: 'Points' },
  polygons: { es: 'Polígonos', en: 'Polygons' },
  populationDensity: { es: 'Densidad de población', en: 'Population density' },
  position: {es: 'Posición', en: 'Position'},
  precision: {es: 'Precisión', en: 'Precision'},
  premium: { es: 'Premium', en: 'Premium' },
  preview: {es:'Previsualizar', en: 'Preview'},
  priceFourteen: {es:'Precio catorcena', en: 'Fourteen price'},
  pricePass: { es: 'Precio Pase', en: 'Pass price' },
  print: { es: 'Imprimir', en: 'Print' },
  processed: { es: 'Procesado', en: 'Processed' },
  processStatus:{ es: 'Estado procesos', en: 'Process status'},
  productExposition: { es: 'Exposición al producto', en: 'Exposure to the product' },
  productExpositionLables: {
    between1And2: { es: '1-2', en: '1-2' },
    between3And6: { es: '3-6', en: '3-6' },
    between7And15: { es: '7-15', en: '7-15' },
    moreThan15: { es: '+15', en: '+15' },
  },
  profiling: { es: 'Perfilado', en: 'Profiling' },
  PROGRAMMATIC_GUARANTEED_DOOH: { es: 'Garantizada', en: 'Guaranteed' },
  PROGRAMMATIC_NOT_GUARANTEED_DOOH: { es: 'No garantizada', en: 'Not guaranteed' },
  promotion: { es: 'Promoción', en: 'Promotion'},
  promotions: { es: 'Promociones', en: 'Promotions'},
  promotionsAvailable: { es: 'Promociones disponibles', en: 'Promotions available' },
  provider: { es: 'Proveedor', en: 'Provider' },
  providerId: { es: 'ID de proveedor', en: 'Provider ID' },
  province: {es: 'Provincia', en: 'Province'},
  psychographicProfile: { es: 'Perfil psicográfico', en: 'Psychographic Profile' },
  publicRoad:{es:'Vía pública', en:'Public road'},
  publish: { es: 'Publicar', en: 'Publish' },
  publisherToken: { es: 'Publisher Token', en: 'Publisher Token' },
  publishers: { es: 'Publishers', en: 'Publishers' },
  publishersReporting: { es: 'Reporte de publisher', en: 'Publisher report' },
  qualitativeAnalytics: { es: 'Analítica cualitativa', en: 'Qualitative analytics' },
  qualitativeNotReadyMsg: {es: 'Los informes cualitativos de los usuarios generales y de los afines al target que pasan por el circuito estarán disponibles el día ', en: 'Qualitative reports of general and related target users passing by the circuit will be ready by '},
  quantitativeAnalytics: { es: 'Analítica cuantitativa', en: 'Quantitative analytics' },
  quantitativeModel: { es: 'Modelo cuantitativo', en: 'Quantitative model' },
  quantity: { es: 'Cantidad', en: 'Quantity' },
  quitReporting: { es: 'Salir de reporte', en: 'Quit reporting' },
  quitEdition: { es: 'Salir de edición', en: 'Quit edition'},
  radiusInMeters: { es: 'Radio (m)', en: 'Radius (m)' },
  radiusTooLarge: { es: 'Demasiada zona seleccionada. Por favor, reduce el tamaño de la selección.', en: 'Too much area selected. Please reduce the size of the selection.' },
  rate: { es: 'Tarifa', en: 'Rate' },
  ratio: {es: 'Ratio', en: 'Ratio'},
  reach: { es: 'Alcance', en: 'Reach' },
  reachAuf: { es: 'Alcance a la región', en: 'Auf Reach' },
  reachByCampaign: {es: 'Alcance por campaña', en: 'Reach by campaign'},
  reachCountry: { es: 'Alcance al país', en: 'Country Reach' },
  reachMonetizing: { es: 'Reach y monetización', en: 'Reach and monetizing' },
  reasignAudiences: { es: 'Reasignar audiencias', en: 'Reasign audiences' },
  reasignAudiencesWarning: { es: 'El usuario va a poder tener acceso a todas aquellas audiencias cuyas agencias estén asignadas al usuario.', en: 'User will have access to all audiences which their agencies are asignated to the user.' },
  recentlyUpdated: {es: 'Recientemente subidas', en: 'Recently updated'},
  region: { es: 'Región', en: 'Region' },
  reject: { es: 'Rechazar', en: 'Reject' },
  rejectCause: { es: 'Motivo del rechazo', en: 'Reject cause' },
  rejectCausePlaceholder: {es: 'Describa brevemente el motivo del rechazo', en:'Describe briefly the reason of rejection'},
  relatedTargetUsersThroughCircuit: { es: 'Usuarios afines al target que pasan por el circuito', en: 'Related target users through the circuit' },
  relatedUsersThroughCircuit: {es: 'Usuarios generales que pasan por el circuito', en: 'General users through the circuit'},
  remove: { es: 'Eliminar', en: 'Remove' },
  removeAudiences: { es: 'Quitar audiencias', en: 'Remove audiences' },
  removeAudiencesWarning: { es: 'Se quitarán todas las audiencias asignadas a este usuario.', en: 'All audiences asignated to this user will be removed.' },
  removeCircuit: { es: 'Quitar circuito', en: 'Remove circuit' },
  removeFromCircuit: {es: 'Quitar del circuito', en: 'Remove from circuit'},
  removePois: { es: 'Borrar puntos', en: 'Remove points' },
  removeSupports: { es: 'Quitar soportes', en: 'Remove supports' },
  removeSupportsToSelection: { es: 'Quitar soportes a la selección', en: 'Remove supports to selection' },
  rename: { es: 'Renombrar', en: 'Rename' },
  renameCreative: { es: 'Renombrar creatividad', en: 'Rename creative' },
  repeatNewPassword: { es: 'Repetir nueva contraseña', en: 'Repeat new password' },
  reportClicks: { es: 'Clicks', en: 'Clicks' },
  reportCtr: { es: 'CTR', en: 'CTR' },
  reportData: { es: 'Datos del reporte', en: 'Report data' },
  reportName: { es: 'Nombre del reporte', en: 'Report name' },
  reportType: { es: 'Tipo de reporte', en: 'Report type' },
  reportImpressions: { es: 'Impresiones', en: 'Impressions' },
  reportImpressionsToday: { es: 'Impresiones hoy', en: 'Today impressions' },
  reportImpressionsYesterday: { es: 'Impresiones ayer', en: 'Yesterday impressions' },
  reporting: { es: 'Reporte', en: 'Reporting' },
  reportings: { es: 'Reportes', en: 'Reportings' },
  requestsBreakdown: {es:'Desglose de peticiones', en:'Requests breakdown'},
  requiredField: {es: 'Campo obligatorio', en: 'Required field'},
  reseller: { es: 'Reseller', en: 'Reseller' },
  resellers: { es: 'Resellers', en: 'Resellers' },
  restaurant: { es: 'Restaurante', en: 'Restaurant' },
  RESTAURANT: { es: 'Restaurante', en: 'Restaurant/Gastro Market' },
  restoreDefault: { es: 'Restaurar valores por defecto', en: 'Restore default values' },
  results: { es: 'Resultados', en: 'Results' },
  resultsByMode: {es: 'Resultados por modalidad', en: 'Results by mode'},
  resume: { es: 'Reanudar', en: 'Resume' },
  responseStatus: { es: 'Respuesta', en: 'Response' },
  response: { es: 'Respuesta', en: 'Response' },
  retailer: { es: 'Retailer', en: 'Retailer' },
  retailers: { es: 'Retailers', en: 'Retailers' },
  revenue: { es: 'Ingresos', en: 'Revenue' },
  revenueType: { es: 'Tipo de ingresos', en: 'Revenue type' },
  review: { es: 'Revisión', en: 'Review' },
  routerModel: { es: 'Modelo de router', en: 'Router model' },
  routerSerial: { es: 'Número de serie del router', en: 'Router serial' },
  routerImei: { es: 'Imei del router', en: 'Router Imei' },
  running: { es: 'En ejecución', en: 'Running' },
  salePoint: {es: 'Punto de venta', en: 'Point of sale'},
  salePointAttribution: {es: 'Modelo de atribución a punto de venta', en: 'Point of sale attribution model'},
  salesPoints: { es: 'Puntos de venta', en: 'Points of sale' },
  same: { es: 'Misma', en: 'Same' },
  saturday: { es: 'Sábado', en: 'Saturday' },
  save: { es: 'Guardar', en: 'Save' },
  saveAudience: {es: 'Guardar audiencia', en:'Save audience'},
  saveFormats: { es: 'Guardar formatos', en: 'Save formats' },
  schedule: { es: 'Horario', en: 'Schedule' },
  screens: {es: 'Pantallas', en: 'Screens'},
  screenSizes: {es: 'Tamaños de pantalla', en:'Screen sizes'},
  sdkEvent: {es: 'Evento Sdk', en:'Sdk Event'},
  search: { es: 'Buscar', en: 'Search' },
  searchingSupportsMsg: { es: 'Estamos buscando los soportes más afines a su público objetivo', en: 'We are searching more related to your objective target'},
  searchType: { es: 'Tipo de Búqueda', en: 'Search Type' },
  searchVolume: { es: 'Volumen de búsquedas', en: 'Search volume' },
  searchLocations: { es: 'Buscar localizaciones', en: 'Search locations' },
  secondaryColor: {es: 'Color secundario', en: 'Secondary color'},
  seconds: {es: 'Segundos', en: 'Seconds'},
  secs: {es: 'Segs', en: 'Secs'},
  section: { es: 'Sección', en: 'Section' },
  sections: { es: 'Secciones', en: 'Sections' },
  sectionsTrackers: { es: 'Trackers por puntos de venta', en: 'Trackers by sales points' },
  sector: { es: 'Sector', en: 'Sector' },
  sectorAverage: { es: 'Media del sector', en: 'Sector average' },
  seeOtherCampaignLines: {es:'Ver otros grupos de anuncios', en: 'Other Campaign lines'},
  segmentationView: { es: 'Vista de segmentación', en: 'Segmentation view' },
  segments: {es: 'Segmentos', en: 'Segments'},
  selectAgencyAndAdvertiser: {es:'Seleccione agencia y anunciante', en: 'Select agency and advertiser'},
  selectAll: {es: 'Selecciona todos', en: 'Select all'},
  selectAnElementFromTheList: { es: 'Selecciona un elemento de la lista', en: 'Select an element from the list' },
  selectAppsCategories: { es: 'Selecciona las categorías de Apps', en: 'Select Apps categories' },
  selectAudience: { es: 'Selecciona la audiencia', en: 'Select your audience' },
  selectCampaignDates: { es: 'Selecciona fechas de la campaña', en: 'Select campaign dates' },
  selectCohorts: { es: 'Seleccciona cohorts', en: 'Select cohorts' },
  selectCompetitorsCircuits: {es: 'Elige circuitos de la competencia', en: 'Choose competitor circuits'},
  selectCountryAndPeriod: { es: 'Selecciona país y periodo', en: 'Select country and period' },
  selectDataToDisplayOnMap: { es: 'Selecciona qué datos se muestran en el mapa', en: 'Select data to display on map' },
  selectDates: { es: 'Selecciona fechas', en: 'Select dates' },
  selectDefault: { es: 'Selecciona una opción', en: 'Please select an option' },
  selectDownloadedApps: { es:'Selecciona las Apps descargadas/utilizadas por la audiencias los últimos 90 días', en:'Select downloaded/used apps by audiences in the last 90 days'},
  selectedCreatives: { es: 'Creatividades seleccionadas', en: 'Selected creatives' },
  selectedFormats: { es: 'Formatos seleccionados', en: 'Selected formats' },
  selectedSchedules: {es: 'Horarios seleccionados', en: 'Selected schedules'},
  selectEvaluationDates: { es: 'Selecciona fechas para la evaluación', en: 'Select evaluation dates' },
  selectEvents: { es: 'Selecciona eventos', en: 'Select events' },
  selectEventsForCampaign: { es: 'Selecciona eventos para la campaña', en: 'Select events for campaign' },
  selectIab: { es: 'Selecciona los intereses IAB', en: 'Select IAB interests' },
  selectionRange: {es: 'Selección de horario', en: 'Selection range'},
  selectRetailer: { es: 'Selecciona retailer', en: 'Please select retailer' },
  selectKeywords: {es: 'Selecciona los intereses de Keywords', en:'Select Keywords interests'},
  selectLocations: { es: 'Seleccionar localizaciones', en: 'Select locations' },
  selectPois: { es: 'Selección de puntos de interés', en: 'POIs selection' },
  selected: { es: 'Seleccionados', en: 'Selected' },
  selectedSupports: { es: 'Soportes seleccionados', en: 'Selected supports' },
  selectOnMap: { es: 'Selecciona sobre el mapa', en: 'Select on map' },
  selectPeriodAndDate: { es: 'Seleccione un periodo y unas fechas', en: 'Select a period and dates' },
  selectSchedule: { es: 'Selección de horario', en: 'Schedule selection'},
  selectSiteEvents: {es: 'Selecciona eventos del Site', en: 'Select Site events'},
  selectSites: {es: 'Selecciona tus Sites', en: 'Select your Sites'},
  selectVendor: { es: 'Selecciona un vendor', en: 'Select a vendor' },
  selectVendorAndCountry: { es: 'Selecciona un vendor y un país', en: 'Select a vendor and a country' },
  selectCountry: { es: 'Selecciona un país', en: 'Select a country' },
  selectZonesWhereAudienceCirculates: { es: 'Escoge las zonas por las que circula la audiencia', en: 'Select zones where the audience circulates' },
  send: { es: 'Envío', en: 'Send' },
  sendEmail: { es: 'Envío email', en: 'Send email' },
  sendFrequency: { es: 'Frecuencia de envío', en: 'Send frequency' },
  sendToBeachFront: { es: 'Enviar a Beachfront', en: 'Send to Beachfront' },
  seniorAlone: { es: 'Anciano solo', en: 'Senior alone' },
  seniorCouple: { es: 'Pareja ancianos', en: 'Senior couple' },
  serialNumber: { es: 'Número de serie', en: 'Serial number' },
  served: { es: 'Servido', en: 'Served' },
  servedHits: { es: 'Impac/S', en: 'Impac/S' },
  sessionTimedOut: {
    es: 'Su sesión ha caducado. Por favor inicie sesión para seguir navegando por la plataforma.',
    en: 'Your session has timed out. Please login to continue using the platform.'
  },
  scheduledReports: {es: 'Reportes programados', en: 'Scheduled reports'},
  scheduledReportsList: {es: 'Lista de reportes programados', en: 'Scheduled reports list'},
  scheduling: {es: 'Planificación', en: 'Scheduling'},
  sectionData: {es: 'Datos de la sección', en: 'Section data'},
  showImpacts: {es: 'Mostrar información de impactos y frecuencias', en: 'Show impact and frequency information'},
  showPOIs: { es: 'POIs', en: 'POIs' },
  simProvider: {es: 'Proveedor de SIM', en: 'SIM provider'},
  simPhoneNumber: {es: 'Teléfono', en: 'Phone number'},
  simId: {es: 'Id de SIM', en: 'SIM id'},
  simPin: {es: 'Pin', en: 'Pin'},
  simPuk: {es: 'Puk', en: 'Puk'},
  singleMaleHouseholder: {es: 'Hombre soltero', en: 'Single male householder'},
  singleFemaleHouseholder: {es: 'Mujer soltera', en: 'Single female householder'},
  shop: {es: 'Tienda', en: 'Shop'},
  shopAssistantModel: { es: 'Modelo personas en tienda', en: 'Shop assistant model' },
  shopAttribution: { es: 'Atribución a tienda', en: 'Shop attribution' },
  shopType: { es: 'Tipo de tienda', en: 'Shop type' },
  shortAudienceByAdvertisingProfile: { es: 'Acota tu audiencia según el perfil publicitario', en:'Narrow down your audience by advertising profile'},
  shortAudienceByCohorts: { es: 'Acota tu audiencia según cohorts', en: 'Narrow down your audience by cohorts'},
  shortAudienceByDemographic: { es: 'Acota tu audiencia según el perfil socio-demográfico', en: 'Narrow down your audience by socio-demographic profile' },
  shortAudienceByDigitalInterests: { es: 'Acota tu audiencia según los intereses digitales', en: 'Narrow down your audience by digital interests' },
  shortAudienceByGeoContextualProfile: { es: 'Acota tu audiencia según su perfil Geo-contextual', en: 'Narrow down your audience by Geo-contextual profile' },
  shortAudienceBySiteEvents: { es: 'Acota tu audiencia según uno o varios Sites/Eventos', en: 'Narrow down your audience by one or many Sites/Events' },
  shortByDatesAndSchedule: {es: 'Acota por fechas y horarios', en: 'Short by dates and schedule'},
  shortAudienceByHousehold: {es: 'Acota tu audiencia según su perfil familiar', en: 'Narrow down your audiences by their household profile'},
  showAllSupports: { es: 'Muestra todos los soportes', en: 'Show all supports' },
  showingInGraph: { es: 'Mostrando en la gráfica', en: 'Showing in graph' },
  showInGraph: { es: 'Mostrar en la gráfica', en: 'Show in graph' },
  simulate: { es: 'Simular', en: 'Simulate' },
  sites: { es: 'Sites', en: 'Sites' },
  size: {es: 'Tamaño', en: 'Size'},
  small: {es:'Pequeño', en:'Small'},
  socialConcerns: { es: 'Social concerns', en: 'Social concerns'},
  socioeconomicLevel: { es: 'Nivel socioeconómico', en: 'Socioeconomic level' },
  spend: { es: 'Gasto', en: 'Spend' },
  spendWithVendorFees: { es: 'Gasto con tarifas del vendor', en: 'Spend with vendor fees' },
  start: { es: 'Inicio', en: 'Start' },
  startDate: { es: 'Fecha de inicio', en: 'Start date' },
  startDateError: { es: 'La fecha de inicio no puede ser superior a la fecha fin', en: 'The start date cannot be longer than the end date' },
  state: { es: 'Estado', en: 'State' },
  states: { es: 'Estados', en: 'States' },
  statisticLayerPercent: { es: 'Porcentaje capa estadística', en: 'Statistic layer percent' },
  statisticLayerRadius: { es: 'Radio capa estadística', en: 'Statistic layer radius' },
  status: { es: 'Estado', en: 'Status' },
  statusCampaign: { es: 'Estado campaña', en: 'Status campaign' },
  sticky_ad: {es: 'Sticky Ad', en: 'Sticky Ad'},
  stop: { es: 'Parar', en: 'Stop' },
  stopCampaign: { es: 'Parar campaña', en: 'Stop campaign' },
  stopOrderLineConfirm: { es: '¿Seguro que desea parar esta linea de pedido?', en: 'Are you sure you want to stop this order line?' },
  stopped: { es: 'Detenido', en: 'Stopped' },
  successDeleting: { es: 'Los datos se han borrado correctamente', en: 'Success deleting data' },
  successDownloading: {es: 'Los datos se han descargado correctamente', en: 'Success downloading data'},
  successGenerating: {es: 'Los datos se han generado correctamente', en: 'Success generating data'},
  successPublish: {es: 'La campaña se va a publicar', en: 'Campaign is going to be published'},
  successReportSend: {es: 'El reporte se ha enviado correctamente a su destinatario', en: 'The report has been successfully sent'},
  successRequest: {es: 'Se ha enviado la petición correctamente, consulta el resultado en los proximos minutos', en: 'Request sent, check results in some minutes'},
  successSaving: {es: 'Los datos se han guardado correctamente', en: 'Success saving data'},
  successSavingOrder: {es: 'Se ha generado una nueva orden de pedido', en: 'A new purchase order has been generated.'},
  successUpload: { es: 'Los datos se han subido correctamente', en: 'Success uploading data' },
  successUploadingImproveDigital: {es: 'El segmento se subirá en los próximos minutos', en: 'Segment will be uploaded in the next minutes'},
  successUploadWarning: {es: 'Los datos se han subido correctamente. El proceso tardará unos minutos', en: 'Success uploading data. The process will take a few minutes' },
  summary: { es: 'Resumen', en: 'Summary' },
  sunday: { es: 'Domingo', en: 'Sunday' },
  support: { es: 'Soporte', en: 'Support' },
  supports: { es: 'Soportes', en: 'Supports' },
  supportAnalysis: { es: 'Listado de soportes', en: 'Supports list' },
  supportData: {es: 'Datos del soporte', en: "Support's data"},
  supportManagement: { es: 'Gestión de soportes vendor', en: 'Vendor supports management'},
  supportMeasurement: { es: 'Inventario', en: 'Inventory' },
  supportOrientation: { es: 'Orientación del soporte', en: 'Support orientation' },
  supportProfile: { es: 'Perfilado del soporte', en: 'Support profile' },
  supportSelected: {es: 'Soporte seleccionado', en: 'Support selected'},
  supportsList: { es: 'Listado de soportes', en: 'Supports list' },
  supportSearch: { es: 'Buscar soportes', en: 'Support search' },
  supportsSelected: { es: 'Soportes seleccionados', en: 'Supports selected' },
  supportSize: { es: 'Tamaño del soporte', en: 'Support size' },
  supportsManagement: { es: 'Gestión de soportes', en: 'Supports management' },
  supportType: { es: 'Tipo de soporte', en: 'Support type' },
  surname: {es: 'Apellido', en: 'Surname'},
  table: { es: 'Tabla', en: 'Table' },
  targetAffinity: { es: 'Afinidad al target', en: 'Target affinity' },
  targeting: { es: 'Targeting', en: 'Targeting' },
  targetReach: {es: 'Reach target', en: 'Reach target'},
  technicalContact: {es: 'Contacto técnico', en: 'Technical contact'},
  template: { es: 'Plantilla', en: 'Template' },
  temporalAffinity: { es: 'Afinidad temporal', en: 'Temporal affinity' },
  text: { es: 'Texto', en: 'Text' },
  thankYouPage: { es: 'Thank you page', en: 'Thank you page' },
  threeMembers: { es: 'Tres miembros', en: 'Three members' },
  thirdQuartile: { es: 'Tercer cuartil', en: 'Third quartile' },
  thursday: { es: 'Jueves', en: 'Thursday' },
  times: { es: 'Veces', en: 'Times' },
  timeUnit: {es: 'Unidad de tiempo', en:'Time unit'},
  timeZone: {es: 'Zona horaria', en:'Time zone'},
  to: { es: 'Hasta', en: 'To' },
  toCreateFunnelDescription: { es:'Para crear tus funnels de conversión, debes seleccionar las etapas de tu funnel y añadir la audiencia que queres observar en cada etapa', en:'To create your conversion funnels you have to choose your funnel stages and add the audience you want to watch for each'},
  todas: { es: 'Todas', en:'All' },
  toEstimation: { es: 'Estimación hasta', en: 'Estimation to' },
  toHour: { es: 'Hasta la hora', en: 'To hour' },
  toModel: { es: 'Modelo hasta', en: 'Model to' },
  tooManyCircuitsMsg: {es: 'El archivo no puede superar los 10.000 circuitos', en: "File can't exceed 10,000 circuits"},
  top10cities: {es: 'Top 10 ciudades', en: 'Top 10 cities'},
  top10locations: { es: 'Top 10 localizaciones', en: 'Top 10 locations' },
  top10locationCities: { es: 'Ciudades (Top 10 localizaciones)', en: 'Cities (Top 10 locations)' },
  topSites: {es: 'Top Sites', en: 'Top Sites'},
  top10Supports: { es: 'Top 10 soportes', en: 'Top 10 soportes' },
  top10zips: { es: 'Top 10 códigos postales', en: 'Top 10 zip codes' },
  total: { es: 'Total', en: 'Total' },
  totalBudget: { es: 'Total gasto', en: 'Total budget' },
  totalClicks: {es: 'Clicks totales', en: 'Total clicks'},
  totalDetections: { es: 'Detecciones totales', en: 'Total detections' },
  totalDistributionCounter: { es: 'Folletos totales', en: 'Total Flyers' },
  totalDuplicate: {es: 'Duplicado total', en: 'Total duplicate'},
  totalHits: { es: 'Impactos totales', en: 'Total hits' },
  totalImpressions: { es: 'Impresiones totales', en: 'Total impressions' },
  totalPoints: { es: 'Nº total de puntos', en: 'Total points amount'},
  totalReach: { es: 'Reach', en: 'Reach' },
  totalReport: { es: 'Folletos distribuidos', en: 'Delivered flyers' },
  totalRate: { es: 'Costo total', en: 'Total rate' },
  totalRateWithoutTaxes: { es: 'Costo total sin impuestos', en: 'Total rate without taxes' },
  totals: { es: 'Totales', en: 'Totals' },
  totalUsers: { es: 'Usuarios totales', en: 'Total users' },
  totalHouseholds: { es: 'Unidades familiares totales', en: 'Total households' },
  tracker: { es: 'Tracker', en: 'Tracker' },
  trackerData: { es: 'Datos del tracker', en: 'Tracker data' },
  trackers: { es: 'Trackers', en: 'Trackers' },
  trackersNumbers: { es: 'Nº de Trackers', en: 'Nº Trackers' },
  tree: { es: 'Árbol', en: 'Tree' },
  trackerAnalytics: { es: 'Analíticas tracker', en: 'Tracker analytics' },
  trackersList: { es: 'Lista trackers', en: 'Trackers list' },
  trafficNearPointOfSale: { es: 'Tráfico cerca del punto de venta', en: 'Traffic near the point of sale' },
  trp: {es: 'TRP', en: 'TRP'},
  tuesday: {es: 'Martes', en: 'Tuesday'},
  type: { es: 'Tipo', en: 'Type' },
  twoMembers: { es: 'Dos miembros', en: 'Two members' },
  unevaluableSupports: {es: 'Soportes no evaluables', en: 'Unevaluable supports'},
  uniqueUsers: { es: 'Usuarios únicos', en: 'Unique users' },
  uniqueUsersAggregated: { es: 'Usuarios únicos agregados', en: 'Uniquer users aggregated' },
  uniqueUsersImpacted: { es: 'Usuarios únicos detectados', en: 'Unique users detected' },
  universe: {es: 'Universo', en: 'Universe'},
  unmute: { es: 'Unmute', en: 'Unmute' },
  unselectAll: { es: 'Deselecciona todos', en: 'Unselect all' },
  unselectedSupports: {es:'Soportes no seleccionados' , en:'Unselected supports'},
  updatedAt: { es: 'Fecha de actualización', en: 'Updated at' },
  updateFormats: { es: 'Actualizar formatos', en: 'Update formats' },
  updateViaApi: { es: 'Actualizar via API', en: 'Update via API' },
  updateViaExcel: { es: 'Actualizar via Excel', en: 'Update via Excel' },
  uploadAtLeastOneFile: { es: 'Sube al menos 1 fichero', en: 'Upload at least 1 file' },
  uploadCircuits: { es: 'Sube un fichero con circuitos', en: 'Upload a circuits file' },
  uploadCompleteDate: {es: 'Fecha de subida completada', en: 'Upload complete date'},
  uploadDate: {es: 'Fecha de subida', en: 'Upload date'},
  uploadDsp: { es: 'Subida DSP', en: 'Upload DSP' },
  uploadDspConfirm: { es: '¿Confirma que deseas publicar esta audiencia?', en: 'Confirm you want to publish this audience?' },
  uploadImproveDigital: {es: 'Subida Improve Digital', en: 'Improve Digital upload'},
  uploadFile: { es: 'Subir fichero', en: 'Upload file' },
  uploadReporting: { es: 'Subir reporting', en: 'Upload reporting' },
  uploadYourAudience: {es: 'Sube tu propia audiencia', en:'Upload your own audience'},
  urlThirdParty: { es: 'URL de terceros', en: 'Third party URL' },
  userCategories: { es: 'Categorías del usuario', en: 'User categories' },
  userName: { es: 'Nombre de usuario', en: 'User name' },
  username: { es: 'Nombre de usuario', en: 'User name' },
  user: { es: 'Usuario', en: 'User' },
  useRangesTooltip: {es: 'Si está desmarcado, busca fecha de inicio o de fin exacta. Si está marcado, busca que comiencen a partir de la fecha de inicio hasta la fecha de fin', en: "If it's unchecked, searchs exact start and end date. Else searchs starting from start date until end date"},
  userId: { es: 'ID Usuario', en: 'User ID' },
  userDetails: { es: 'Detalles del usuario', en: 'User details' },
  users: { es: 'Usuarios', en: 'Users' },
  uuid: { es: 'UUID', en: 'UUID' },
  validate: { es: 'Validar', en: 'Validate' },
  validationWarning: { es: 'Hay campos que no cumplen la validación', en: "Some fields doesn't pass validation" },
  value: {es: 'Valor', en: 'Value'},
  values: { es: 'Valores', en: 'Values' },
  VAST: { es: 'VAST', en: 'VAST' },
  vastUrl: { es: 'URL VAST', en: 'VAST URL' },
  vendor: { es: 'Vendor', en: 'Vendor' },
  vendorAnalytics: {es: 'Análisis por vendor', en: 'Vendor analytics'},
  vendors: { es: 'Vendors', en: 'Vendors' },
  vertical: { es: 'Vertical', en: 'Vertical' },
  video: {es: 'Vídeo', en: 'Video'},
  videoAsset: { es: "Video asset", en: "Video asset" },
  videoData: {es: 'Datos del vídeo', en: 'Video data'},
  video_in_banner: { es: 'Vídeo en banner', en: 'Video in banner' },
  video_pre_roll: { es: 'Vídeo pre roll', en: 'Video pre roll' },
  view: {es: 'View', en: 'View'},
  viewability: { es: 'Visibilidad', en: 'Viewability' },
  viewBasicReport: { es: 'Ver reporte básico', en: 'View basic report' },
  viewContext: {es: 'Ver contexto', en: 'View context'},
  viewReporting: {es: 'Personalizar reporting', en:'Customize reporting'},
  visitAnalysis: { es: 'Análisis de visita', en: 'Visit analysis' },
  visitEstimation: { es: 'Estimación de visitas', en: 'Visits estimation' },
  visitIntervals: {es: 'Intervalo de visitas', en: 'Visit intervals'},
  visitorsReturn: { es: 'Retorno de visitantes', en: 'Visitors return' },
  visitorsReturnGraph: {
    daily: { es: 'Visita diaria', en: 'Daily visit' },
    dailyTooltip: {es: 'Visto al menos 5 días de 8', en: 'Seen at least 5 of 8 days'},
    firstTime: { es: 'Primera visita', en: 'First time visit' },
    occasional: { es: 'Visita ocasional', en: 'Occasional visit' },
    occasionalTooltip: { es: 'Visita que se repite, pero que no llega a categorizarse como ninguna de las anteriores', en: 'Repeated visit, but uncategorized as any previous type' },
    weekly: { es: 'Visita semanal', en: 'Weekly visit' },
    weeklyTooltip: {es: 'Se ha visto al menos 2 semanas de 4 seguidas', en:'Seen at least 2 weeks out of 4 in a row'}
  },
  visitors: { es: 'Visitantes', en: 'Visitors' },
  visitRssi: { es: 'Tiempo de RSSI', en: 'Visit RSSI' },
  visits: { es: 'Visitas', en: 'Visits' },
  visitsClicks: {es: 'Visitas (CLICKS)', en: 'Visits (CLICKS)'},
  visitsFrequency: { es: 'Frecuencia de visitas', en: 'Visits frequency' },
  visitsImpressions: {es: 'Visitas (IMPRESIONES)', en: 'Visits (IMPRESSIONS)'},
  visitsRatio: {es: 'Ratio de visitas', en: 'Visits ratio'},
  visitsUU: { es: 'Visitas usuarios únicos', en: 'Unique users visits' },
  visitTime: { es: 'Tiempo de visita', en: 'Visit time' },
  visualizeResults: { es: 'Visualizar resultados', en: 'Visualize results' },
  filterGraph: { es: 'Filtros top 10', en: 'Filters top 10' },
  vitalzone: { es: 'Zona vital', en: 'Vital zone' },
  vitalZone: { es: 'Zona vital', en: 'Vital zone' },
  warning:{
    warning: { es: 'Warning sobre el tamaño de la muestra', en: 'Warning about sample size' },
    MEDIUM: { es: 'Media', en: 'Medium' },
    HIGH: { es: 'Alta', en: 'High' },
    LOW: { es: 'Ninguna', en: 'None' },
    NONE: { es: 'Ninguna', en: 'None' },
    en: 'Warning',
    es: 'Aviso'
  },
  warningScheduleOOH: { es: 'Faltan horas para las fechas seleccionadas. Si ya es consciente, pulse confirmar.', en: 'There are missing hours for the selected dates. If you are already aware of this, press confirm.' },
  webEvents: { es: 'Eventos web', en: 'Web events' },
  websiteVisits: {es: 'Visitas a website', en: 'Website visits'},
  wednesday: { es: 'Miércoles', en: 'Wednesday' },
  week: { es: 'Semana', en: 'Week' },
  weekday: {es: 'Día de la semana', en: 'Weekday'},
  weekly: { es: 'Semanal', en: 'Weekly' },
  weeklyAudience: {es:'Audiencia semanal', en:'Weekly audience'},
  weekdaysByIndex: {
    long: {
      0: { es: 'Domingo', en: 'Sunday' },
      1: { es: 'Lunes', en: 'Monday' },
      2: { es: 'Martes', en: 'Tuesday' },
      3: { es: 'Miércoles', en: 'Wednesday' },
      4: { es: 'Jueves', en: 'Thursday' },
      5: { es: 'Viernes', en: 'Friday' },
      6: { es: 'Sábado', en: 'Saturday' },
    },
    short: {
      0: { es: 'D', en: 'Sun' },
      1: { es: 'L', en: 'Mon' },
      2: { es: 'M', en: 'Tue' },
      3: { es: 'X', en: 'Wed' },
      4: { es: 'J', en: 'Thu' },
      5: { es: 'V', en: 'Fri' },
      6: { es: 'S', en: 'Sat' },
    },
  },
  weight: {es: 'Peso', en:'Weight'},
  width: { es: 'Ancho', en: 'Width' },
  widthResolutionPx: { es: 'Resolución ancho (px)', en: 'Width resolution (px)' },
  withAudio: {es: 'Con audio', en: 'With audio'},
  workGeo: { es: 'Trabajo', en: 'Work'},
  workplace: { es: 'Trabajo', en: 'Work' },
  writeAudienceName: { es: 'Escriba el nombre de audiencia', en: 'Write audience name' },
  writeCampaignLineName: { es: 'Escriba el nombre del grupo de anuncios', en: 'Write campaign line name' },
  writeCampaignName: { es: 'Escriba el nombre de campaña', en: 'Write campaign name' },
  xandrCampaigns: { es: 'Campañas Xandr', en: 'Xandr Campaigns' },
  xandrId: { es: 'Id Xandr', en: 'Xandr Id' },
  year: { es: 'Año', en: 'Year' },
  yes: { es: 'Sí', en: 'Yes' },
  young: { es: 'Vivienda nueva', en: 'New house' },
  zipCode: {es: 'Código postal', en: 'Zip code'},
  zipCodeTop10: { es: 'Código postal (Top 10 localizaciones)', en: 'Zip code (Top 10 locations)' },
  zipCodeList: {es: 'Lista de códigos postales', en: 'Zip code list'},
  zipFile: { es: 'Fichero Zip', en: 'Zip file' },
  zoneName: { es: 'Nombre de la zona', en: 'Zone name' },
  zones: { es: 'Zonas', en: 'Zones' },
  lastUpdtZones: { es: 'Últimas zonas actualizadas', en: 'Last updated zones' },
  zonesList: { es: 'Listado de zonas', en: 'Zones list' }
}

export default dataText;
