import translations from './text';
import permissions from './permissions';

const Utils = function () {
  //utils constructor
};
const utils = new Utils();

const _requestInterceptor = () => {
  return new Promise((resolve, reject) => {
    if (utils.hasSession()) {
      const accountData = utils.getAccountData();
      const now = new Date();
      const expiration = new Date(accountData.exp * 1000);
      const ttl = (accountData.exp * 1000) - (accountData.iat * 1000);// TTL = expiration date - issued at date
      const timeLeft = expiration - now;
      if (timeLeft <= 0) {
        window.infiniaApp.logout();
        utils.showMsg('error','sessionTimedOut');
        reject('Session timed out');
      } else if (timeLeft < ttl / 2) {
        utils.post(
          `${constants.backendUrl}/login/refresh`,
          {},
          'login',
          false
        ).then(newToken => {
          utils.processToken(newToken);
          resolve();
        }).catch(err => {
          reject(err);
        });
      } else {
        resolve();
      }
    } else {
      resolve();
    }
  });
}

Utils.prototype.get = (url, params = [], intercept = true) => {
  const interceptor = intercept
    ? _requestInterceptor
    : () => Promise.resolve();

  // Return a new promise.
  return new Promise(function (resolve, reject) {
    interceptor().then(() => {
      const http = new XMLHttpRequest();

      if (params.length) {
        url += '?';
        params.forEach((param, index) => {
          if (index != 0)
            url += '&'
          if (param.key)
            url += param.key + '='
          if (param.value)
            url += param.value
        });
      }

      const token = utils.getToken();

      http.open('GET', url);
      http.setRequestHeader("Accept", "application/json;charset=UTF-8");
      http.setRequestHeader("Content-Type", "application/json");
      http.setRequestHeader("Authorization", token);
      let userLang = utils.getUserLanguage();
      http.setRequestHeader("Accept-Language", userLang);

      http.onload = () => {
        // This is called even on 404 etc
        // so check the status
        if (http.status == 200) {
          // Resolve the promise with the response text
          resolve(http.response);
        }
        else {
          // Otherwise reject with the status text
          // which will hopefully be a meaningful error
          let error = new Error(http.status);
          error.response = http.response != null && http.response.startsWith('{') ? JSON.parse(http.response) : '';
          reject(error);
        }
      };

      // Handle network errors
      http.onerror = function () {
        reject(Error("Network Error"));
      };

      http.send();
    }).catch(err => {
      reject(err);
    });
  });
}

Utils.prototype.post = (url, data, type = '', intercept = true, stringify = true, dataType = 'json') => {
  const interceptor = intercept
    ? _requestInterceptor
    : () => Promise.resolve();

  // Return a new promise.
  return new Promise(function (resolve, reject) {
    interceptor().then(() => {
      const http = new XMLHttpRequest();

      const token = utils.getToken();

      http.open('POST', url);
      http.setRequestHeader("Accept", "application/json;charset=UTF-8");
      http.setRequestHeader("Authorization", token);
      if (dataType !== 'file' && dataType !== 'files') {
        http.setRequestHeader("Content-Type", "application/json");
      }
      let userLang = utils.getUserLanguage();
      http.setRequestHeader("Accept-Language", userLang);
      // If dataType === file, we should set "Content-Type" to "multipart/form-data".
      // But that is not enough, because the form boundary would then be missing from the "Content-Type" header.
      // Therefore, in this case, we leave the "Content-Type" header empty, and the web browser fills it out for us
      // with both the "multipart/form-data" and the boundary (e.g. boundary=----WebKitFormBoundaryG2eaKWL4g7emMaW2).
      // Reference: https://stackoverflow.com/questions/36005436/the-request-was-rejected-because-no-multipart-boundary-was-found-in-springboot

      http.onload = () => {
        // This is called even on 404 etc
        // so check the status
        if (http.status == 200) {
          if (type === 'login') {
            // Get token
            let generatedToken = http.getResponseHeader('Authorization') || '';
            resolve(generatedToken);
          } else {
            // Resolve the promise with the response text
            resolve(http.response);
          }
        }
        else {
          // Otherwise reject with the status text
          // which will hopefully be a meaningful error
          let error = new Error(http.status);
          error.response = http.response != null && http.response.startsWith('{') ? JSON.parse(http.response) : '';
          reject(error);
        }
      };

      // Handle network errors
      http.onerror = function () {
        reject(Error("Network Error"));
      };

      if (dataType === 'file') {
        let formData = new FormData();
        formData.append('file', data, data.name);
        http.send(formData);
      } else if (dataType === 'files') {
        let formData = new FormData();
        data.forEach(d => {formData.append('file', d, d.name);})
        http.send(formData);
      } else {
        http.send(stringify ? JSON.stringify(data) : data);
      }
    }).catch(err => {
      reject(err);
    });
  });
}

Utils.prototype.put = (url, data, intercept = true, dataType = 'json') => {
  const interceptor = intercept
    ? _requestInterceptor
    : () => Promise.resolve();

  // Return a new promise.
  return new Promise(function (resolve, reject) {
    interceptor().then(() => {
      const http = new XMLHttpRequest();

      const token = utils.getToken();

      http.open('PUT', url);
      http.setRequestHeader("Accept", "application/json;charset=UTF-8");
      http.setRequestHeader("Authorization", token);
      if (dataType !== 'file') {
        http.setRequestHeader("Content-Type", "application/json");
      }
      let userLang = utils.getUserLanguage();
      http.setRequestHeader("Accept-Language", userLang);
      // If dataType === file, we should set "Content-Type" to "multipart/form-data".
      // But that is not enough, because the form boundary would then be missing from the "Content-Type" header.
      // Therefore, in this case, we leave the "Content-Type" header empty, and the web browser fills it out for us
      // with both the "multipart/form-data" and the boundary (e.g. boundary=----WebKitFormBoundaryG2eaKWL4g7emMaW2).
      // Reference: https://stackoverflow.com/questions/36005436/the-request-was-rejected-because-no-multipart-boundary-was-found-in-springboot

      http.onload = () => {
        // This is called even on 404 etc
        // so check the status
        if (http.status == 200) {
          // Resolve the promise with the response text
          resolve(http.response);
        }
        else {
          // Otherwise reject with the status text
          // which will hopefully be a meaningful error
          let error = new Error(http.status);
          error.response = http.response != null && http.response.startsWith('{') ? JSON.parse(http.response) : '';
          reject(error);
        }
      };

      // Handle network errors
      http.onerror = function () {
        reject(Error("Network Error"));
      };

      if (dataType === 'file') {
        let formData = new FormData();
        formData.append('file', data, data.name);
        http.send(formData);
      } else {
        http.send(JSON.stringify(data));
      }
    }).catch(err => {
      reject(err);
    });
  });
}

Utils.prototype.delete = (url, intercept = true) => {
  const interceptor = intercept
    ? _requestInterceptor
    : () => Promise.resolve();

  // Return a new promise.
  return new Promise((resolve, reject) => {
    interceptor().then(() => {
      const http = new XMLHttpRequest();

      const token = utils.getToken();

      http.open('DELETE', url);
      http.setRequestHeader("Accept", "application/json;charset=UTF-8");
      http.setRequestHeader("Authorization", token);

      http.onload = () => {
        // This is called even on 404 etc
        // so check the status
        if (http.status == 200) {
          // Resolve the promise with the response text
          resolve(http.response);
        }
        else {
          // Otherwise reject with the status text
          // which will hopefully be a meaningful error
          let error = new Error(http.status);
          error.response = http.response != null && http.response.startsWith('{') ? JSON.parse(http.response) : '';
          reject(error);
        }
      };

      // Handle network errors
      http.onerror = function () {
        reject(Error("Network Error"));
      };

      http.send();
    }).catch(err => {
      reject(err);
    });
  });
}

Utils.prototype.getToken = () => {
  let token = localStorage.getItem('userToken') || '';
  return token
}

Utils.prototype.setToken = (token) => {
  localStorage.setItem('userToken', token);
  return token;
}

Utils.prototype.refreshToken = (context, url) => {
  utils.post(`${constants.backendUrl}/login/refresh`, {}, 'login', false).then(newToken => {
    utils.processToken(newToken);
    if (context && url) {
      context.dispatchEvent(new CustomEvent('navigate-to', {
        bubbles: true, composed: true, detail: url
      }));
    }
  }).catch(() => {
    utils.showMsg('error', 'errors.genericErrorMsg');
  });
}

Utils.prototype.getAccountData = () => {
  let accountDataStr = localStorage.getItem('accountData');
  if (accountDataStr) {
    return JSON.parse(accountDataStr);
  } else {
    return null;
  }
}

Utils.prototype.getUserInfo = () => {
  const userInfoStr = localStorage.getItem('userInfo');
  if (userInfoStr) {
    return JSON.parse(userInfoStr);
  } else {
    return null;
  }
}

Utils.prototype.setAccountData = (accountData) => {
  localStorage.setItem('accountData', JSON.stringify(accountData));
}

Utils.prototype.checkPermissionsToAccess = (permissions) => {
  if (!utils.hasAnyPermissions(permissions)) {
    let app = document.body.querySelector('infinia-app')
    if (app) {
      app.shadowRoot.dispatchEvent(new CustomEvent('navigate-to', {
        bubbles: true, composed: true, detail: '/error/access'
      }));
    }
  }
}

Utils.prototype.hasAnyPermissions = (permissionList) => {
  if (utils.getAccountData() == null) {
    return false;
  }
  let aut = utils.getAccountData().aut;
  return permissionList.some(permission => aut.includes(permissions[permission]))
}

Utils.prototype.hasPermissions = (permissionList) => {
  if (utils.getAccountData() == null) {
    return false;
  }
  let aut = utils.getAccountData().aut;
  return permissionList.every(permission => aut.includes(permissions[permission]))
}

Utils.prototype.processToken = (token) => {
  utils.setToken(token);

  let tokenStr = token;

  // Slice initial Bearer
  if (token.indexOf('Bearer') === 0) {
    tokenStr = tokenStr.slice(7);
  }

  // Split and take parse base64 of second part of jwt token
  // https://jwt.io/
  const accountData = JSON.parse(atob(tokenStr.split('.')[1]));

  utils.setAccountData(accountData);
}

Utils.prototype.logout = () => {
  localStorage.removeItem('userToken');
  localStorage.removeItem('accountData');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('urlAttempt');
}

Utils.prototype.hasSession = () => {
  return localStorage.getItem('userToken') ? true : false;
}

Utils.prototype.getInit = () => {
  let token = localStorage.getItem('userToken');
  const decodedData = utils.decodeToken(token);
  let init = '';
  if (decodedData) {
    
    init = decodedData.ini

    // let decodedToken = atob(token);
    // console.log(decodedToken)
  }
  return init
}

Utils.prototype.decodeToken = (token) => {
  if (!token) return null;
  try {
    const payload = token.split(".")[1]; // Get the payload part
    const decoded = atob(payload); // Decode Base64
    return JSON.parse(decoded); // Convert to JSON
  } catch (e) {
    console.error("Invalid token", e);
    return null;
  }
}

Utils.prototype.getUserLanguage = () => {
  return window.infiniaUserLang ? window.infiniaUserLang : 'es';
}

Utils.prototype.toHTML = (string) => {
  let htmlObject = document.createElement('span');
  htmlObject.innerHTML = utils.translate(string);
  return htmlObject
}

Utils.prototype.translate = (string, index = '') => {
  const lang = utils.getUserLanguage();
  if (index != '') {
    return (translations[string] && translations[string][Number(index)] && translations[string][Number(index)][lang]) ? translations[string][Number(index)][lang] : string;
  } else if (string && typeof string === 'string') {
    const split = string.split('.');
    if (split.length > 0) {
      let keysArr = split.reverse();
      let key = keysArr.pop();
      let currentObj = translations[key];

      while (keysArr.length > 0 && currentObj) {
        key = keysArr.pop();
        currentObj = currentObj[key];
      }

      if (keysArr.length > 0 || !currentObj) {
        return string;
      } else {
        return currentObj[lang] ? currentObj[lang] : string;
      }
    } else {
      return string;
    }
  } else {
    return '';
  }
}

Utils.prototype.formatAmount = (amount, decimals = 2, thousandsIndicator = false) => {
  let lang = utils.getUserLanguage();
  let auxAmount = amount;

  let hasThousandsIndicator = false;
  if (thousandsIndicator) {
    auxAmount = amount / 1000;
    hasThousandsIndicator = auxAmount >= 1;
    auxAmount = hasThousandsIndicator ? auxAmount : amount;
  }

  let ret;
  if (lang == 'en') {
    if (decimals > 3) {
      let format = Number(auxAmount).toFixed(decimals).toString().split('.', 2);
      ret = format[0].replace(/\B(?=(\d{1,3})+(?!\d))/g, ",") + ',' + format[1];
    } else {
      ret = Number(auxAmount).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  } else {
    if (decimals > 3) {
      let format = Number(auxAmount).toFixed(decimals).toString().split('.', 2);
      ret = format[0].replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1.') + ',' + format[1];
    } else {
      ret = Number(auxAmount).toFixed(decimals).replace('.', ',').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1.');
    }
  }

  return hasThousandsIndicator ? `${ret} k` : ret;
}

Utils.prototype.getFormattedDate = (date, separator = '-', displayHours = false, lang = utils.getUserLanguage(), utc = false) => {
  if (date) {
    let d = new Date(date),
      month = '' + ((utc ? d.getUTCMonth() : d.getMonth()) + 1),
      day = '' + (utc ? d.getUTCDate() : d.getDate()),
      year = utc ? d.getUTCFullYear() : d.getFullYear(),
      hours = utc ? d.getUTCHours() : d.getHours(),
      minutes = utc ? d.getUTCMinutes() : d.getMinutes(),
      seconds = utc ? d.getUTCSeconds() : d.getSeconds();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hours < 10)
      hours = '0' + hours;
    if (minutes < 10)
      minutes = '0' + minutes;
    if (seconds < 10)
      seconds = '0' + seconds;
    if (lang == 'es') {
      return displayHours ? [day, month, year].join(separator) + ' | ' + [hours, minutes, seconds].join(':') + ' H' : [day, month, year].join(separator);
    } else {
      return displayHours ? [month, day, year].join(separator) + ' | ' + [hours, minutes, seconds].join(':') + ' H' : [month, day, year].join(separator);
    }
  } else {
    return '-'
  }
}

Utils.prototype.getFormattedDateWithoutTimeZone = (date, separator = '-', displayHours = false, lang = 'en') => {
  if (date) {
    let dateTimeSplit = date.split('T');
    let dateSplit = dateTimeSplit[0].split('-');
    let timeSplit = dateTimeSplit[1].split('.')[0].split(':');

    let year = dateSplit[0],
      month = dateSplit[1],
      day = dateSplit[2],
      hours = timeSplit[0],
      minutes = timeSplit[1];

    if (lang == 'es') {
      return displayHours ? [day, month, year].join(separator) + ' | ' + [hours, minutes].join(':') + ' H' : [day, month, year].join(separator);
    } else {
      return displayHours ? [month, day, year].join(separator) + ' | ' + [hours, minutes].join(':') + ' H' : [month, day, year].join(separator);
    }
  } else {
    return '-'
  }
}

Utils.prototype.getShortedMonth = (month) => {
  let shortedMonth = utils.translate('monthShorted', month.toString());
  return shortedMonth
}

Utils.prototype.getFormData = (formEl) => {
  if (!formEl || !formEl.elements || formEl.elements.length < 1) {
    return {};
  }

  const data = {};
  const elems = Array.from(formEl.elements);
  // Following lines are only needed because most recent versions of other browsers different from
  // Chrome do not support ElementInternals https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement
  // which causes our custom form elements to not be registered in formEl.elements
  Array.from(formEl.querySelectorAll('[name]')).forEach(el => {
    if (el.constructor.formAssociated && !elems.includes(el)) {
      elems.push(el);
    }
  });

  // Checkbox groups
  const arrElems = elems.filter(el =>
    el.name &&
    el.name.substring(el.name.length - 2, el.name.length) === '[]' &&
    el.type === 'checkbox'
  );
  arrElems.forEach(el => {
    const field = el.name.substring(0, el.name.length - 2);
    if (!data[field]) data[field] = [];
    if (el.checked) data[field].push(el.value);
  });

  // Radio groups
  const radioGroupNames = [];
  let radioNames = elems.filter(el => el.type === 'radio').map(el => el.name);
  let name, prevLength;
  while (radioNames.length) {
    name = radioNames.pop();
    prevLength = radioNames.length;
    radioNames = radioNames.filter(radioName => radioName !== name);
    // If the result of filtering other radios with the same name is that
    // some radios got removed from the array, then the name corresponds
    // to a radio group
    if (radioNames.length < prevLength) {
      radioGroupNames.push(name);
      data[name] = '';
    }
  }

  // Input groups
  const inputElems = elems.filter(el =>
    el.name &&
    el.name.substring(el.name.length - 2, el.name.length) === '[]' &&
    (el.type === 'number' || el.type === 'text')
  );
  inputElems.forEach(el => {
    const field = el.name.substring(0, el.name.length - 2);
    if (!data[field]) data[field] = [];
    if (el.value) data[field].push({ 'name': el.dataset.label, 'value': el.value });
  });

  const singleElems = elems.filter(el =>
    el.name &&
    el.name.substring(el.name.length - 2, el.name.length) !== '[]' &&
    !el.disabled &&
    el.type !== 'file' &&
    el.type !== 'reset' &&
    el.type !== 'submit' &&
    el.type !== 'button'
  );
  singleElems.forEach(el => {
    switch (el.type) {
      case 'checkbox':
        data[el.name] = el.checked;
        break;
      case 'radio':
        if (radioGroupNames.includes(el.name)) {
          if (el.checked) {
            data[el.name] = el.value;
          }
        } else {
          data[el.name] = el.checked;
        }
        break;
      default:
        data[el.name] = el.value;
    }
  });

  return data;
}

Utils.prototype.validateForm = (formEl) => {
  if (!formEl || !formEl.elements || formEl.elements.length < 1) {
    return { isValid: true };
  }

  let isValid = true;
  const fields = {};
  const elems = Array.from(formEl.elements);
  elems.forEach(field => {
    if (field.name &&
      !field.disabled &&
      field.type !== 'reset' &&
      field.type !== 'submit' &&
      field.type !== 'button') {
      switch (field.type) {
        case 'checkbox':
        case 'radio':
          if (field.required && !field.checked) {
            fields[field] = { isValid: false, error: utils.translate('form.checkedFieldRequired') };
            isValid = false;
          } else {
            fields[field] = { isValid: true };
          }
          break;
        default:
          if (field.required && !field.value) {
            fields[field] = { isValid: false, error: utils.translate('form.valueFieldRequired') };
            isValid = false;
          } else {
            fields[field] = { isValid: true };
          }
      }
    }
  });

  return { isValid, fields };
};

Utils.prototype.clearForm = (formEl) => {
  if (!formEl || !formEl.elements || formEl.elements.length < 1) {
    return { isValid: true };
  }

  const elems = Array.from(formEl.elements);
  elems.forEach(field => {
    if (field.name &&
      !field.disabled &&
      field.type !== 'reset' &&
      field.type !== 'submit' &&
      field.type !== 'button') {
      switch (field.type) {
        case 'checkbox':
        case 'radio':
          field.checked = false;
          break;
        default:
          field.value = '';
      }
    }
  });

  return;
};

Utils.prototype.isDefinedObject = (obj) => {
  return obj !== null && typeof obj === 'object' && obj instanceof Object && !Array.isArray(obj);
}

Utils.prototype.isFilledArray = (arr) => {
  return Array.isArray(arr) && arr.length > 0;
}

Utils.prototype.getColor = (color, type = 'rgba') => {
  let colorType = type == 'hex' ? 'hexcolor' : 'color';
  let colorPicker = color == 'black' ? 'dark-' + colorType : color == 'white' ? 'light-' + colorType : color == 'grey' ? 'grey-' + colorType : color == 'darkergrey' ? 'darkergrey-' + colorType : color == 'lightergrey' ? 'lightergrey-' + colorType : colorType + '-' + color
  return getComputedStyle(document.querySelector('infinia-app')).getPropertyValue('--default-' + colorPicker).replace(/["']/g, "").trim() || utils.getRandomColor();
}

Utils.prototype.getWeekDays = (mondayStart = false) => {
  let weekDays = [];
  if (mondayStart) {
    for (let i = 1; i < 7; i++) {
      weekDays.push(utils.translate(`weekdaysByIndex.long.${i}`));
    }
    weekDays.push(utils.translate('weekdaysByIndex.long.0'));
  } else {
    for (let i = 0; i < 7; i++) {
      weekDays.push(utils.translate(`weekdaysByIndex.long.${i}`));
    }
  }


  return weekDays
}

Utils.prototype.getDayOfWeek = (date, short = 'true') => {
  const weekDaysShort = utils.getUserLanguage() == 'es' && short ? ['D', 'L', 'M', 'X', 'J', 'V', 'S'] : short ? ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] : utils.getUserLanguage() == 'es' ? ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'] : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] ;
  return weekDaysShort[new Date(date).getDay()];
}

Utils.prototype.getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

Utils.prototype.getStyle = (property) => {
  return getComputedStyle(document.querySelector('infinia-app')).getPropertyValue(property);
}

Utils.prototype.setStyle = (property, value) => {
  document.querySelector('infinia-app').style.setProperty(property, value);
}

Utils.prototype.unsetStyle = (property) => {
  document.querySelector('infinia-app').style.removeProperty(property);
}

Utils.prototype.getChartColor = (color) => {
  return getComputedStyle(document.querySelector('infinia-app')).getPropertyValue('--default-chart-color-' + color).replace(/["']/g, "").trim();
}

Utils.prototype.getCoords = (country) => {
  let coords = {
    ES: { lng: -3.7025600, lat: 40.4165000 },
    MX: { lat: 19.432666, lng: -99.1324396 },
    CO: { lat: 4.707690, lng: -74.077901 },
    CL: { lat: -33.4488897, lng: -70.6692655 },
    PE: { lng: -71.75451534525718, lat: -10.58049474987368 },
    AR: { lat: -34.6157437, lng: -58.5733857 },
    CR: { lat: 9.9356124, lng: -84.1483648 },
    BR: { lat: -11.1260851, lng: -54.5275846 },
    US: { lat: 37.681914077363615, lng: -83.73276879904267 },
    PR: { lat: 18.2500000, lng: -66.5000000 },
    IN: { lat: 22.3511148, lng: 78.6677428 },
    HN: { lat: 15.199999, lng: -86.241905 },
    DO: { lat: 18.735693, lng: -70.162651 },
    UY: { lat: -32.522779, lng: -55.765835 },
    PA: { lat: 8.537981, lng: -80.782127 },
    SV: { lat: 13.794185, lng: -88.89653 },
    GT: { lat: 15.783471, lng: -90.230759 },
    EC: { lat: -1.831239, lng: -78.183406 },
    PY: { lat: -23.442503, lng: -58.443832 },
    NI: { lat: 12.865416, lng: -85.207229 },
    PT: { lat: 39.557191, lng: -7.8536599 }
  }
  return coords[country];
}

Utils.prototype.getDemographicConf = () => {
  return [
    {
      field: 'ages',
      values: ["18-25", "26-40", "41-55", "+55"]
    },
    {
      field: 'gender',
      values: ["male", "female"]
    },
    {
      field: 'incomeLevels',
      values: ["income_A", "income_B", "income_C", "income_D", "income_E"]
    }
  ]
}

Utils.prototype.getDemographicConfButtonsCheck = () => {
  return [
    {
      field: 'ages',
      values: [
        { text: "18-25", value: "18-25" },
        { text: "26-40", value: "26-40" },
        { text: "41-55", value: "41-55" },
        { text: "+55", value: "+55" },
      ]
    },
    {
      field: 'gender',
      values: [
        { text: "male", value: "male" },
        { text: "female", value: "female" },
      ]
    },
    {
      field: 'incomeLevels',
      values: [
        { text: "income_A", value: "income_A" },
        { text: "income_B", value: "income_B" },
        { text: "income_C", value: "income_C" },
        { text: "income_D", value: "income_D" },
        { text: "income_E", value: "income_E" },
      ]
    }
  ]
}

Utils.prototype.getDemographicConfButtonsCheckClusters = () => {
  return [
    {
      field: 'ages',
      values: [
        { text: "18-25", value: "18-25" },
        { text: "26-40", value: "26-40" },
        { text: "41-55", value: "41-55" },
        { text: ">55", value: ">55" },
      ]
    },
    {
      field: 'gender',
      values: [
        { text: "male", value: "male" },
        { text: "female", value: "female" },
      ]
    },
    {
      field: 'incomeLevels',
      values: [
        { text: "income_A", value: "income_A" },
        { text: "income_B", value: "income_B" },
        { text: "income_C", value: "income_C" },
        { text: "income_D", value: "income_D" },
        { text: "income_E", value: "income_E" },
      ]
    }
  ]
}

Utils.prototype.getDemographicConfButtonsCheckMeasurement = (country = 'ES') => {
  let fields = [
    {
      field: 'ages',
      values: [
        { text: "18-25", value: "v18_25" },
        { text: "26-40", value: "v26_40" },
        { text: "41-55", value: "v41_55" },
        { text: "+55", value: "m55" },
      ]
    },
    {
      field: 'gender',
      values: [
        { text: "male", value: "male" },
        { text: "female", value: "female" },
      ]
    },
    {
      field: 'incomeLevels',
      values: [
        { text: "income_A", value: "incomeLevelA" },
        { text: "income_B", value: "incomeLevelB" },
        { text: "income_C", value: "incomeLevelC" },
        { text: "income_D", value: "incomeLevelD" },
        { text: "income_E", value: "incomeLevelE" },
      ]
    }
  ];
  if (country == 'MX') {
    fields = [
      {
        field: 'ages',
        values: [
          { text: "18-25", value: "v18_25" },
          { text: "26-40", value: "v26_40" },
          { text: "41-55", value: "v41_55" },
          { text: "+55", value: "m55" },
        ]
      },
      {
        field: 'gender',
        values: [
          { text: "male", value: "male" },
          { text: "female", value: "female" },
        ]
      },
      {
        field: 'incomeLevels',
        values: [
          { text: "ABC+", value: "incomeLevelA" },
          { text: "C", value: "incomeLevelB" },
          { text: "C-", value: "incomeLevelC" },
          { text: "D+", value: "incomeLevelD" },
          { text: "DE", value: "incomeLevelE" },
        ]
      }
    ];
  } else if (country == 'CO') {
    fields = [
      {
        field: 'ages',
        values: [
          { text: "18-25", value: "v18_25" },
          { text: "26-40", value: "v26_40" },
          { text: "41-55", value: "v41_55" },
          { text: "+55", value: "m55" },
        ]
      },
      {
        field: 'gender',
        values: [
          { text: "male", value: "male" },
          { text: "female", value: "female" },
        ]
      },
      {
        field: 'incomeLevels',
        values: [
          { text: "Estrato 1-2", value: "incomeLevelD" },
          { text: "Estrato 3", value: "incomeLevelC" },
          { text: "Estrato 4", value: "incomeLevelB" },
          { text: "Estrato 5-6", value: "incomeLevelA" }
        ]
      }
    ];
  }
  return fields
}

Utils.prototype.getDemographicConfZone = () => {
  return [
    {
      field: 'houseAge',
      values: ["old", "mediumAge", "young"]
    },
    {
      field: 'houseHeight',
      values: ["vertical", "horizontal"]
    }
  ]
}

Utils.prototype.getIABsList = () => {
  return [
    { id: "IAB19_1", value: "3-D Graphics" },
    { id: "IAB5_1", value: "7-12 Education" },
    { id: "IAB7_2", value: "A.D.D." },
    { id: "IAB18_6", value: "Accessories" },
    { id: "IAB6_1", value: "Adoption" },
    { id: "IAB5_2", value: "Adult Education" },
    { id: "IAB20_1", value: "Adventure Travel" },
    { id: "IAB3_1", value: "Advertising" },
    { id: "IAB20_2", value: "Africa" },
    { id: "IAB3_2", value: "Agriculture" },
    { id: "IAB7_3", value: "AIDS/HIV" },
    { id: "IAB20_3", value: "Air Travel" },
    { id: "IAB7_4", value: "Allergies" },
    { id: "IAB7_5", value: "Alternative Medicine" },
    { id: "IAB23_1", value: "Alternative Religions" },
    { id: "IAB8_1", value: "American Cuisine" },
    { id: "IAB19_2", value: "Animation" },
    { id: "IAB19_3", value: "Antivirus Software" },
    { id: "IAB21_1", value: "Apartments" },
    { id: "IAB10_1", value: "Appliances" },
    { id: "IAB16_1", value: "Aquariums" },
    { id: "IAB21_2", value: "Architects" },
    { id: "IAB5_3", value: "Art History" },
    { id: "IAB9_1", value: "Art/Technology" },
    { id: "IAB7_6", value: "Arthritis" },
    { id: "IAB9_2", value: "Arts & Crafts" },
    { id: "IAB1", value: "Arts & Entertainment" },
    { id: "IAB7_7", value: "Asthma" },
    { id: "IAB15_1", value: "Astrology" },
    { id: "IAB23_2", value: "Atheism/Agnosticism" },
    { id: "IAB20_4", value: "Australia & New Zealand" },
    { id: "IAB7_8", value: "Autism/PDD" },
    { id: "IAB2_1", value: "Auto Parts" },
    { id: "IAB17_1", value: "Auto Racing" },
    { id: "IAB2_2", value: "Auto Repair" },
    { id: "IAB2", value: "Automotive" },
    { id: "IAB6_2", value: "Babies & Toddlers" },
    { id: "IAB8_2", value: "Barbecues & Grilling" },
    { id: "IAB17_2", value: "Baseball" },
    { id: "IAB9_3", value: "Beadwork" },
    { id: "IAB18_1", value: "Beauty" },
    { id: "IAB20_5", value: "Bed & Breakfasts" },
    { id: "IAB13_1", value: "Beginning Investing" },
    { id: "IAB17_3", value: "Bicycling" },
    { id: "IAB15_2", value: "Biology" },
    { id: "IAB3_3", value: "Biotech/Biomedical" },
    { id: "IAB7_9", value: "Bipolar Disorder" },
    { id: "IAB16_2", value: "Birds" },
    { id: "IAB9_4", value: "Birdwatching" },
    { id: "IAB9_5", value: "Board Games/Puzzles" },
    { id: "IAB18_2", value: "Body Art" },
    { id: "IAB17_4", value: "Bodybuilding" },
    { id: "IAB1_1", value: "Books & Literature" },
    { id: "IAB15_9", value: "Botany" },
    { id: "IAB17_5", value: "Boxing" },
    { id: "IAB7_10", value: "Brain Tumor" },
    { id: "IAB23_3", value: "Buddhism" },
    { id: "IAB20_6", value: "Budget Travel" },
    { id: "IAB3", value: "Business" },
    { id: "IAB3_4", value: "Business Software" },
    { id: "IAB20_7", value: "Business Travel" },
    { id: "IAB2_3", value: "Buying/Selling Cars" },
    { id: "IAB21_3", value: "Buying/Selling Homes" },
    { id: "IAB20_8", value: "By US Locale" },
    { id: "IAB19_4", value: "C/C++" },
    { id: "IAB8_3", value: "Cajun/Creole" },
    { id: "IAB19_5", value: "Cameras & Camcorders" },
    { id: "IAB20_9", value: "Camping" },
    { id: "IAB20_10", value: "Canada" },
    { id: "IAB7_11", value: "Cancer" },
    { id: "IAB9_6", value: "Candle & Soap Making" },
    { id: "IAB17_6", value: "Canoeing/Kayaking" },
    { id: "IAB2_4", value: "Car Culture" },
    { id: "IAB9_7", value: "Card Games" },
    { id: "IAB4_11", value: "Career Advice" },
    { id: "IAB4_1", value: "Career Planning" },
    { id: "IAB4", value: "Careers" },
    { id: "IAB20_11", value: "Caribbean" },
    { id: "IAB23_4", value: "Catholicism" },
    { id: "IAB16_3", value: "Cats" },
    { id: "IAB1_2", value: "Celebrity Fan/Gossip" },
    { id: "IAB19_6", value: "Cell  Phones" },
    { id: "IAB2_5", value: "Certified Pre-Owned" },
    { id: "IAB17_7", value: "Cheerleading" },
    { id: "IAB15_3", value: "Chemistry" },
    { id: "IAB9_8", value: "Chess" },
    { id: "IAB8_4", value: "Chinese Cuisine" },
    { id: "IAB7_12", value: "Cholesterol" },
    { id: "IAB23_5", value: "Christianity" },
    { id: "IAB7_13", value: "Chronic Fatigue Syndrome" },
    { id: "IAB7_14", value: "Chronic Pain" },
    { id: "IAB9_9", value: "Cigars" },
    { id: "IAB17_8", value: "Climbing" },
    { id: "IAB18_5", value: "Clothing" },
    { id: "IAB8_5", value: "Cocktails/Beer" },
    { id: "IAB8_6", value: "Coffee/Tea" },
    { id: "IAB7_15", value: "Cold & Flu" },
    { id: "IAB9_10", value: "Collecting" },
    { id: "IAB5_4", value: "Colledge Administration" },
    { id: "IAB4_2", value: "College" },
    { id: "IAB5_5", value: "College Life" },
    { id: "IAB9_11", value: "Comic Books" },
    { id: "IAB11_5", value: "Commentary" },
    { id: "IAB22_3", value: "Comparison" },
    { id: "IAB19_7", value: "Computer Certification" },
    { id: "IAB19_8", value: "Computer Networking" },
    { id: "IAB19_9", value: "Computer Peripherals" },
    { id: "IAB19_10", value: "Computer Reviews" },
    { id: "IAB3_5", value: "Construction" },
    { id: "IAB22_1", value: "Contests & Freebies" },
    { id: "IAB2_6", value: "Convertible" },
    { id: "IAB26_4", value: "Copyright Infringement" },
    { id: "IAB2_7", value: "Coupe" },
    { id: "IAB22_2", value: "Couponing" },
    { id: "IAB13_2", value: "Credit/Debt & Loans" },
    { id: "IAB17_9", value: "Cricket" },
    { id: "IAB2_8", value: "Crossover" },
    { id: "IAB20_12", value: "Cruises" },
    { id: "IAB8_7", value: "Cuisine-Specific" },
    { id: "IAB19_11", value: "Data Centers" },
    { id: "IAB19_12", value: "Databases" },
    { id: "IAB14_1", value: "Dating" },
    { id: "IAB6_3", value: "Daycare/Pre School" },
    { id: "IAB7_16", value: "Deafness" },
    { id: "IAB7_17", value: "Dental Care" },
    { id: "IAB7_18", value: "Depression" },
    { id: "IAB7_19", value: "Dermatology" },
    { id: "IAB19_13", value: "Desktop Publishing" },
    { id: "IAB19_14", value: "Desktop Video" },
    { id: "IAB8_8", value: "Desserts & Baking" },
    { id: "IAB7_20", value: "Diabetes" },
    { id: "IAB2_9", value: "Diesel" },
    { id: "IAB8_9", value: "Dining Out" },
    { id: "IAB5_6", value: "Distance Learning" },
    { id: "IAB14_2", value: "Divorce Support" },
    { id: "IAB16_4", value: "Dogs" },
    { id: "IAB9_12", value: "Drawing/Sketching" },
    { id: "IAB20_13", value: "Eastern  Europe" },
    { id: "IAB5", value: "Education" },
    { id: "IAB6_9", value: "Eldercare" },
    { id: "IAB2_10", value: "Electric Vehicle" },
    { id: "IAB19_15", value: "Email" },
    { id: "IAB22_4", value: "Engines" },
    { id: "IAB5_7", value: "English as a 2nd Language" },
    { id: "IAB10_2", value: "Entertaining" },
    { id: "IAB19_29", value: "Entertainment" },
    { id: "IAB10_3", value: "Environmental Safety" },
    { id: "IAB7_21", value: "Epilepsy" },
    { id: "IAB14_8", value: "Ethnic Specific" },
    { id: "IAB20_14", value: "Europe" },
    { id: "IAB7_1", value: "Exercise" },
    { id: "IAB25_2", value: "Extreme Graphic/Explicit Violence" },
    { id: "IAB6", value: "Family & Parenting" },
    { id: "IAB6_4", value: "Family Internet" },
    { id: "IAB18_3", value: "Fashion" },
    { id: "IAB17_10", value: "Figure Skating" },
    { id: "IAB4_3", value: "Financial  Aid" },
    { id: "IAB13_3", value: "Financial News" },
    { id: "IAB13_4", value: "Financial Planning" },
    { id: "IAB1_3", value: "Fine Art" },
    { id: "IAB17_11", value: "Fly Fishing" },
    { id: "IAB8", value: "Food & Drink" },
    { id: "IAB8_10", value: "Food Allergies" },
    { id: "IAB17_12", value: "Football" },
    { id: "IAB3_6", value: "Forestry" },
    { id: "IAB20_15", value: "France" },
    { id: "IAB9_13", value: "Freelance Writing" },
    { id: "IAB8_11", value: "French Cuisine" },
    { id: "IAB17_13", value: "Freshwater Fishing" },
    { id: "IAB17_14", value: "Game & Fish" },
    { id: "IAB10_4", value: "Gardening" },
    { id: "IAB14_3", value: "Gay Life" },
    { id: "IAB9_14", value: "Geneaology" },
    { id: "IAB15_8", value: "Geography" },
    { id: "IAB15_4", value: "Geology" },
    { id: "IAB7_22", value: "GERD/Acid Reflux" },
    { id: "IAB9_15", value: "Getting Published" },
    { id: "IAB17_15", value: "Golf" },
    { id: "IAB3_7", value: "Government" },
    { id: "IAB5_9", value: "Graduate School" },
    { id: "IAB19_16", value: "Graphics Software" },
    { id: "IAB20_16", value: "Greece" },
    { id: "IAB3_8", value: "Green Solutions" },
    { id: "IAB9_16", value: "Guitar" },
    { id: "IAB2_11", value: "Hatchback" },
    { id: "IAB25_5", value: "Hate Content" },
    { id: "IAB7_23", value: "Headaches/Migraines" },
    { id: "IAB7", value: "Health & Fitness" },
    { id: "IAB8_12", value: "Health/Lowfat Cooking" },
    { id: "IAB7_24", value: "Heart Disease" },
    { id: "IAB13_5", value: "Hedge Fund" },
    { id: "IAB7_25", value: "Herbs for Health" },
    { id: "IAB23_6", value: "Hinduism" },
    { id: "IAB9", value: "Hobbies & Interests" },
    { id: "IAB7_26", value: "Holistic Healing" },
    { id: "IAB10", value: "Home & Garden" },
    { id: "IAB9_17", value: "Home Recording" },
    { id: "IAB10_5", value: "Home Repair" },
    { id: "IAB10_6", value: "Home Theater" },
    { id: "IAB19_17", value: "Home Video/DVD" },
    { id: "IAB5_10", value: "Homeschooling" },
    { id: "IAB5_11", value: "Homework/Study Tips" },
    { id: "IAB20_17", value: "Honeymoons/Getaways" },
    { id: "IAB17_16", value: "Horse Racing" },
    { id: "IAB17_17", value: "Horses" },
    { id: "IAB20_18", value: "Hotels" },
    { id: "IAB3_9", value: "Human Resources" },
    { id: "IAB1_4", value: "Humor" },
    { id: "IAB17_18", value: "Hunting/Shooting" },
    { id: "IAB2_12", value: "Hybrid" },
    { id: "IAB7_27", value: "IBS/Crohn's Disease" },
    { id: "IAB26", value: "Illegal Content" },
    { id: "IAB26_1", value: "Illegal Content" },
    { id: "IAB11_1", value: "Immigration" },
    { id: "IAB25_7", value: "Incentivized" },
    { id: "IAB7_28", value: "Incest/Abuse Support" },
    { id: "IAB7_29", value: "Incontinence" },
    { id: "IAB7_30", value: "Infertility" },
    { id: "IAB17_19", value: "Inline  Skating" },
    { id: "IAB13_6", value: "Insurance" },
    { id: "IAB10_7", value: "Interior  Decorating" },
    { id: "IAB12_1", value: "International News" },
    { id: "IAB19_18", value: "Internet Technology" },
    { id: "IAB13_7", value: "Investing" },
    { id: "IAB9_18", value: "Investors & Patents" },
    { id: "IAB23_7", value: "Islam" },
    { id: "IAB8_13", value: "Italian Cuisine" },
    { id: "IAB20_19", value: "Italy" },
    { id: "IAB20_20", value: "Japan" },
    { id: "IAB8_14", value: "Japanese Cuisine" },
    { id: "IAB19_19", value: "Java" },
    { id: "IAB19_20", value: "Javascript" },
    { id: "IAB18_4", value: "Jewelry" },
    { id: "IAB9_19", value: "Jewelry Making" },
    { id: "IAB4_4", value: "Job Fairs" },
    { id: "IAB4_5", value: "Job Search" },
    { id: "IAB23_8", value: "Judaism" },
    { id: "IAB5_12", value: "K-6 Educators" },
    { id: "IAB10_8", value: "Landscaping" },
    { id: "IAB5_8", value: "Language Learning" },
    { id: "IAB16_5", value: "Large Animals" },
    { id: "IAB23_9", value: "Latter-Day Saints" },
    { id: "IAB11", value: "Law, Gov't & Politics" },
    { id: "IAB11_2", value: "Legal Issues" },
    { id: "IAB12_3", value: "Local News" },
    { id: "IAB3_10", value: "Logistics" },
    { id: "IAB2_13", value: "Luxury" },
    { id: "IAB19_21", value: "Mac Support" },
    { id: "IAB9_20", value: "Magic & Illusion" },
    { id: "IAB3_11", value: "Marketing" },
    { id: "IAB14_4", value: "Marriage" },
    { id: "IAB17_20", value: "Martial Arts" },
    { id: "IAB7_31", value: "Men's Health" },
    { id: "IAB3_12", value: "Metals" },
    { id: "IAB8_15", value: "Mexican Cuisine" },
    { id: "IAB20_21", value: "Mexico & Central America" },
    { id: "IAB2_14", value: "MiniVan" },
    { id: "IAB2_15", value: "Mororcycles" },
    { id: "IAB17_21", value: "Mountain Biking" },
    { id: "IAB1_5", value: "Movies" },
    { id: "IAB19_22", value: "MP3/MIDI" },
    { id: "IAB1_6", value: "Music" },
    { id: "IAB13_8", value: "Mutual Funds" },
    { id: "IAB17_22", value: "NASCAR Racing" },
    { id: "IAB12_2", value: "National News" },
    { id: "IAB20_22", value: "National Parks" },
    { id: "IAB9_21", value: "Needlework" },
    { id: "IAB19_23", value: "Net Conferencing" },
    { id: "IAB19_24", value: "Net for Beginners" },
    { id: "IAB19_25", value: "Network Security" },
    { id: "IAB12", value: "News" },
    { id: "IAB25", value: "Non-Standard Content" },
    { id: "IAB4_7", value: "Nursing" },
    { id: "IAB7_32", value: "Nutrition" },
    { id: "IAB2_16", value: "Off-Road Vehicles" },
    { id: "IAB17_23", value: "Olympics" },
    { id: "IAB13_9", value: "Options" },
    { id: "IAB7_33", value: "Orthopedics" },
    { id: "IAB23_10", value: "Paga/Wiccan" },
    { id: "IAB17_24", value: "Paintball" },
    { id: "IAB9_22", value: "Painting" },
    { id: "IAB19_26", value: "Palmtops/PDAs" },
    { id: "IAB7_34", value: "Panic/Anxiety Disorders" },
    { id: "IAB15_5", value: "Paranormal Phenomena" },
    { id: "IAB6_5", value: "Parenting - K-6 Kids" },
    { id: "IAB6_6", value: "Parenting teens" },
    { id: "IAB19_27", value: "PC Support" },
    { id: "IAB7_35", value: "Pediatrics" },
    { id: "IAB2_17", value: "Performance Vehicles" },
    { id: "IAB13", value: "Personal Finance" },
    { id: "IAB16", value: "Pets" },
    { id: "IAB9_23", value: "Photography" },
    { id: "IAB7_36", value: "Physical Therapy" },
    { id: "IAB15_6", value: "Physics" },
    { id: "IAB2_18", value: "Pickup" },
    { id: "IAB11_4", value: "Politics" },
    { id: "IAB25_3", value: "Pornography" },
    { id: "IAB19_28", value: "Portable" },
    { id: "IAB17_25", value: "Power & Motorcycles" },
    { id: "IAB6_7", value: "Pregnancy" },
    { id: "IAB5_13", value: "Private School" },
    { id: "IAB17_26", value: "Pro Basketball" },
    { id: "IAB17_27", value: "Pro Ice Hockey" },
    { id: "IAB25_4", value: "Profane Content" },
    { id: "IAB7_37", value: "Psychology/Psychiatry" },
    { id: "IAB9_24", value: "Radio" },
    { id: "IAB21", value: "Real Estate" },
    { id: "IAB23", value: "Religion & Spirituality" },
    { id: "IAB10_9", value: "Remodeling & Construction" },
    { id: "IAB16_6", value: "Reptiles" },
    { id: "IAB4_6", value: "Resume Writing/Advice" },
    { id: "IAB13_10", value: "Retirement Planning" },
    { id: "IAB2_19", value: "Road-Side Assistance" },
    { id: "IAB17_28", value: "Rodeo" },
    { id: "IAB9_25", value: "Roleplaying Games" },
    { id: "IAB17_29", value: "Rugby" },
    { id: "IAB17_30", value: "Running/Jogging" },
    { id: "IAB17_31", value: "Sailing" },
    { id: "IAB17_32", value: "Saltwater Fishing" },
    { id: "IAB4_8", value: "Scholarships" },
    { id: "IAB9_26", value: "Sci-Fi & Fantasy" },
    { id: "IAB15", value: "Science" },
    { id: "IAB9_27", value: "Scrapbooking" },
    { id: "IAB9_28", value: "Screenwriting" },
    { id: "IAB17_33", value: "Scuba Diving" },
    { id: "IAB2_20", value: "Sedan" },
    { id: "IAB14_5", value: "Senior Living" },
    { id: "IAB7_38", value: "Senor Health" },
    { id: "IAB7_39", value: "Sexuality" },
    { id: "IAB19_30", value: "Shareware/Freeware" },
    { id: "IAB22", value: "Shopping" },
    { id: "IAB17_34", value: "Skateboarding" },
    { id: "IAB17_35", value: "Skiing" },
    { id: "IAB7_40", value: "Sleep Disorders" },
    { id: "IAB7_41", value: "Smoking Cessation" },
    { id: "IAB17_36", value: "Snowboarding" },
    { id: "IAB14", value: "Society" },
    { id: "IAB20_23", value: "South America" },
    { id: "IAB15_7", value: "Space/Astronomy" },
    { id: "IAB20_24", value: "Spas" },
    { id: "IAB5_14", value: "Special Education" },
    { id: "IAB6_8", value: "Special Needs Kids" },
    { id: "IAB17", value: "Sports" },
    { id: "IAB26_3", value: "Spyware/Malware" },
    { id: "IAB9_29", value: "Stamps & Coins" },
    { id: "IAB13_11", value: "Stocks" },
    { id: "IAB5_15", value: "Studying Business" },
    { id: "IAB18", value: "Style & Fashion" },
    { id: "IAB7_42", value: "Substance Abuse" },
    { id: "IAB17_37", value: "Surfing/Bodyboarding" },
    { id: "IAB17_38", value: "Swimming" },
    { id: "IAB17_39", value: "Table Tennis/Ping-Pong" },
    { id: "IAB13_12", value: "Tax Planning" },
    { id: "IAB19", value: "Technology & Computing" },
    { id: "IAB14_6", value: "Teens" },
    { id: "IAB4_9", value: "Telecommuting" },
    { id: "IAB1_7", value: "Television" },
    { id: "IAB17_40", value: "Tennis" },
    { id: "IAB20_25", value: "Theme Parks" },
    { id: "IAB7_43", value: "Thyroid Disease" },
    { id: "IAB20", value: "Travel" },
    { id: "IAB20_26", value: "Traveling with Kids" },
    { id: "IAB2_21", value: "Trucks & Accessories" },
    { id: "IAB11_3", value: "U.S. Government Resources" },
    { id: "IAB4_10", value: "U.S. Military" },
    { id: "IAB24", value: "Uncategorized" },
    { id: "IAB25_6", value: "Under Construction" },
    { id: "IAB20_27", value: "United Kingdom" },
    { id: "IAB19_31", value: "Unix" },
    { id: "IAB25_1", value: "Unmoderated UGC" },
    { id: "IAB8_16", value: "Vegan" },
    { id: "IAB8_17", value: "Vegetarian" },
    { id: "IAB16_7", value: "Veterinary Medicine" },
    { id: "IAB9_30", value: "Video & Computer Games" },
    { id: "IAB2_22", value: "Vintage Cars" },
    { id: "IAB19_32", value: "Visual Basic" },
    { id: "IAB17_41", value: "Volleyball" },
    { id: "IAB2_23", value: "Wagon" },
    { id: "IAB17_42", value: "Walking" },
    { id: "IAB26_2", value: "Warez" },
    { id: "IAB17_43", value: "Waterski/Wakeboard" },
    { id: "IAB15_10", value: "Weather" },
    { id: "IAB19_33", value: "Web Clip Art" },
    { id: "IAB19_34", value: "Web Design/HTML" },
    { id: "IAB19_35", value: "Web Search" },
    { id: "IAB14_7", value: "Weddings" },
    { id: "IAB7_44", value: "Weight Loss" },
    { id: "IAB19_36", value: "Windows" },
    { id: "IAB8_18", value: "Wine" },
    { id: "IAB7_45", value: "Women's Health" },
    { id: "IAB9_31", value: "Woodworking" },
    { id: "IAB17_44", value: "World Soccer" }
  ];
}

Utils.prototype.getHouseholdFields = () => {
  return [
    {
      field: 'householdType',
      values: [
        { text: "adultAlone", value: "adultAlone" },
        { text: "adultCouple", value: "adultCouple" },
        { text: "seniorAlone", value: "seniorAlone" },
        { text: "seniorCouple", value: "seniorCouple" },
      ]
    },
    {
      field: 'householdSize',
      values: [
        { text: "oneMember", value: "oneMember" },
        { text: "twoMembers", value: "twoMembers" },
        { text: "threeMembers", value: "threeMembers" },
        { text: "fourMembers", value: "fourMembers" },
        { text: "fiveMembers", value: "fiveMembers" },
        { text: "moreThanSixMembers", value: "moreThanSixMembers" },
      ]
    },
    {
      field: 'incomeLevels',
      values: [
        { text: "income_A", value: "income_A" },
        { text: "income_B", value: "income_B" },
        { text: "income_C", value: "income_C" },
        { text: "income_D", value: "income_D" },
        { text: "income_E", value: "income_E" },
      ]
    }
  ]
}

Utils.prototype.getIABDataTier1 = () => {
  return [
    { id: "IAB1", value: "Arts &amp; Entertainment" },
    { id: "IAB2", value: "Automotive" },
    { id: "IAB3", value: "Business" },
    { id: "IAB4", value: "Careers" },
    { id: "IAB5", value: "Education" },
    { id: "IAB6", value: "Family &amp; Parenting" },
    { id: "IAB8", value: "Food &amp; Drink" },
    { id: "IAB7", value: "Health &amp; Fitness" },
    { id: "IAB9", value: "Hobbies &amp; Interests" },
    { id: "IAB10", value: "Home &amp; Garden" },
    { id: "IAB26", value: "Illegal Content" },
    { id: "IAB11", value: "Law, Gov't &amp; Politics" },
    { id: "IAB12", value: "News" },
    { id: "IAB25", value: "Non-Standard Content" },
    { id: "IAB13", value: "Personal Finance" },
    { id: "IAB16", value: "Pets" },
    { id: "IAB21", value: "Real Estate" },
    { id: "IAB23", value: "Religion &amp; Spirituality" },
    { id: "IAB15", value: "Science" },
    { id: "IAB22", value: "Shopping" },
    { id: "IAB14", value: "Society" },
    { id: "IAB17", value: "Sports" },
    { id: "IAB18", value: "Style &amp; Fashion" },
    { id: "IAB19", value: "Technology &amp; Computing" },
    { id: "IAB20", value: "Travel" },
    { id: "IAB24", value: "Uncategorized" }
  ];
}

Utils.prototype.getIABData = (iab) => {
  const iabsList = utils.getIABsList();
  let result = '';
  let findItem = [...iabsList].filter(item => item.id == iab);
  if (findItem && findItem.length) {
    result = findItem[0].value
  }
  return result
}

Utils.prototype.downloadExcel = (filename, content) => {
  let a = window.document.createElement('a');
  a.href = 'data:application/octet-stream;base64,' + content;
  a.download = filename;
  document.body.appendChild(a)
  a.click();
  document.body.removeChild(a)
}

Utils.prototype.downloadPdf = (filename, content) => {
  let a = window.document.createElement('a');
  a.href = 'data:application/pdf;base64,' + content;
  a.download = filename;
  document.body.appendChild(a)
  a.click();
  document.body.removeChild(a)
}

Utils.prototype.showMsg = (type = 'success', text = '') => {
  let app = document.body.querySelector('infinia-app')
  if (app) {
    app.shadowRoot.dispatchEvent(new CustomEvent('show-msg', {
      bubbles: true,
      composed: true,
      detail: {
        type: type,
        txt: text
      }
    }));
  }
}

Utils.prototype.hideMsg = () => {
  let app = document.body.querySelector('infinia-app')
  if (app) {
    app.shadowRoot.dispatchEvent(new CustomEvent('hide-msg', {
      bubbles: true,
      composed: true
    }));
  }
}

Utils.prototype.showDialog = (title, message, acceptButton, cancelButton) => {
  let app = document.body.querySelector('infinia-app')
  if (app) {
    app.shadowRoot.dispatchEvent(new CustomEvent('open-modal', {
      bubbles: true, composed: true, detail: {
        id: 'confirm-dialog',
        title: title,
        message: message,
        acceptButton: acceptButton,
        cancelButton: cancelButton
      }
    }));
  }
}

Utils.prototype.showHtmlDialog = (title, body, acceptButton, cancelButton) => {
  let app = document.body.querySelector('infinia-app')
  if (app) {
    app.shadowRoot.dispatchEvent(new CustomEvent('open-modal', {
      bubbles: true, composed: true, detail: {
        id: 'confirm-dialog',
        title: title,
        body: body,
        acceptButton: acceptButton,
        cancelButton: cancelButton
      }
    }));
  }
}


Utils.prototype.getCountries = () => {
  return [
    { id: "AR", value: "Argentina", text: "Argentina" },
    { id: "BR", value: "Brasil", text: "Brasil" },
    { id: "CL", value: "Chile", text: "Chile" },
    { id: "CO", value: "Colombia", text: "Colombia" },
    { id: "CR", value: "Costa Rica", text: "Costa Rica" },
    { id: "EC", value: "Ecuador", text: "Ecuador" },
    { id: "SV", value: "El Salvador", text: "El Salvador" },
    { id: "GT", value: "Guatemala", text: "Guatemala" },
    { id: "HN", value: "Honduras", text: "Honduras" },
    { id: "MX", value: "México", text: "México" },
    { id: "NI", value: "Nicaragua", text: "Nicaragua" },
    { id: "PA", value: "Panamá", text: "Panamá" },
    { id: "PY", value: "Paraguay", text: "Paraguay" },
    { id: "PE", value: "Perú", text: "Perú" },
    { id: "PR", value: "Puerto Rico", text: "Puerto Rico" },
    { id: "DO", value: "República Dominicana", text: "República Dominicana" },
    { id: "UY", value: "Uruguay", text: "Uruguay" },
    { id: "ES", value: "España", text: "España" },
    { id: "US", value: "USA", text: "USA" },
    { id: "PT", value: "Portugal", text: "Portugal"}
  ];
}

Utils.prototype.getUsaStates = () => {
  return [
    { id: '1', value: 'Alabama' },
    { id: '17', value: 'Alaska' },
    { id: '37', value: 'Arizona' },
    { id: '44', value: 'Arkansas' },
    { id: '29', value: 'California' },
    { id: '5', value: 'Colorado' },
    { id: '11', value: 'Connecticut' },
    { id: '22', value: 'Delaware' },
    { id: '42', value: 'District of Columbia' },
    { id: '2', value: 'Florida' },
    { id: '20', value: 'Georgia' },
    { id: '3', value: 'Hawaii' },
    { id: '51', value: 'Idaho' },
    { id: '14', value: 'Illinois' },
    { id: '36', value: 'Indiana' },
    { id: '35', value: 'Iowa' },
    { id: '12', value: 'Kansas' },
    { id: '34', value: 'Kentucky' },
    { id: '6', value: 'Louisiana' },
    { id: '24', value: 'Maine' },
    { id: '33', value: 'Maryland' },
    { id: '43', value: 'Massachusetts' },
    { id: '48', value: 'Michigan' },
    { id: '9', value: 'Minnesota' },
    { id: '7', value: 'Mississippi' },
    { id: '32', value: 'Missouri' },
    { id: '27', value: 'Montana' },
    { id: '31', value: 'Nebraska' },
    { id: '46', value: 'Nevada' },
    { id: '8', value: 'New Hampshire' },
    { id: '50', value: 'New Jersey' },
    { id: '26', value: 'New Mexico' },
    { id: '10', value: 'New York' },
    { id: '45', value: 'North Carolina' },
    { id: '13', value: 'North Dakota' },
    { id: '25', value: 'Ohio' },
    { id: '21', value: 'Oklahoma' },
    { id: '38', value: 'Oregon' },
    { id: '4', value: 'Pennsylvania' },
    { id: '41', value: 'Rhode Island' },
    { id: '40', value: 'South Carolina' },
    { id: '23', value: 'South Dakota' },
    { id: '28', value: 'Tennessee' },
    { id: '15', value: 'Texas' },
    { id: '18', value: 'Utah' },
    { id: '16', value: 'Vermont' },
    { id: '39', value: 'Virginia' },
    { id: '47', value: 'Washington' },
    { id: '19', value: 'West Virginia' },
    { id: '30', value: 'Wisconsin' },
    { id: '49', value: 'Wyoming' }
  ];
}

Utils.prototype.getLangs = () => {
  const permissions = utils.getAccountData();
  let languages = [
    { id: "EN", value: "EN", text: utils.translate('language.EN') },
    { id: "ES", value: "ES", text: utils.translate('language.ES') }
  ];
  if (permissions && permissions.aut.includes(10)) {
    languages.push({ id: "CC", value: "CC", text: utils.translate('language.CC') })
  }
  return languages
}

Utils.prototype.getUserLanguages = () => {
  let languages = [
    { id: "EN", value: "EN", text: utils.translate('language.EN') },
    { id: "ES", value: "ES", text: utils.translate('language.ES') }
  ];
  return languages
}

Utils.prototype.getFontFamily = () => {
  return [
    { id: "DINPro", value: "DINPro", text: "DINPro" },
    { id: "Arial", value: "Arial", text: "Arial" },
    { id: "TC", value: "TC", text: "TC" },
    { id: "Tiempos", value: "Tiempos", text: "Tiempos" },
    { id: "Poppins", value: "Poppins", text: "Poppins" }
  ];
}

Utils.prototype.getMaxDate = () => {
  let today = new Date();
  return new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()).toJSON().split("T")[0];
}

Utils.prototype.getMinDate = () => {
  let today = new Date();
  return new Date(today.getFullYear() - 2, today.getMonth(), today.getDate()).toJSON().split("T")[0];
}

Utils.prototype.getMarkersColors = () => {
  return ['#3552b6', '#fc433b', '#fc2267', '#b42cb3', '#1999f3', '#fdc243', '#e02e45', '#fd6b77', '#9aceff', '#f99977', '#bd4f58', '#ffb5af', '#567d89', '#ff8436', '#7e2e45', '#7d5a5d', '#005062', '#38d098', '#3d1a1d', '#4a2b65'];
}

Utils.prototype.convertToNumber = (number) => {
  let lang = utils.getUserLanguage();
  let amount = number.toString();
  if (lang == 'en') {
    amount = amount.replace(',', '');
  } else if (lang == 'es') {
    amount = amount.replace('.', '');
    amount = amount.replace(',', '.');
  }
  return parseFloat(amount)
}

Utils.prototype.checkUrlPresent = (url) => {
  if (window.location.href.indexOf(url) > -1) return true
}

Utils.prototype.getCurrencySymbol = (currency) => {
  return currency == 'EUR' ? '€' : currency == 'USD' ? '$' : '€';
}

Utils.prototype.getPolygons = (id) => {
  let pol = {
    id: id,
    polygons: []
  };
  const url = `${constants.backendUrl}/maps/polygon/cartodbId/${id}`;
  return new Promise((resolve, reject) => {
    utils.get(url).then((response) => {
      pol.polygons = JSON.parse(response).polygon;
    }, (error) => {
        reject(error)
    }).finally(() => {
      resolve(pol)
    });
  });
}

Utils.prototype.validateRequiredInputs = (DOM) => {
  let isValid = true;
  const fields = {};
  const elems = DOM.querySelectorAll('input');
/*
 some of these types might need to be added in the future
  button / checkbox / color / date / datetime-local / 
  email / file / hidden / image / month / number / password / 
  radio / range / reset / search / submit / tel / text / time / url / week
*/
  elems.forEach(field => {
    if (field.name &&
      !field.disabled) {
      switch (field.type) {
        case 'checkbox':
        case 'radio':
          if (field.required && !field.checked) {
            fields[field] = { isValid: false, error: utils.translate('form.checkedFieldRequired') };
            isValid = false;
          } else {
            fields[field] = { isValid: true };
          }
          break;
        case 'email':
          if (field.required && !field.value) {
            fields[field] = { isValid: false, error: utils.translate('form.emailFieldRequired') };
            isValid = false;
          } else {
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (field.value && !field.value.match(validRegex)) {
              fields[field] = { isValid: false, error: utils.translate('form.emailWrongFormat') };
              isValid = false;
            } else {
              fields[field] = { isValid: true };
            }
          }
          break;
        default:
          if (field.required && !field.value) {
            fields[field] = { isValid: false, error: utils.translate('form.valueFieldRequired') };
            isValid = false;
          } else {
            fields[field] = { isValid: true };
          }
      }
    }
  });

  return { isValid, fields };
}

Utils.prototype.paginateArray = (array, pageSize, pageNumber) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

Utils.prototype.getPendingAlerts = () => {
  const alertsUrl = `${constants.backendUrl}/inbox/alerts`;
  const url = `${constants.backendUrl}/inbox/unread`;
  let pending = 0;
  let alerts = [];
  utils.get(alertsUrl).then((response) => {
    alerts = JSON.parse(response)
  }).finally(() => {
    // Hides page load
    document.dispatchEvent(new CustomEvent('alerts-received', {
      bubbles: true,
      composed: true,
      detail: alerts
    }));
  });
  return new Promise((resolve, reject) => {
    utils.get(url).then((response) => {
      pending = response;
    }, (error) => {
      reject(error)
    }).finally(() => {
      resolve(pending)
    });
  });
}

Utils.prototype.markAsRead = (alerts) => {
  const url = `${constants.backendUrl}/inbox/alerts`;
  return new Promise((resolve) => {
    utils.put(url, alerts).finally(() => {
      resolve()
    });
  });
}

Utils.prototype.convertDecimalMinutes = (decimalMinutes, shorted = true) => {
  const minutes = Math.floor(decimalMinutes);
  const seconds = Math.round((decimalMinutes - minutes) * 60);
  const formatted = shorted ? seconds > 0 ? `${minutes} ${utils.translate('mins')} ${seconds} ${utils.translate('secs')}` : `${minutes} ${utils.translate('mins')}` : seconds > 0 ? `${minutes} ${utils.translate('minutes')} ${seconds} ${utils.translate('seconds')}` : `${minutes} ${utils.translate('minutes')}`;
  return formatted;
}


export default utils;
