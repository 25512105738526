
const permissions = {

  PLANNING_ACCESS: 10,

  // Areas
  AREAS_ACCESS: 100,
  AREA_EDIT: 101,
  AREA_DELETE: 106,
  AREA_POLYGONS: 103,
  AREA_OOH: 104,

  // Clusters
  CLUSTER_ACCESS: 1,
  CLUSTER_CREATE: 2,
  CLUSTER_EDIT: 3,
  CLUSTER_EXECUTE: 4,
  CLUSTER_MODIFY: 5,
  CLUSTER_ACTIVATE: 6,
  CLUSTER_ACCESS_COMPLETED: 7,
  CLUSTER_ACCESS_AREAS: 8,
  CLUSTER_DELETE: 9,
  CRISS_CROSS_LANGUAGE: 10,
  CLUSTER_COHORTS: 11,
  CLUSTER_DELETE_USERS: 12,
  CLUSTER_LOOKALIKE: 13,
  CLUSTER_CSV_COORDINATES: 14,
  CLUSTER_IMPROVE_DIGITAL: 16,

  // Digital Events
  DIGITAL_EVENTS_ACCESS: 20,
  DIGITAL_EVENTS_EDIT: 21,

  // Extrapolation
  EXTRAPOLATION_ACCESS: 25,
  SUPPORT_EXTRAPOLATION_ACCESS: 26,
  SUPPORT_EXTRAPOLATION_EDIT: 31,
  SUPPORT_EXTRAPOLATION_DOWNLOAD_PDF: 28,
  EXTRAPOLATION_IAB: 29,
  EXTRAPOLATION_DISTANCE: 200,
  EXTRAPOLATION_ADD_CIRCUIT: 201,
  DAILY_EXTRAPOLATION_ACCESS: 23,

  // Affinity Analysis
  AFFINITY_ANALYSIS: 27,
  AFFINITY_ANALYSIS_EDIT: 15,

  // Digital campaigns

  DIGITAL_CAMPAIGN_ACCESS: 110,
  DIGITAL_CAMPAIGN_EDIT: 111,
  DIGITAL_CAMPAIGN_REPORTING: 112,
  DIGITAL_CAMPAIGN_PUBLISH: 113,
  DIGITAL_CAMPAIGN_BRAND_EQUITY: 114,
  DIGITAL_CAMPAIGN_BRIEFING: 115,
  DIGITAL_CAMPAIGN_REPORTING_ADVERTISING: 116,
  DIGITAL_CAMPAIGN_XANDER: 117,
  ATTRIBUTION_MODEL_ACCESS: 118,
  ATTRIBUTION_MODEL_EDIT: 119,

  // Inhouse campaigns

  INHOUSE_CAMPAIGN_ACCESS: 81,

  // Distribution Campaign

  DISTRIBUTION_CAMPAIGN_ACCESS: 120,
  DISTRIBUTION_CAMPAIGN_REPORTING: 121,
  DISTRIBUTION_CAMPAIGN_ATTRIBUTION: 122,

  // Outdoor Digital Campaign
  OUTDOOR_DIGITAL_CAMPAIGN_ACCESS: 140,
  OUTDOOR_DIGITAL_CAMPAIGN_EDIT: 141,
  OUTDOOR_DIGITAL_CAMPAIGN_SEND: 142,
  OUTDOOR_DIGITAL_CAMPAIGN_REPORTING: 143,
  OUTDOOR_DIGITAL_SUBCAMPAIGN_ACCESS: 144,
  OUTDOOR_DIGITAL_SUBCAMPAIGN_EDIT: 145,
  OUTDOOR_DIGITAL_CAMPAIGN_BRIEFING: 146,

  // Indoor
  INDOOR_ACCESS: 40,
  BENCHMARK_ACCESS: 41,
  RETAILER_EDIT: 52,
  SECTION_EDIT: 62,
  TRACKER_EDIT: 72,
  TRACKER_CONFIGURATION_EDIT: 74,
  INDOOR_ANALYTICS_EDIT: 75,

  // Publishers
  PUBLISHERS_ACCESS: 90,

  // Management
  MANAGEMENT_ACCESS: 30,
  AGENCIES_ACCESS_ALL: 33,
  CLUSTER_ACCESS_ALL: 36,
  SCHEDULED_REPORTING_ACCESS_ALL: 39,
  AGENCIES_EDIT: 37,
  ADVERTISERS_EDIT: 38,
  VENDORS_EDIT: 105,
  CREATIVES_MANAGEMENT_ACCESS: 32,
  PROMOTION_MANAGEMENT_ACCESS: 34,
  PROMOTION_CREATIVES_ACCESS: 35,
  CUSTOM_REPORTING_ACCESS: 42,

  // Funnel
  FUNNEL_ACCESS: 80,

  // Outdoor
  NIA_ACCESS: 130,
  NIA_CREATE_CAMPAIGN: 131,
  NIA_CREATE_SUPPORTS: 132,
  NIA_MODIFY: 133,
  CAMPAIGN_OUTDOOR_EDIT: 134,
  CAMPAIGN_OUTDOOR_ACCESS: 135,
  CAMPAIGN_OUTDOOR_DELETE: 136,
  CAMPAIGN_OUTDOOR_BRIEFING: 137,
  CAMPAIGN_OUTDOOR_MODIFY: 138,
  CAMPAIGN_OUTDOOR_ACCESS_COMPLETED: 139,
  CAMPAIGN_DOOH_API: 150,

  //Mnext
  MNEXT_FILTERS: 160,
  MNEXT_PLANNER_ACCESS: 161,

  // household
  CTV_ANALYTICS_ACCESS: 91,

  // Dashboard
  CAMPAIGNS_DASHBOARD_ACCESS: 82,
  CAMPAIGNS_DASHBOARD_EDIT: 83,
  AUDIENCES_DASHBOARD_ACCESS: 84,
  AUDIENCES_DASHBOARD_EDIT: 85,

  // Dts
  DTS_ACCESS: 86
}

export default permissions;
