// Use relative paths for peer dependencies
import { LitElement, html, css, unsafeCSS } from 'lit-element';
import commonStyles from '../../styles/css/styles.css';

import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/iron-icons.js';

import '../custom-icons-component/custom-icons-component'

// Extend the LitElement base class
class DropdownMenuComponent extends LitElement {

  static get styles() {
    return [
      css`${unsafeCSS(commonStyles)}`,
      css`
        :host{
          display: block;
          --box_background: var(--dropdown-menu-component_background, var(--default-color-2));
          color: var(--dropdown-menu-component_color, var(--default-light-color));
        }
        .dropdown-menu{
          position:var(--dropdown-menu_position, relative);
        }
        .dropdown-menu:hover{
          cursor:var(--dropdown-menu-hover_cursor, pointer);
        }
        .right .dropdown-list{
          transform: var(--right-dropdown-list_transform, translateY(-50%));
          margin: var(--right-dropdown-list_margin, 0 0 0 20px);
          --box_overflow: visible;
        }
        .right .box.dropdown-list::before{
          content: var(--right-box-dropdown-list-before_content, '');
          display: var(--right-box-dropdown-list-before_display, block);
          width: var(--right-box-dropdown-list-before_width, 20px);
          height: var(--right-box-dropdown-list-before_height, 20px);
          background: var(--box-dropdown-list_background, var(--default-color-2));
          position: var(--right-box-dropdown-list-before_position, absolute);
          top: var(--right-box-dropdown-list-before_top, 50%);
          left: var(--right-box-dropdown-list-before_left, 0);
          transform: var(--right-box-dropdown-list-before_transform, rotate(45deg) translateX(-50%));
        }
        .left .dropdown-list{
          transform: var(--left-dropdown-list_transform, translateY(-50%));
          margin: var(--left-dropdown-list_margin, 0 20px 0 0);
          --box_overflow: visible;
        }
        .left .box.dropdown-list::before{
          content: var(--left-box-dropdown-list-before_content, '');
          display: var(--left-box-dropdown-list-before_display, block);
          width: var(--left-box-dropdown-list-before_width, 20px);
          height: var(--left-box-dropdown-list-before_height, 20px);
          background: var(--box-dropdown-list_background, var(--default-color-2));
          position: var(--left-box-dropdown-list-before_position, absolute);
          top: var(--left-box-dropdown-list-before_top, 50%);
          right: var(--right-box-dropdown-list-before_right, 0);
          transform: var(--left-box-dropdown-list-before_transform, rotate(45deg) translateY(-50%));
        }
        .box.dropdown-list{
          position: var(--box-dropdown-list_position, fixed);
          display: var(--box-dropdown-list_display, none);
          z-index: var(--box-dropdown-list_z-index, 2);
          --iron-icon-fill-color: var(--dropdown-list-icons_fill, currentColor);
          --box_padding: var(--box-dropdown-list_padding, 16px);
          border-radius: var(--box-dropdown-list_border-radius, 10px);
        }
        .box.dropdown-list.opened{
          display: var(--box-dropdown-list-opened_display, block);
        }
        .dropdown-list-title{
          white-space: var(--dropdown-list-item_white-space, nowrap);
          list-style: var(--dropdown-list-item_list-style, none);
          padding: var(--dropdown-list-item_padding, 0 0 10px);
          text-transform: var(--dropdown-list-title_text-transform, uppercase);
        }
        .dropdown-list-item{
          white-space: var(--dropdown-list-item_white-space, nowrap);
          list-style: var(--dropdown-list-item_list-style, none);
          font-weight: var(--dropdown-list-item_font-weight, 500);
          padding: var(--dropdown-list-item_padding, 0 0 10px);
        }
        .dropdown-list-item:hover{
          color: var(--dropdown-list-item-hover_color, var(--default-color-1));
        }
        .dropdown-list-item:last-of-type{
          padding: var(--dropdown-list-item-last_padding, 0);
        }
        .dropdown-action-icon{
          margin: var(--dropdown-action-icon_margin, 0 1ex 0 0);
        }
        .dropdown-sublist{
          display:none;
        }
        .display-sublevel .dropdown-sublist{
          display: block;
        }
      `
    ]
  }

  static get properties() {
    return {
      icon: { type: String },
      orientation: { type: String },
      actions: { type: Array },
      isOpened: { type: Boolean },
      title: {type: String}
    }
  }

  constructor() {
    super();
    this.orientation = '';
    this.isOpened = false;
    this.actions = [];
    this.title = '';
  }

  connectedCallback() {
    super.connectedCallback();

    this._clickOutsideListenerBound = this._clickOutsideListener.bind(this);
    window.addEventListener('click', this._clickOutsideListenerBound);

    // Add scroll listeners to reposition options on scroll of any of its parent elements
    this._scrollListenerBound = this._scrollListener.bind(this);
    this._parents = [];
    const getParent = node => {
      if (node.nodeType === 11) {
        // If parent node is a document fragment, then its parent is its host,
        // not its parent node
        return node.host;
      } else if (node.assignedSlot) {
        // If parent is inside a <slot>, take the parent of the slot
        return node.assignedSlot.parentNode;
      } else {
        return node.parentNode;
      }
    }
    let parent = getParent(this);
    while (parent) {
      if (parent.nodeType === 1) {
        // If parent node is an element, add scroll listener
        parent.addEventListener('scroll', this._scrollListenerBound, true);
        this._parents.push(parent);
      }
      parent = getParent(parent);
    }
  }

  disconnectedCallback() {
    window.removeEventListener('click', this._clickOutsideListenerBound);
    this._parents.forEach(parent => {
      parent.removeEventListener('scroll', this._scrollListenerBound);
    });

    super.disconnectedCallback();
  }

  firstUpdated() {
    this._content = this.shadowRoot.querySelector('.dropdown-list');
  }

  render(){

    return html`
      <div class="dropdown-menu ${this.orientation}">
        ${this.icon ? html`
          <iron-icon
            icon="${this.icon}"
            @click=${this.toggleActions}>
          </iron-icon>
        `: html``}
        <slot name="icon" @click=${this.toggleActions}></slot>
        ${this.actions && this.actions.length ? html`
          <ul class="dropdown-list box ${this.isOpened ? 'opened' : ''}">
            ${this.title != '' ? html`
              <li class='dropdown-list-title'>${utils.translate(this.title)}</li>
            `:``}
            ${this.actions.map((action) => html`
              <li
                class="dropdown-list-item box-flex align items-centered"
                data-action="${action.value}"
                @click=${this.actionClicked}
              >
                ${action.icon ? html`
                  <span class="dropdown-action-icon" @click=${this.actionClicked}>
                    <iron-icon icon="${action.icon}"></iron-icon>
                  </span> 
                `: html``}
                <span class="dropdown-action-text" @click=${this.actionClicked}>${utils.translate(action.text)}</span>
                ${action.subactions && action.subactions.length ? html`
                  <ul class='dropdown-sublist'>
                  ${action.subactions.map((subaction) => html`
                    <li
                      class="dropdown-sublist-item"
                      data-action="${subaction.value}"
                      @click=${this.actionClicked}
                    >
                      <span class="dropdown-action-text" @click=${this.actionClicked}>${utils.translate(subaction.text)}</span>
                    </li>
                  `)}
                  </ul>
                `: html``}
              </li>
            `)}
          </ul>
        `: html ``}
      </div>
    `;
  }

  toggleActions() {
    if (this.isOpened) {
      this.closeActions();
    } else {
      this.isOpened = true;
    }
    this._repositionContent();
    let event = new CustomEvent('dropdown-toggle', {
      bubbles: true,
      composed: true,
      detail: this
    });
    this.dispatchEvent(event);
  }

  closeActions() {
    if (this.isOpened) {
      this.isOpened = false;
      const sublevels = this.shadowRoot.querySelectorAll('.display-sublevel');
      if (sublevels && sublevels.length) {
        sublevels.forEach(elem => {
          elem.classList.remove('display-sublevel');
        })
      }
    }
  }

  actionClicked(e) {
    e.stopPropagation();
    let elem = e.target.closest('li');
    if (elem.dataset.action == 'toggleSublevel') {
      let sublevels = this.shadowRoot.querySelectorAll('.display-sublevel');
      if (sublevels && sublevels.length) {
        sublevels.forEach(elem => {
          elem.classList.remove('display-sublevel');
        })
      }
      elem.classList.toggle('display-sublevel')
    } else {
      let event = new CustomEvent('dropdown-action-clicked', {
        bubbles: true,
        composed: true,
        detail: elem.dataset.action
      });
      this.dispatchEvent(event);
    }
  }

  _repositionContent() {
    requestAnimationFrame(() => {
      const rect = this.getBoundingClientRect();
      this._content.style.top = `${rect.top + rect.height / 2}px`;
      if (this.orientation === 'right') {
        this._content.style.left = `${rect.x + rect.width}px`;
        this._content.style.right = '';
      } else {
        this._content.style.left = '';
        this._content.style.right = `${window.innerWidth - rect.x}px`;
      }
    });
  }

  _clickOutsideListener(e) {
    const path = e.composedPath();
    let element = path.pop();
    let notInside = true;
    while (element && notInside) {
      notInside = (element !== this);
      element = path.pop();
    }
    
    if (notInside) {
      this.closeActions();
    }
  }

  _scrollListener(e) {
    if (!this.isOpened) {
      return;
    }
    
    // TODO Check scroll provoked position change for content element
  
    // Check scroll origin element doesn't come from within
    const path = e.composedPath();
    let element = path.pop();
    let notInsideContentElement = true;
    while (element && notInsideContentElement) {
      notInsideContentElement = (element !== this._content);
      element = path.pop();
    }
    
    if (notInsideContentElement) {
      this._repositionContent();
    }
  }
}
// Register the new element with the browser.
customElements.define('dropdown-menu-component', DropdownMenuComponent);
