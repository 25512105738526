import { LitElement, html, css, unsafeCSS } from 'lit-element';
import commonStyles from '../../styles/css/styles.css';

class CardComponent extends LitElement {
  static get styles() {
    return [
      css`${unsafeCSS(commonStyles)}`,
      css`
        :host {
          display: var(--card_display, block);
          margin: var(--card_margin, 0 0 20px);
        }
        :host(.maximized){
          min-height: var(--card-component-maximized-card-content_min-height,50vh);
          --card-content_max-height: var(--card-component-maximized-card-content_max-height, 90vh);
        }
        :host(.maximized):before{
          content: var(--card-component-maximized-before_content,'');
          display: var(--card-component-maximized-before_display, block);
          position: var(--card-component-maximized-before_position, fixed);
          top: var(--card-component-maximized-before_top,-100%);
          left: var(--card-component-maximized-before_left,-10px);
          width: var(--card-component-maximized-before_width,100vw);
          height: var(--card-component-maximized-before_height,200vh);
          background: var(--card-component-maximized-before_background, var(--default-dark-color));
          opacity: var(--card-component-maximized-before_opacity,.75);
        }
        :host(.maximized) .collapse,
        :host(.maximized) .remove {
          --card-header-action_display: none;
        }
        :host(.modal){
          display: var(--card-component-modal_display, none);
          --card-header-action-fullscreen_background: var(--modal-card-header-action-fullscreen_background, var(--default-color-error));
        }
        :host(.untitled){
          --card-header_border-bottom: var(--card-component-untitled-card-header_border-bottom, none);
        }
        :host(.maximized.modal){
          display: var(--card-component-maximized-modal_display, block);
        }
        :host(.maximized.modal.auto-fit){
          --card_width: var(--card-maximized-auto-fit_width, fit-content);
          --card_margin: var(--card-maximized-auto-fit_margin, 0 auto);
        }
        :host(.print-mode){
          --card-header-action_display:  var(--card-component-print-mode-card-header-action_display, none);
          --card-content-padding: var(--card-component-print-mode-card-content-padding, 40px);
          --card-component-print-btn_display: var(--card-component-print-mode-print-btn_display,block);
          --progress-bar_margin: 0;
        }
        :host(.no-printable){
          --card-component-print-btn_display: var(--card-component-no-printable-print-mode-print-btn_display,none);
          --card-component-not-for-print_display: var(--card-component-no-printable-card-component-not-for-print_display, block);
        }
        .card {
          border: var(--card_border, 1px solid var(--default-grey-color));
          border-radius: var(--card_border-radius, 10px);
          box-shadow: var(--card_box-shadow, 5px 5px 5px var(--default-darkergrey-color));
          background: var(--card_background, #FFFFFF);
          height: var(--card_height, auto);
          max-height: var(--card_max-height, 100%);
          overflow: var(--card_overflow, visible);
          display: var(--card_display, flex);
          flex-direction: var(--card_flex-direction, column);
          position:var(--card_position, relative);
          width: var(--card_width, 100%);
          margin: var(--card_margin, 0);
        }
        .card-header {
          padding: var(--card-header_padding, 10px);
          border-bottom: var(--card-header_border-bottom, 1px solid var(--default-grey-color));
          display: var(--card-header_display, flex);
          align-items: var(--card-header_align-items, center);
          justify-content: var(--card-header_justify-content, space-between);
          font-weight: var(--card-header_font-weight, 500);
          flex-grow: var(--card-header_flex-grow, 0);
        }
        .minified{
          --card-header_border-bottom: var(--card-header-minified_border-bottom, none);
        }
        .card-content {
          padding: var(--card-content-padding, 10px);
          max-height: var(--card-content_max-height, none);
          overflow: var(--card-content_overflow, auto);
          height: var(--card-content_height, auto);
          display: var(--card-content_display, block);
          align-items: var(--card-content_align-items, flex-start);
          justify-content: var(--card-content_justify-content, flex-start);
          flex-direction: var(--card-content_flex-direction, row);
        }
        .card-header-action {
          width: var(--card-header-action_width, 15px);
          height: var(--card-header-action_height, 15px);
          border-radius: var(--card-header-action_border-radius, 50%);
          background: var(--card-header-action_background, transparent);
          display: var(--card-header-action_display, inline-block);
        }
        .card-header-action:hover,
        .card-header.clickable:hover {
          cursor: var(--card-header-action-hover_cursor, pointer)
        }
        .collapse {
          --card-header-action_background: var(--card-header-action-collapse_background, var(--default-color-success));
        }
        .remove,
        .close {
          --card-header-action_background: var(--card-header-action-remove_background, var(--default-color-error));
        }
        .fullscreen {
          --card-header-action_background: var(--card-header-action-fullscreen_background, var(--default-color-progress));
        }
        .print-btn{
          position: var(--card-component-print-btn_position, absolute);
          right:10px;
          bottom: 10px;
          width: 30px;
          height: 30px;
          display: var(--card-component-print-btn_display, none);
        }
        .print-btn:hover{
          cursor: var(--card-component-print-btn-hover_cursor, pointer);
        }
        .not-for-print{
          display: var(--card-component-not-for-print_display, none);
        }
        @media print{
          :host{
            --card-header-action_display: var(--card-header-action-print_display, none);
          }
          :host(.print-mode){
            --card-component-print-btn_display: var(--card-component-print-mode-card-component-print-btn_display, none);
            --card-component-not-for-print_display: var(--card-component-print-mode-not-for-print_display, none);
            --card-content-padding: var(--card-component-print-mode-card-content_print-padding, 10px);
          }
        }
      `
    ];
  }

  static get properties() {
    return {
      header: { type: String },
      actions: { type: Array },
      showContent: { type: Boolean },
      isModal: { type: Boolean },
      modalId: { type: String },
      displayActions: { type: Boolean },
      toggleHeader: {type: Boolean}
    }
  }

  constructor() {
    super();
    this.header = '';
    this.actions = ['collapse', 'fullscreen', 'remove'];
    this.showContent = true;
    this.modalId = '';
    this.isModal = false;
    this.displayActions = false;
    this.toggleHeader = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this._openModalListener = (e) => {
      if (this.isModal && e.detail.id == this.modalId) {
        this.classList.add('maximized');
      }
    };
    window.addEventListener('open-modal', this._openModalListener);

    this._closeModalListener = (e) => {
      if (e.detail == this.modalId) {
        this.classList.remove('maximized');
      }
    };
    window.addEventListener('close-modal', this._closeModalListener);

    this._closeAllModalsListener = () => {
      this.classList.remove('maximized');
    }
    window.addEventListener('close-all-modals', this._closeAllModalsListener);

    this._scrollTopCard = (e) => {
      if (e.detail == this.modalId) {
        let container = this.shadowRoot.querySelector('.card-content');
        if (container)
          container.scrollTop = 0;
      }
    };
    window.addEventListener('scroll-top-card', this._scrollTopCard);
  }

  disconnectedCallback() {
    window.removeEventListener('open-modal', this._openModalListener);
    window.removeEventListener('close-modal', this._closeModalListener);
    window.removeEventListener('close-all-modals', this._closeAllModalsListener);
    window.removeEventListener('scroll-top-card', this._scrollTopCard);
    super.disconnectedCallback();
  }

  shouldUpdate(changedProperties) {
    if (changedProperties.has('isModal') && this.isModal) {
      this.classList.add('modal');
    }
    return true
  }

  render() {
    return html`
      <div class='card'>
        <div class='card-header ${this.showContent ? '' : 'minified'} ${this.actions && this.actions.includes('collapse') && this.toggleHeader ? 'clickable':''}' data-action='collapse' @click=${this.actions && this.actions.includes('collapse') && this.toggleHeader? this.handleAction : false}>
          <div class='card-header-title'>
            ${this.header ? html`
              ${utils.translate(this.header)}
            ` : html``}
          </div>
          ${this.actions && this.actions.length && this.displayActions ? html`
            <div class='card-header-actions'>
              ${this.actions.map(action => html`
                <span 
                  class='card-header-action ${action}'
                  data-action='${action}'
                  @click=${this.handleAction}></span>
              `)}
            </div>
          `: html``}
        </div>
        ${this.showContent ? html`
          <div class='card-content'>
            <slot></slot>
          </div>
        `: html``}
        <span class='print-btn for-print' @click='${this.togglePrint}' >
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
            <style type="text/css">
              .st0{fill:#333333;}
              .st3{fill:#00D200;}
              .st1{fill:#CC331D;}
              .st2{fill:none;stroke:#FFFFFF;}
            </style>
            <g>
              <path class="st0" d="M11.78,2.99h0.98c0.29-1.04,1.24-1.8,2.37-1.8s2.08,0.77,2.37,1.8h0.98c0.37,0,0.67,0.31,0.67,0.68v1.35
                c0,0.86-0.71,1.58-1.57,1.58h-4.92c-0.86,0-1.57-0.71-1.57-1.58V3.67C11.11,3.3,11.41,2.99,11.78,2.99"/>
              <path class="st0" d="M9.99,3.89h0.22v1.13c0,1.37,1.1,2.48,2.46,2.48h4.92c1.36,0,2.46-1.11,2.46-2.48V3.89h0.22
                c1.36,0,2.46,1.11,2.46,2.48v11.26c0,1.37-1.1,2.48-2.46,2.48h-5.9c0.45-0.96,0.72-2.03,0.74-3.15h4.26c0.37,0,0.67-0.31,0.67-0.68
                c0-0.37-0.3-0.68-0.67-0.68h-4.33c-0.08-0.55-0.21-1.07-0.39-1.58h4.73c0.37,0,0.67-0.31,0.67-0.68c0-0.37-0.3-0.68-0.67-0.68
                h-5.37c-0.35-0.58-0.76-1.11-1.24-1.58h6.61c0.37,0,0.67-0.31,0.67-0.68c0-0.37-0.3-0.68-0.67-0.68h-8.45
                C9.91,9.21,8.75,8.88,7.52,8.86V6.37C7.52,5,8.63,3.89,9.99,3.89"/>
              <path class="st3" d="M7.3,10.65c3.33,0,6.04,2.73,6.04,6.08s-2.71,6.08-6.04,6.08c-3.33,0-6.04-2.73-6.04-6.08
                S3.97,10.65,7.3,10.65"/>
              <line class="st2" x1="3.09" y1="16.69" x2="11.09" y2="16.69"/>
              <line class="st2" x1="7.09" y1="12.69" x2="7.09" y2="20.69"/>
            </g>
          </svg>
        </span>
        <span class='print-btn not-for-print' @click='${this.togglePrint}' >
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
          <g>
            <path class="st0" d="M11.78,2.99h0.98c0.29-1.04,1.24-1.8,2.37-1.8s2.08,0.77,2.37,1.8h0.98c0.37,0,0.67,0.31,0.67,0.68v1.35
              c0,0.86-0.71,1.58-1.57,1.58h-4.92c-0.86,0-1.57-0.71-1.57-1.58V3.67C11.11,3.3,11.41,2.99,11.78,2.99"/>
            <path class="st0" d="M9.99,3.89h0.22v1.13c0,1.37,1.1,2.48,2.46,2.48h4.92c1.36,0,2.46-1.11,2.46-2.48V3.89h0.22
              c1.36,0,2.46,1.11,2.46,2.48v11.26c0,1.37-1.1,2.48-2.46,2.48h-5.9c0.45-0.96,0.72-2.03,0.74-3.15h4.26c0.37,0,0.67-0.31,0.67-0.68
              c0-0.37-0.3-0.68-0.67-0.68h-4.33c-0.08-0.55-0.21-1.07-0.39-1.58h4.73c0.37,0,0.67-0.31,0.67-0.68c0-0.37-0.3-0.68-0.67-0.68
              h-5.37c-0.35-0.58-0.76-1.11-1.24-1.58h6.61c0.37,0,0.67-0.31,0.67-0.68c0-0.37-0.3-0.68-0.67-0.68h-8.45
              C9.91,9.21,8.75,8.88,7.52,8.86V6.37C7.52,5,8.63,3.89,9.99,3.89"/>
            <path class="st1" d="M7.3,10.65c3.33,0,6.04,2.73,6.04,6.08s-2.71,6.08-6.04,6.08c-3.33,0-6.04-2.73-6.04-6.08
              S3.97,10.65,7.3,10.65"/>
            <line class="st2" x1="3.29" y1="16.56" x2="11.29" y2="16.56"/>
          </g>
        </svg>
        </span>
      </div>
    `;
  }

  handleAction(e) {
    const action = e.target.dataset.action;
    if (action == 'collapse') {
      this.showContent = !this.showContent;
      let removeCard = new CustomEvent('toggle-card', {
        bubbles: true,
        composed: true,
        detail: this.showContent
      });
      this.dispatchEvent(removeCard);
    }
    if (action == 'remove') {
      let removeCard = new CustomEvent('remove-card', {
        bubbles: true,
        composed: true,
        detail: this
      });
      this.dispatchEvent(removeCard);
    }
    if (action == 'fullscreen') {
      let fullscreenCard = new CustomEvent('fullscreen-card', {
        bubbles: true,
        composed: true,
        detail: this
      });
      this.dispatchEvent(fullscreenCard);
    }
    if (action == 'close') {
      this.classList.toggle('maximized');
      this.dispatchEvent(new CustomEvent('close-card', {
        bubbles: true,
        composed: true,
        detail: this
      }));
    }
  }

  togglePrint() {
    let printToggle = new CustomEvent('toggle-card-print', {
      bubbles: true,
      composed: true,
      detail: this
    });
    this.dispatchEvent(printToggle);
  }
}
// Register the new element with the browser.
customElements.define('card-component', CardComponent);