import { LitElement, html, css, unsafeCSS } from 'lit-element';
import commonStyles from '../../styles/css/styles.css';

import '../card-component/card-component.js'

class DialogComponent extends LitElement {
  static get styles() {
    return [
      css`${unsafeCSS(commonStyles)}`,
      css`
        :host{
        }
        .dialog-message {
          padding: 10px;
          min-width: 400px;
        }
      `
    ]
  }

  static get properties() {
    return {
      title: { type: String },
      message: { type: Array },
      body: { type: String },
      cancelButton: { type: Object },
      acceptButton: { type: Object },
    }
  }

  clear() {
    this.title = '';
    this.message = '';
    this.body = '';
    this.cancelButton = function() {};
    this.acceptButton = function() {};
  }

  constructor() {
    super();
    this.clear();
  }

  connectedCallback() {
    super.connectedCallback();

    this._openModalListener = (e) => {
      if (e.detail.id == 'confirm-dialog') {
        this.title = e.detail.title;
        this.message = e.detail.message;
        this.body = e.detail.body;
        this.cancelButton = e.detail.cancelButton;
        this.acceptButton = e.detail.acceptButton;
      }
    };
    window.addEventListener('open-modal', this._openModalListener);
  }

  disconnectedCallback() {
    this.removeEventListener('open-modal', this._openModalListener);
    super.disconnectedCallback();
  }

  render() {
    return html`
      <card-component id='confirm-dialog' class='auto-fit' modalId='confirm-dialog' header='${utils.translate(this.title)}' .isModal='${true}' .displayActions='${true}'>
        <div class='flex separator-line dialog-message'>
          ${this.message ? html`
            <label class="bold">${utils.translate(this.message)}</label>
          `: html `
            ${new DOMParser().parseFromString(this.body, 'text/html').body}
          `}
        </div>
        <div class='flex flex-end'>
          ${this.acceptButton ? html`
            <button @click=${this._cancel}>${utils.translate('cancel')}</button>
            <button @click=${this._accept}>${utils.translate('accept')}</button>
            `: html`
            <button @click=${this._accept}>${utils.translate('accept')}</button>
            `}

        </div>
      </card-component>
    `;
  }

  _cancel() {
    if (this.cancelButton) {
      this.cancelButton();
    }
    this._closeModal();
  }

  _accept() {
    if (this.acceptButton) {
      this.acceptButton();
    }
    this._closeModal();
  }

  _closeModal() {
    this.dispatchEvent(new CustomEvent('close-modal', {
      bubbles: true,
      composed: true,
      detail: 'confirm-dialog'
    }));
    this.clear();
  }
}

customElements.define('dialog-component', DialogComponent);