import { LitElement, html, css, unsafeCSS } from 'lit-element';
import commonStyles from '../../styles/css/styles.css';

import '../../components/card-component/card-component.js';

class ChangePasswordComponent extends LitElement {
  static get styles() {
    return [
      css`${unsafeCSS(commonStyles)}`,
      css`
        #changePassword input {
          min-width: 500px;
        }
      `
    ];
  }

  static get properties() {
    return {
    }
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  render() {
    return html`
      <card-component class='auto-fit' id='changePassword' modalId='changePassword' header='changePassword' .isModal='${true}' .displayActions='${true}'>
        <form @submit=${this._changePassword}>
          <label>${utils.translate('oldPassword')}</label>
          <input name="oldPassword" class="separation" type="password" required>
          <label>${utils.translate('newPassword')}</label>
          <input name="newPassword" class="separation" type="password" required>
          <label>${utils.translate('repeatNewPassword')}</label>
          <input name="repeatNewPassword" class="separation" type="password" required>
          <div class="flex flex-center">
            <button>${utils.translate('accept')}</button>
          </div>
        </form>
      </card-component>
    `;
  }

  _changePassword(e) {
    e.preventDefault();
    const formEl = e.currentTarget;
    const formData = utils.getFormData(formEl);

    if (formData.newPassword != formData.repeatNewPassword) {
      utils.showMsg('error', 'errors.repeatPassword');
    } else {
      let changePasswordUrl = `${constants.backendUrl}/users/${utils.getAccountData().uid}/password`;
      utils.put(changePasswordUrl, formData).then(async () => {
        window.infiniaApp.logout();
        utils.showMsg('success', 'successSaving');
        this.dispatchEvent(new CustomEvent('close-modal', {
          bubbles: true, composed: true, detail: 'changePassword'
        }));
      }).catch(() => {
        utils.showMsg('error', 'errors.passwordChange');
      })
    }
  }

}
// Register the new element with the browser.
customElements.define('change-password-component', ChangePasswordComponent);