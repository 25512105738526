// Use relative paths for peer dependencies
import { LitElement, html, css, unsafeCSS } from 'lit-element';
import commonStyles from '../../styles/css/styles.css';

// Extend the LitElement base class
class LoaderComponent extends LitElement {

  static get styles() {
    return [
      css`${unsafeCSS(commonStyles)}`,
      css`
        :host {
          width:100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .lds-ring {
          display: inline-block;
          position: relative;
          width: 80px;
          height: 80px;
        }
        .lds-ring div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 64px;
          height: 64px;
          margin: 8px;
          border: 8px solid var(--default-color-3);
          border-radius: 50%;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: var(--default-color-3) transparent transparent transparent;
        }
        .lds-ring div:nth-child(1) {
          animation-delay: -0.45s;
        }
        .lds-ring div:nth-child(2) {
          animation-delay: -0.3s;
        }
        .lds-ring div:nth-child(3) {
          animation-delay: -0.15s;
        }
        @keyframes lds-ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `
    ]
  }

  static get properties() {
    return {
      msg: {type: String}
    }
  }

  constructor() {
    super();
    this.msg = '';
  }

  render(){

    return html`
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      ${this.msg != '' ? html`
        <div class='medium text-center'>
          <span>${utils.translate(this.msg)}</span>
        </div>
      `: ``}
    `;
  }

}
// Register the new element with the browser.
customElements.define('loader-component', LoaderComponent);
