// Use relative paths for peer dependencies
import '@webcomponents/webcomponentsjs/webcomponents-loader.js';
import { LitElement, html, css, unsafeCSS } from 'lit-element';
import page from 'page';
import { startRouting, linkRequest, resetLink } from './routes.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/iron-icons.js';
import './components/dropdown-menu-component/dropdown-menu-component.js';
import './components/loader-component/loader-component.js';
import './components/custom-icons-component/custom-icons-component.js';
import './components/change-password-component/change-password-component.js';
import './components/dialog-component/dialog-component.js';
import 'element-internals-polyfill';
import commonStyles from './styles/css/styles.css';
import utils from './utils/utils';

window.utils = utils;

let customCSS = document.createElement('style');
let platformCSS = document.createElement('style');

// Extend the LitElement base class
class InfiniaApp extends LitElement {
  static get styles() {
    return [
      css`${unsafeCSS(commonStyles)}`,
      css`
        :host{
          display: var(--app_display, block);
          overflow: var(--app-overflow, hidden);
          background: var(--custom-app_background, var(--app_background, #FFF));
          background-size: var(--app_background-size, cover);
          font-family: var(--font-family,'Poppins',sans-serif);
          box-sizing: border-box;
          position: relative;
          --default-app-menu_background: linear-gradient(var(--default-dark-color), var(--default-color-2));
          --original-app-menu_background: radial-gradient(var(--original-dark-color), var(--original-color-2));
        }
        .app{
          width: var(--app_width, 100vw);
          height: var(--app_height ,100vh);
          display: var(--app_display, block);
          position: relative;
          background-image: var(--app_background, radial-gradient(farthest-corner at 100% 0, var(--default-color-3) 0%, var(--default-color-1) 40%));
        }
        .app::before{
          content: '';
          background: url('./src/img/logo_bg.png') no-repeat top right;
          width: 100vw;
          height: 100vh;
          position:absolute;
          display: var(--app-background-before, block);
        }
        .app-menu{
          display: var(--app-menu_display, flex);
          flex-direction: var(--app-menu_flex-direction, column);
          background: var(--app-menu_background, var(--default-app-menu_background));
          color: var(--app-menu_color, var(--default-light-color));
          width: var(--app-menu_width, 14.0625vw);
          font-size: var(--app-menu_font-size, 14px);
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: width ease-in-out 400ms;
          --iron-icon-fill-color: var(--app-menu-iron-icon-fill-color, var(--default-color-4));
          z-index: 4;
        }
        .app-menu[hidden]{
          display: var(--app-menu-hidden_display, none);
        }
        .app-menu.min{
          width: 60px;
        }
        .app-menu:hover button.empty.app-menu__toggle {
          display: block;
        }
        button.empty.app-menu__toggle {
          display: none;
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translate(50%, -50%);
          border-radius: 50%;
          background: var(--default-color-2);
          box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
          z-index: 3;
        }
        button.empty.app-menu__toggle iron-icon {
          fill: white;
          width: 34px;
          height: 34px;
        }
        .app-menu-logo{
          padding: 10px;
          height: 50px;
          text-align: center;
          display: flex;
          align-items:center;
        }
        .app-menu-logo img{
          width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
        .min .app-menu-logo img{
          width: 40px;
        }
        .app-menu-user {
          padding: var(--app-menu-user_padding, 10px);
          background: var(--app-menu-user_background, var(--default-dark-color));
          display: var(--app-menu-user_display, flex);
          align-items: var(--app-menu-user_align-items, center)
        }
        .app-menu-user-info {
          white-space: var(--app-menu-user-info_white-space, nowrap);
          margin: var(--app-menu-user-info_margin, 0 0 0 10px);
          font-weight: var(--app-menu-user-info_font-weight, bold);
          overflow: var(--app-menu-user-info_overflow, hidden);
          text-overflow: var(--app-menu-user-info_text-overflow, ellipsis);
        }
        .app-menu button {
          color: var(--app-menu-button_color, var(--default-light-color));
          border: var(--app-menu-button_border, none);
          background: var(--app-menu-button_background, transparent);
          display:block;
        }
        .app-container{
          position: relative;
          margin: var(--app-container_margin, 0 0 0 14.0625vw);
          display: var(--app-container_display, flex);
          flex-direction: var(--app-container_flex-direction, column);
          height: var(--app-container_height, 100%);
          overflow: var(--app-container_overflow, hidden);
          transition: margin ease-in-out 400ms;
          background: var(--app-container_background, var(--default-lightergrey-color));
        }
        .app-container.max{
          margin: var(--app-container-max_margin,0 0 0 60px);
        }
        .app-container.no-background{
          --app-container_background: var(--app-container-no-background_background, transparent);
        }
        header{
          padding: var(--header_padding, 0);
          border-bottom: 1px solid var(--default-darkergrey-color);
          display: var(--header_display, flex);
          justify-content: space-between;
          align-items:center;
        }
        header .logout:hover {
          cursor: pointer;
          font-weight: 500;
        }
        header .breadcrumbs {
          padding: 20px;
        }
        header .header-icons div{
          padding: 20px 0;
        }
        header .header-icons div:hover{
          padding: 15px 0 15px;
        }
        header
        header[hidden] {
          display: none;
        }
        .breadcrumbs {
          display: flex;
        }
        .breadcrumb:not(:last-child) {
          padding-right: 8px;
          margin-right: 8px;
          border-right: 1px solid var(--default-dark-color);
        }
        .breadcrumb a {
          color: var(--default-dark-color);
          text-decoration: none;
        }
        .breadcrumb a:hover {
          text-decoration: underline;
        }
        .page-container {
          padding: 20px;
          flex-basis:100%;
          width: 100%;
          overflow: var(--page-container_overflow,auto);
          box-sizing: border-box;
        }
        .with-bg{
          background: var(--with-bg_background, var(--app-container_background, var(--default-lightergrey-color, #f5fbfd)));
        }
        .page-loading{
          position: fixed;
          top:0;
          left:0;
          width:100vw;
          height: 100vh;
          overflow: hidden;
          background: rgba(255, 255, 255, .8);
          display: flex;
          align-items: center;
          z-index:3;
        }
        .page-loading.hidden{
          display: none;
        }
        .app-menu-subitems{
          font-weight: var(--app-menu-subitems_font-weight, 200);
          background: var(--app-menu-subitems_background, linear-gradient(rgba(0,0,0,0), var(--default-dark-color)));
        }
        .app-menu-subitems .app-menu-subitems{
          background: var(--app-menu-subitems-app-menu-subitems_background, transparent);
        }
        .min .app-menu-subitems{
          display: var(--min-app-menu-subitems_display, none);
        }
        .app-menu-item-header-icon{
          margin: 0 5px 0 0;
        }
        .sublevel-1{
          position: relative;
          z-index: 1;
          margin: 0 0 0 40px;
          padding: 0;
          border-left: var(--app-menu-sublevel-highlight_border, 1px solid var(--app-menu-sublevel-highlight_color, var(--default-golden-color, #ffbe2d)));
        }
        .sublevel-2{
          padding: 0;
        }
        .app-menu li{
          padding: 10px 10px 10px 15px;
          list-style: none;
        }
        .collapsed .app-menu-subitems{
          display: none;
        }
         .app-menu .expand-icon{
          transition: transform ease-in-out 200ms;
        }
        .collapsed .expand-icon{
          transform: rotateX(180deg);
        }
        .app-menu-item-header{
          display: flex;
          padding: 10px;
          justify-content: space-between;
          background-color: var(--app-menu-item-header_background, var(--default-dark-color));
          font-weight: var(--app-menu-item-header_font-weight, bold);
        }
        .min .app-menu-item-header{
          justify-content: space-around;
        }
        .collapsed .app-menu-item-header{
          --app-menu-item-header_background: var(--collapsed-app-menu-item-header_background, transparent);
        }
        .collapsed .app-menu-item-header:hover{
          --app-menu-item-header_background: var(--collapsed-app-menu-item-header-hover_background, var(--default-dark-color));
        }
        .app-menu .sublevel-2 li{
          padding: 10px 10px 10px 20px;
          list-style: none;
        }
        .app-menu .sublevel-2 li:first-of-type{
          padding: 20px 10px 10px 20px;
        }
        .app-menu li span{
          display: block;
        }

        .sublevel-1 li{
          position: relative;
        }
        .sublevel-1 li:before{
          content: '';
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          background: var(--app-menu-sublevel-highlight_color, var(--default-golden-color,#A89A65));
          border-radius: 50%;
          top: 15px;
          left: -6px;
        }
        .sublevel-1 li:hover:before{
          width: 12px;
          height: 12px;
          top: 14px;
          left: -7px;
        }
        .sublevel-1 .parent-item.collapsed {
          background: transparent;
        }
        .sublevel-1 .parent-item:after,
        .sublevel-1 .active-link:after{
          content: '';
          display: block;
          position: absolute;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          top: 12px;
          left: -9px;
          border: var(--app-menu-sublevel-highlight_border, 1px solid var(--app-menu-sublevel-highlight_color, var(--default-golden-color,#ffbe2d)));
        }
        .sublevel-1 .parent-item.collapsed:after,
        .sublevel-2 .active-link:after{
          display: none;
        }
        .sublevel-2 li:before{
          display:none;
        }
        .sublevel-2 .active-link,
        .sublevel-2 li:hover{
          font-weight: bold;
          color: var(--app-menu-sublevel-highlight_color, var(--default-golden-color, #A89A65));
        }
        .app-menu-actions{
          overflow-y: var(--app-menu-actions_overflow-y, auto);
          flex-basis: var(--app-menu-actions_flex-basis, 100%);
        }
        .app-menu-actions:hover{
          cursor: pointer;
        }
        #videoBg {
          position: fixed;
          right: 0;
          bottom: 0;
          min-width: 100%;
          min-height: 100%;
          z-index: -2;
        }
        .app-bg {
          position: fixed;
          right: 0;
          bottom: 0;
          min-width: 100%;
          min-height: 100%;
          z-index: -1;
          background: var(--app_background, transparent);
        }
        .app-messages {
          box-sizing: border-box;
          position:fixed;
          background: var(--default-color-2);
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          padding: 10px;
          --iron-icon-width: 48px;
          --iron-icon-height: 48px;
          z-index:10;
          align-items: center;
          color: var(--default-light-color);
        }
        .app-messages.full-msg{
          left: 0;
          width: 100%;
        }
        .app-messages.app-messages-error{
          background: var(--default-color-error-bg);
        }
        .app-messages.app-messages-warning{
          align-items: start;
          background: var(--default-color-6);
        }
        .app-messages.max{
          width: var(--app-messages-max_width, 100%);
        }
        .app-message-intro {
          color: var(--default-light-color);
          font-size: var(--huge-text_font-size, 24px);
          font-weight: var(--huge-text_font-weight, 500);
          text-transform: uppercase;
          display: flex;
          align-items: center;
        }
        .app-message-intro span {
          vertical-align: middle;
        }
        .app-message {
          font-weight: 500;
        }
        .app-message ul{
          margin-block-start: 0;
          margin-block-end: 0;
          margin: 0 20px 0 0;
          max-height: 15vh;
          overflow: auto;
        }
        .app-message-intro iron-icon{
          margin: 0 10px 0 0;
        }
        .app-messages button {
          font-family: var(--button_font-family, var(--font-family));
          font-size: var(--button_font-size, var(--font-size));
          border-radius: var(--button_border-radius, 20px);
          color: var(--button_color, var(--default-light-color));
          padding: var(--button_padding, 10px 30px);
          background: var(--button_background, var(--default-dark-color));
          border: var(--button_border, none);
          text-transform: uppercase;
        }
        .app-messages button:hover{
          color: var(--button-hover_color, var(--default-dark-color));
          background: var(--button-hover_background, var(--default-light-color));
          cursor: var(--button-hover_cursor,pointer);
          box-shadow:var(--button-hover_box-shadow,inset 0px 0px 0px 1px var(--default-dark-color));
        }
        .app-messages button:focus{
          outline: var(--button-focus_outline, none);
          box-shadow: var(--button-focus_box-shadow,0 0 5px var(--default-dark-color));
        }
        .print-mode {
          --app-menu_display: var(--print-mode-app-menu_display, none);
          --header_display: var(--print-mode-header_display, none);
          --app-container_margin: 0;
          --app-container-max_margin: 0;
          --campaigns-dooh-reporting-graph-legend_display: var(--print-mode-campaigns-dooh-reporting-graph-legend_display, none);
        }
        .h3 {
          font-size: 1.2em;
        }
        header .flex {
          align-items: center;
        }
        header .flex div,
        header .flex div:last-of-type{
          margin: 0 10px 0 10px;
        }
        header .flex div:hover,
        header .flex div:last-of-type:hover{
          --iron-icon-width: 34px;
          --iron-icon-height: 34px;
          color: var(--default-color-2, currentColor);
          margin: 0 5px 0 5px;
        }
        @media (max-width: 990px){
          .app-menu{
            width: 60px;
          }
          .app-menu .app-menu-logo img{
            width: 40px;
          }
          .app-menu:hover button.empty.app-menu__toggle {
            display: none;
          }
          .app-messages{
            width: calc(100% - 60px);
            display: block;
            text-align: center;
          }
          .app-messages.full-msg{
            width: 100%;
          }
          header {
            display: block;
          }
        }
        @media print{
          .with-bg{
            background: var(--with-bg_print-background, var(--default-light-color, #FFF));
          }
          .logout{
            display:none;
          }
          .app-messages{
            display: none;
          }
        }
        .logo-user {
          max-width: 40px;
        }
      `
    ]
  }

  static get properties() {
    return {
      page: { type: String },
      customStyles: { type: Boolean },
      platformStyles: { type: Boolean },
      isLoading: { type: Boolean },
      navList: { type: Array },
      userInfo: { type: Object },
      menuMax: { type: Boolean },
      logoUser: { type: String },
      logoBig: { type: String },
      logoSmall: { type: String },
      displayMsg: { type: Boolean },
      msgTxt: { type: String },
      printMode: { type: Boolean },
      breadcrumbs: { type: Array },
      displayVideoBg: { type: Boolean },
      basicPrint: { type: Boolean },
      loaderMsg: { type: String },
      lastPage: { type: String },
      lastUser: { type: String },
      initPage: { type: String },
      isLogged: { type: Boolean },
      pendingAlerts: { type: Number },
      alerts: { type: Array }
    };
  }

  /**
   * Instance of the element is created/upgraded. Use: initializing state,
   * set up event listeners, create shadow dom.
   * @constructor
   */
  constructor() {
    super();
    this.displayVideoBg = false;
    this.basicPrint = false;
    this.pendingAlerts = 0;
    this.alerts = [];
    // Implement singleton pattern
    if (!InfiniaApp.instance) {

      let userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        this.userInfo = JSON.parse(userInfo);
      } else {
        this.userInfo = {
          name: '',
          surname: ''
        };
      }
      this.navList = [];
      this.menuMax = false;
      this.logoUser = this.userInfo.logo && this.userInfo.logo != '' ? `${this.userInfo.logo}` : './src/img/Emblem-Stencil-(White).png';
      this.logoBig = this.userInfo.entityLogo && this.userInfo.entityLogo != '' ? `${this.userInfo.entityLogo}` : './src/img/Horizantal-Full-Colour-Logo-(White).png';
      this.logoSmall = this.userInfo.entityLogoSml && this.userInfo.entityLogoSml != '' ? `${this.userInfo.entityLogoSml}` : './src/img/Emblem-Full-Colour.png';
      this.platformStyles = false;
      this.customStyles = false;
      this._loadedPages = {};
      this.breadcrumbs = [];
      InfiniaApp.instance = this;
      this.addEventListener('navigate-to', (e) => {
        if('/' + e.target.page == e.detail){
          return
        } else {
          page(e.detail);
        }
      });
      this.addEventListener('start-page-load', (e) => {
        if (e.detail) {
          this.loaderMsg = utils.translate(e.detail);
        }
        this.isLoading = true;
      });
      this.addEventListener('end-page-load', () => {
        this.loaderMsg = '';
        this.isLoading = false;
      });
      this.addEventListener('scroll-to', (e) => {
        this.scrollTo(e.detail);
      });
      this.addEventListener('update-breadcrumbs', (e) => {
        this.breadcrumbs = e.detail;
      });
      window.addEventListener('resize', () => {
        const screenSize = window.innerWidth;
        if (screenSize <= 990) {
          this.menuMax = false;
        } else {
          this.menuMax = true;
        }
        this.requestUpdate();
      });
      this.msgTxt = '';
      this.displayMsg = false;
      this.printMode = false;
      this.loaderMsg = '';
      this.lastPage = '';
      this.lastUser = '';
      this.initPage = '/clusters';
      this.isLogged = false;
    }
    return InfiniaApp.instance;
  }

  connectedCallback() {
    super.connectedCallback();

    this._bindedOnLogin = this.onLogin.bind(this);
    document.addEventListener('infinia-login', this._bindedOnLogin);

    this._dropdownActionClickedListener = (e) => {
      if (e.detail.includes('go-to')) {
        let url = e.detail.replace('go-to', '');
        this.shadowRoot.querySelectorAll('dropdown-menu-component').forEach(elem => {
          elem.closeActions();
        });
        let event = new CustomEvent('navigate-to', {
          bubbles: true,
          composed: true,
          detail: url
        });
        this.dispatchEvent(event);
      } else if (e.detail === 'logout') {
        this.logout();
      } else if (e.detail === 'myUser') {
        let event = new CustomEvent('navigate-to', {
          bubbles: true,
          composed: true,
          detail: '/myUser'
        });
        this.dispatchEvent(event);
      } else if (e.detail === 'downloadsManager') {
        let event = new CustomEvent('navigate-to', {
          bubbles: true,
          composed: true,
          detail: '/downloadsManager'
        });
        this.dispatchEvent(event);
      }
    };
    this.addEventListener('dropdown-action-clicked', this._dropdownActionClickedListener);

    this._fullscreenCardListener = (e) => {
      e.detail.classList.toggle('maximized');
      let chart = e.detail.querySelector('chart-component');
      let funnel = e.detail.querySelector('funnel-component');
      if (chart) {
        chart.resize();
      }
      if (funnel) {
        funnel.resize();
      }
    }
    this.addEventListener('fullscreen-card', this._fullscreenCardListener);

    this._removeCardListener = (e) => {
      let blockToggled = e.detail.closest('.print-block');
      let section = e.detail.closest('.print-section');
      e.detail.remove();
      if (blockToggled) {
        let printables = blockToggled.querySelectorAll('card-component');
        if (!printables || !printables.length) {
          blockToggled.remove();
        }
      }
      if (section) {
        let sectionBlocks = section.querySelectorAll('.print-block:not(.no-printable)');
        if (sectionBlocks && sectionBlocks.length) {
          sectionBlocks.forEach((block, index) => {
            block.classList.remove('page-block');
            if ((index + 1) % 4 == 0) block.classList.add('page-block')
          })
        }
      }

    }
    this.addEventListener('remove-card', this._removeCardListener);

    if (window.utils.hasSession()) {
      this.isLoading = true;
      this.getMenu().finally(() => this.isLoading = false);
      window.infiniaUserLang = this.userInfo.language ? this.userInfo.language.toLowerCase() : 'es';
    }
    this._showMsgListener = (e) => {
      this.msgTxt = e.detail.txt;
      this.msgType = e.detail.type;
      this.displayMsg = true;
    };
    this.addEventListener('show-msg', this._showMsgListener);

    this._hideMsgListener = () => {
      this.closeMsg();
    };
    this.addEventListener('hide-msg', this._hideMsgListener);

    this._viewReportingListener = () => {
      this.printMode = !this.printMode;
    };
    this.addEventListener('view-reporting', this._viewReportingListener);

    this._togglePrintCardListener = (e) => {
      e.detail.classList.toggle('no-printable');
      let blockToggled = e.detail.closest('.print-block');
      if (blockToggled) {
        let printables = blockToggled.querySelectorAll('card-component');
        let notPrintables = blockToggled.querySelectorAll('.no-printable');
        if (printables.length == notPrintables.length) {
          blockToggled.classList.add('no-printable');
          if (blockToggled.classList.contains('page-block')) {
            blockToggled.classList.remove('page-block');
          }
        } else {
          blockToggled.classList.remove('no-printable');
        }
      }
      let section = e.detail.closest('.print-section');
      if (section) {
        let allBlocks = section.querySelectorAll('.print-block');
        if (allBlocks && allBlocks.length) {
          allBlocks.forEach((block) => {
            block.classList.remove('page-block');
          })
        }
        let sectionBlocks = section.querySelectorAll('.print-block:not(.no-printable)');
        if (sectionBlocks && sectionBlocks.length) {
          sectionBlocks.forEach((block, index) => {
            if (index != 0 && index % 3 == 0) block.classList.add('page-block')
          })
        }
      }

    }
    this.addEventListener('toggle-card-print', this._togglePrintCardListener);
    this.addEventListener('request-platform-styles', this.loadPlatformStyles);
    this.addEventListener('export-to-pdf', this.exportToPdf);
    window.addEventListener('afterprint', this.resetToScreen.bind(this));
    document.addEventListener('alerts-received', this.displayAlerts.bind(this));
    document.addEventListener('pending-updated', this.updatePending.bind(this));
  }

  disconnectedCallback() {
    document.removeEventListener('infinia-login', this._bindedOnLogin);
    this.removeEventListener('dropdown-action-clicked', this._dropdownActionClickedListener);
    this.removeEventListener('fullscreen-card', this._fullscreenCardListener);
    this.removeEventListener('remove-card', this._removeCardListener);
    this.removeEventListener('show-msg', this._showMsgListener);
    this.removeEventListener('view-reporting', this._viewReportingListener);
    this.removeEventListener('toggle-card-print', this._togglePrintCardListener);
    this.removeEventListener('hide-msg', this._hideMsgListener);
    this.removeEventListener('request-platform-styles', this.loadPlatformStyles);
    this.removeEventListener('export-to-pdf', this.exportToPdf);
    window.removeEventListener('afterprint', this.resetToScreen.bind(this));
    document.removeEventListener('alerts-received', this.displayAlerts.bind(this));
    document.removeEventListener('pending-updated', this.updatePending.bind(this));

    super.disconnectedCallback();
  }

  firstUpdated() {
    if (this.userInfo && this.userInfo.changePassword) {
      utils.logout();
      location.reload();
    } else if (window.utils.hasSession()) {
      this.loadStyles().finally(() => {
        // Start listening to route changes to decide what to render
        startRouting(window.infiniaApp);
      });
    } else {
      // Start listening to route changes to decide what to render
      startRouting(window.infiniaApp);
    }
  }

  onLogin() {
    this.loadStyles().finally(() => {
      let user = utils.getAccountData();
      this.getUserInfo(user.uid).finally(() => {
        if (this.userInfo.changePassword) {
          this.dispatchEvent(new CustomEvent('open-modal', {
            bubbles: true, composed: true, detail: { id: 'changePassword' },
          }));
        } else {
          this.getMenu().finally(() => {
            this.isLogged = true;
            const link = linkRequest();
            if (this.lastUser != '' && user.uid == this.lastUser && this.lastPage != '' || link != '') {
              if (link != '') {
                page(link);
              } else {
                page(this.lastPage);
              }
            } else if (this.initPage) {
              page(this.initPage);
            } else {
              page('/clusters');
            }
            // TODO: Change to Dashboard when finish: page('/dashboard');
          })
        }
      });
    });
  }

  loadStyles() {
    return new Promise((resolve, reject) => {
      const stylesConfurl = `${constants.backendUrl}/users/configuration/css`;

      window.utils.get(stylesConfurl)
        .then(async css => {
          customCSS.innerHTML = css;
          this.customStyles = true;
          resolve();
        }).catch(err => {
          reject(err)
        });
    });

  }

  getMenu() {
    return new Promise((resolve, reject) => {

      let menurl = `${constants.backendUrl}/users/configuration/menu`;
      window.utils.get(menurl).then(async menu => {
        const response = JSON.parse(menu);
        this.navList = response.options || [];
        this.initPage = response.initPage || '/clusters';
        resolve();
      }).catch(err => {
        reject(err);
      });
    });

  }

  getUserInfo(id) {
    return new Promise((resolve, reject) => {
      const url = `${constants.backendUrl}/users/${id}/me`;
      // const url = 'http://95.120.116.64:8765/api/infinia/v1/users/' + id;

      window.utils.get(url)
        .then(async userData => {
          localStorage.setItem('userInfo', userData);
          this.userInfo = JSON.parse(userData);
          if (this.userInfo.entityLogo && this.userInfo.entityLogo != '') {
            this.logoBig = `${this.userInfo.entityLogo}`
          } else {
            this.logoBig = './src/img/Horizantal-Full-Colour-Logo-(White).png'
          }
          if (this.userInfo.entityLogoSml && this.userInfo.entityLogoSml != '') {
            this.logoSmall = `${this.userInfo.entityLogoSml}`
          } else {
            this.logoSmall = this.userInfo.entityLogo && this.userInfo.entityLogo != '' ? `${this.userInfo.entityLogo}` : './src/img/Emblem-Full-Colour.png'
          }
          if (this.userInfo.logo && this.userInfo.logo != '') {
            this.logoUser = `${this.userInfo.logo}`
          } else {
            this.logoUser = './src/img/Emblem-Stencil-(White).png'
          }
          window.infiniaUserLang = this.userInfo.language ? this.userInfo.language.toLowerCase() : 'es';
          resolve();
        }).catch(err => {
          reject(err);
        });
    });
  }

  loadPlatformStyles() {
    // if (window.location.hostname == 'localhost' || window.location.hostname.includes('infiniamobile.com')) {
    //   this.displayVideoBg = true;
    // }
    if (!window.location.hostname == 'localhost' || window.location.hostname.includes('mnext')) {
      return new Promise((resolve, reject) => {
        let stylesConfurl = `${constants.backendUrl}/login/configuration/css?url=${window.location.hostname}`;
  
        window.utils.get(stylesConfurl)
          .then(async css => {
            let color2 = css.match('--color-2:' + '(.*?)' + ';');
            if (color2) {
              let formated = color2[1].replace(' ', '');
              utils.setStyle('--platform-color-2', formated);
            }
            let color3 = css.match('--color-3:' + '(.*?)' + ';');
            if (color3) {
              let formated = color3[1].replace(' ', '');
              utils.setStyle('--platform-color-3', formated);
            }
            let color4 = css.match('--color-4:' + '(.*?)' + ';');
            if (color4) {
              let formated = color4[1].replace(' ', '');
              utils.setStyle('--platform-color-4', formated);
            }
            let dark = css.match('--dark-color:' + '(.*?)' + ';');
            if (dark) {
              let formated = dark[1].replace(' ', '');
              utils.setStyle('--platform-dark-color', formated);
            }
            let light = css.match('--light-color:' + '(.*?)' + ';');
            if (light) {
              let formated = light[1].replace(' ', '');
              utils.setStyle('--platform-light-color', formated);
            }
            let golden = css.match('--default-golden-color:' + '(.*?)' + ';');
            if (golden) {
              let formated = golden[1].replace(' ', '');
              utils.setStyle('--platform-golden-color', formated);
            }
            let grey = css.match('--lightergrey-color:' + '(.*?)' + ';');
            if (grey) {
              let formated = grey[1].replace(' ', '');
              utils.setStyle('--platform-lightergrey-color', formated);
            }
            let menuBg = css.match('--app-menu_background:' + '(.*?)' + ';');
            if (!menuBg && color2 && dark) {
              let formatedColor2 = color2[1].replace(' ', '');
              let formatedDark = dark[1].replace(' ', '');
              utils.setStyle('--original-app-menu_background', 'linear-gradient(' + formatedColor2 + ',' + formatedDark + ')')
            } else if (!menuBg) {
              utils.setStyle('--original-app-menu_background', 'linear-gradient(var(--original-dark-color), var(--original-color-2))')
            }
            let title = css.match('--login-title:' + '(.*?)' + ';');
            if (title) {
              document.title = title[1];
            }
            let description = css.match('--login-title-description:' + '(.*?)' + ';');
            let meta = document.querySelector('meta[name="description"]');
            if (description && meta) {
              meta.content = description[1];
            }
            platformCSS.innerHTML = css;
            this.platformStyles = true;
            resolve();
          }).catch(err => {
            reject(err);
          });
      });
    }
  }

  _renderPage(page) {
    //const screenSize = window.innerWidth;
    // if (screenSize <= 990) {
    //   this.menuMax = false;
    // } else {
    //   this.menuMax = true;
    // }
    if (page && this._loadedPages[page]) {
      return html`${this._loadedPages[page]}`;
    } else {
      return html``;
    }
  }

  async _loadPage(page) {
    // Dynamically load page, if necessary
    if (this._loadedPages[page] == null) {
      await import(`./pages/${page}/${page}-page.js`);
      this._loadedPages[page] = new (customElements.get(`${page}-page`));
    }
    return this._loadedPages[page];
  }

  async changePage(ctx, page) {
    utils.getPendingAlerts().then(response => {
      this.pendingAlerts = response;
    });
    this.printMode = false;
    this.displayMsg = false;
    // If we were in another page previously...
    const prevPage = this.page;
    if (prevPage && this._loadedPages[prevPage] != null) {
      // ...exit that page
      this._loadedPages[prevPage].exit();
    }
    const pageInstance = await this._loadPage(page);
    this.page = page;
    // Once loaded, inform the page we are entering it
    pageInstance.enter(ctx);
  }

  _showHeader(page) {
    if (!page) {
      return false;
    } else {
      return page !== 'login';
    }
  }

  logout() {
    const user = utils.getAccountData();
    this.lastUser = user.uid ? user.uid : '';
    if (window.location.pathname != '/login')
      this.lastPage = '';
    window.utils.logout();
    this.customStyles = false;
    this.userInfo = {
      name: '',
      surname: ''
    };
    window.infiniaUserLang = '';
    resetLink();
    this.isLogged = false;
    page('/login');
  }

  render() {
    return html`
      ${this.platformStyles ? html`${platformCSS}` : html``}
      ${this.customStyles ? html`${customCSS}` : html``}
      <custom-icons-component></custom-icons-component>
      <div class='app ${this.printMode ? 'print-mode' : ''}'>
        <div class='app-menu ${this.menuMax ? '' : 'min'}' ?hidden=${!this._showHeader(this.page)}>
          <button class="app-menu__toggle empty" @click=${this.toggleMenu}>
            <iron-icon icon=${this.menuMax ? "chevron-left" : "chevron-right"}></iron-icon>
          </button>
          <div class='app-menu-logo'>
            ${this.menuMax ? html`
              <img src=${this.logoBig}>
            `: html`
              <img src=${this.logoSmall}>
            `}

          </div>
          <div class='app-menu-user'>
            <div class='app-menu-user-img'>
              <dropdown-menu-component
                orientation="right"
                .actions=${[{ text: 'myUser', value: 'myUser' }, { text: 'logout', value: 'logout' }, { text: 'downloadsManager', value: 'downloadsManager' } ]}
              >
                <img slot="icon" class="logo-user" src=${this.logoUser}>
              </dropdown-menu-component>
            </div>
            ${this.menuMax ? html`
              <div class='app-menu-user-info'>
                <span>${this.userInfo.name} ${this.userInfo.surname}</span>
              </div>
            `: html``}
          </div>
          <div class='app-menu-actions'>
            ${this.navList.map(item => html`
              ${item.link ? html`
                <span @click=${this.menuAction}>${utils.translate(item.cat)}</span>
              `: html`
                <div class=${this._getMenuParentClass(item, this.breadcrumbs)}>
                  <div class='app-menu-item-header' @click=${this.toggleLevel}>
                    <span>
                      ${this.menuMax ? html`
                        ${item.icon ? html`
                          <iron-icon class="app-menu-item-header-icon" icon="${item.icon}"></iron-icon>
                        `: html``}
                        <span>${utils.translate(item.cat)}</span>
                      `: html`
                        <dropdown-menu-component
                          .icon=${item.icon ? item.icon : 'settings'}
                          orientation="right"
                          .actions=${this.navList.filter(action => action.cat === item.cat)[0].actions}
                          .title=${item.cat}
                        ></dropdown-menu-component>
                      `}
                    </span>
                    ${this.menuMax ? html`
                      <iron-icon class="expand-icon" icon="expand-less"></iron-icon>
                    `: html``}
                  </div>
                  <div class='app-menu-subitems'>
                    <ul class='sublevel-1'>
                      ${item.subcat.map(cat => html`
                        ${cat.link ? html`
                          <li
                            class=${window.location.pathname == cat.link ? 'active-link' : ''}
                            data-link='${cat.link}'
                            @click=${this.navigate}
                          >${utils.translate(cat.cat)}</li>
                        `: html`
                          <li class=${this._getSubMenuParentClass(cat, this.breadcrumbs)}>
                            <span @click=${this.toggleLevel}>${utils.translate(cat.cat)}</span>
                            <span class='app-menu-subitems'>
                              <ul class='sublevel-2'>
                                ${cat.subcat.map(cat => html`
                                  <li
                                    class=${window.location.pathname == cat.link ? 'active-link' : ''}
                                    data-link='${cat.link}'
                                    @click=${this.navigate}
                                  >${utils.translate(cat.cat)}</li>
                                `)}
                              </ul>
                            </span>
                          </li>
                        `}
                      `)}
                    </ul>
                  </div>
                </div>
              `}
            `)}
          </div>
        </div>
        <div class='app-container ${this.menuMax ? '' : 'max'} ${this._showHeader(this.page) ? '' : 'no-background'}'>
          ${this.displayMsg ? html`
            <div class='app-messages ${this._showHeader(this.page) ? '' : 'full-msg'} ${this.menuMax || this.isLogged ? '' : 'max'} ${this.msgType == 'error' ? 'app-messages-error' : this.msgType == 'warning' ? 'app-messages-warning' : 'app-messages-succes'}'>
              <span class='app-message-intro'>
                ${this.msgType == 'error' ? html`
                  <iron-icon icon='custom:warning-msg'></iron-icon>
                  <span>${utils.translate('error')}</span>
                `: this.msgType == 'warning' ? html`
                  <iron-icon icon='add-alert'></iron-icon>
                  <span>${utils.translate('warning')}</span>
                `: html`
                  <iron-icon icon='custom:success-msg'></iron-icon>
                  <span>${utils.translate('congrats')}</span>
                `}
              </span>
              <span class='app-message'>${utils.toHTML(utils.translate(this.msgTxt))}</span>
              <button class='uppercase' @click='${this.closeMsg}'>${utils.translate('accept')}</button>
            </div>
          `: html``}
          ${this.page != 'login' ? html `
            <header ?hidden=${!this._showHeader(this.page)}>
              <div class="breadcrumbs">
                <div class="breadcrumb"><a href=${"/dashboard"}><b>Home</b></a></div>
                ${this.breadcrumbs.map(breadcrumb => {
                  if (breadcrumb.href) {
                    return html`<div class="breadcrumb"><a href=${breadcrumb.href}>${utils.translate(breadcrumb.label)}</a></div>`;
                  } else {
                    return html`<div class="breadcrumb">${utils.translate(breadcrumb.label)}</div>`;
                  }
                })}
              </div>
              <div class='flex flex-end no-resp header-icons'>
                <div @click=${this.goToUser}>
                  <iron-icon icon='face' class="logout"></iron-icon>
                </div>
                <div @click=${this.goToDownloads}>
                  <iron-icon icon='archive' class="logout"></iron-icon>
                </div>
                <div class='pending-alerts-container logout' @click=${this.goToInbox}>
                  <iron-icon icon='mail' class="logout"></iron-icon>
                  ${this.pendingAlerts > 0 ? html`
                    <span class='pending-alerts-amount'>${this.pendingAlerts > 9 ? '+9' : this.pendingAlerts}</span>
                  `: ``}
                </div>
                <div>
                  <iron-icon icon='power-settings-new' class="logout" @click=${this.logout.bind(this)}></iron-icon>
                </div>
              </div>
            </header>
          `:``}
          <div class='page-container ${this._showHeader(this.page) ? 'with-bg' : ''}'>
            ${this._renderPage(this.page)}
          </div>
        </div>
        ${!this.printMode && this.displayVideoBg ? html`
          <div class='app-bg'></div>
          <video autoplay muted loop id="videoBg">
            <source src="https://infinia-demo.s3.eu-west-1.amazonaws.com/logos/video_test.mp4" type="video/mp4">
          </video>
        `: html``}
      </div>
      <change-password-component></change-password-component>
      <dialog-component></dialog-component>
      <div class="page-loading ${this.isLoading ? '' : 'hidden'}">
        <loader-component .msg=${this.loaderMsg}></loader-component>
      </div>
    `;
  }
  navigate(e) {
    this.shadowRoot.querySelectorAll('.active-link').forEach(item => {
      item.classList.remove('active-link');
    });
    let item = e.target.closest('.parent-item');
    this.shadowRoot.querySelectorAll('.parent-item').forEach(parent => {
      if (item && !parent.contains(item))
        parent.classList.add('collapsed')
    });
    e.target.classList.add('active-link');
    let event = new CustomEvent('navigate-to', {
      bubbles: true,
      composed: true,
      detail: e.target.dataset.link
    });
    this.dispatchEvent(event);
  }
  toggleLevel(e) {
    let item = e.target.closest('.parent-item');
    if (this.menuMax) {
      // this.shadowRoot.querySelectorAll('.parent-item').forEach(parent => {
      //   if (!parent.contains(item))
      //     parent.classList.add('collapsed')
      // });
      item.classList.toggle('collapsed');
    } else {
      this.collapseMenu();
    }

  }
  collapseMenu() {
    this.shadowRoot.querySelectorAll('.parent-item').forEach(parent => {
      parent.classList.add('collapsed')
    });
  }
  toggleMenu() {
    this.menuMax = !this.menuMax;
    if (!this.menuMax) {
      this.collapseMenu()
    }
    let event = new CustomEvent('menu-toggled', {
      bubbles: true,
      composed: true
    });
    this.dispatchEvent(event);

  }
  scrollTo(top) {
    const scrollElement = this.shadowRoot.querySelector('.page-container');
    scrollElement.scrollTo({ top, behavior: 'smooth' });
  }
  closeMsg() {
    if (this.alerts.length) {
      utils.markAsRead(this.alerts).then(() => {
        utils.getPendingAlerts().then(response => {
          this.pendingAlerts = response;
        });
      });
    }
    this.displayMsg = false;
  }
  _getMenuParentClass(item, breadcrumbs) {
    let ret = 'app-menu-item parent-item';
    const path = breadcrumbs.map(el => el.label);
    let includedPaths = [];
    if (item.cat === 'planification') {
      includedPaths = ['areas', 'audiences', 'planner', 'orders', 'supportAnalysis', 'segments'];
    } else if (item.cat === 'campaigns') {
      includedPaths = ['scheduledReports', 'digitalCampaigns', 'oohCampaigns', 'oohSubcampaigns', 'outdoorCampaigns', 'mailingCampaigns', 'xandrCampaigns', 'attribution', 'reportings', 'dts'];
    } else if (item.cat === 'outdoor') {
      includedPaths = ['affinityAnalysis', 'extrapolationQuery', 'extrapolationDailyQuery', 'supportMeasurement'];
    } else if (item.cat === 'salesPoints') {
      includedPaths = ['indoorAnalytics', 'benchmark'];
    } else if (item.cat === 'publishers') {
      includedPaths = ['publishersReporting', 'ctvAnalytics'];
    } else if (item.cat === 'conversionFunnels') {
      includedPaths = ['conversionFunnels'];
    } else if (item.cat === 'management') {
      includedPaths = ['users', 'agencies', 'advertisers', 'creatives', 'publishers', 'vendors', 'trackers', 'retailers', 'digitalEvents', 'promotions', 'creativesPromotions', 'supportsManagement', 'creativesDOOH', 'filters', 'customReporting'];
    } else if (item.cat === 'monitoring') {
      includedPaths = ['metrics', 'processStatus', 'enrichment'];
    } else if (item.cat === 'dashboard') {
      includedPaths = ['campaignsDashboard', 'audiencesDashboard'];
    }

    const intersection = includedPaths.filter(el => path.includes(el));
    if (intersection.length < 1) {
      ret += ' collapsed';
    }
    return ret;
  }
  _getSubMenuParentClass(item, breadcrumbs) {
    let ret = 'parent-item';
    const path = breadcrumbs.map(el => el.label);
    let includedPaths = [item.cat];
    if (item.cat === 'campaignReporting') {
      if (!(path.includes('digitalCampaigns') && (path.includes('create') || path.includes('scheduledReports')))) {
        includedPaths = ['digitalCampaigns', 'mailingCampaigns'];
      }
    }
    const intersection = includedPaths.filter(el => path.includes(el));
    if (intersection.length < 1) {
      ret += ' collapsed';
    }
    return ret;
  }

  exportToPdf(e) {
    e.preventDefault();
    this.printMode = true;
    this.basicPrint = true;
    const _this = this;
    setTimeout(() => {
      window.print();
      this.dispatchEvent(new CustomEvent('reset-to-screen', {
        bubbles: true,
        composed: true
      }));
    }, 1000);
  }

  resetToScreen(e) {
    e.preventDefault();
    if(this.basicPrint)
      this.printMode = false;
    this.basicPrint = false;
  }

  goToInbox(e) {
    e.preventDefault();
    if(this.page != 'inbox')
      this.dispatchEvent(new CustomEvent('navigate-to', {
        bubbles: true,
        composed: true,
        detail: '/inbox'
      }));
  }

  goToDownloads(e) {
    e.preventDefault();
    if(this.page != 'downloads-manager')
      this.dispatchEvent(new CustomEvent('navigate-to', {
        bubbles: true,
        composed: true,
        detail: '/downloadsManager'
      }));
  }

  goToUser(e) {
    e.preventDefault();
    if(this.page != 'user-lite-edition')
    this.dispatchEvent(new CustomEvent('navigate-to', {
      bubbles: true,
      composed: true,
      detail: '/myUser'
    }));
  }

  displayAlerts(e) {
    this.alerts = e.detail;
    if (this.alerts.length) {
      let msg = '<ul>';
      this.alerts.forEach(alert => {
        msg = msg.concat(`<li>${alert.createdAt ? `<span class='h3'>${utils.getFormattedDate(alert.createdAt, '-', true)} :</span>` : ''}  ${alert.message}</li>`)
      });
      msg = msg.concat('</ul>');
      this.dispatchEvent(new CustomEvent('show-msg', {
        bubbles: true,
        composed: true,
        detail: {
          txt: msg,
          type: 'warning'
        }
      }));
    }
  }

  updatePending() {
    utils.getPendingAlerts().then(response => {
      this.pendingAlerts = response;
    });
    this.requestUpdate();
  }
}
customElements.define('infinia-app', InfiniaApp);

// Click handler
// Basically meant to manage <a> clicks directed to other pages within our app
document.body.addEventListener('click', e => {
  if (e.defaultPrevented || e.button !== 0 ||
    e.metaKey || e.ctrlKey || e.shiftKey) return;

  const anchor = e.composedPath().filter(
    n => n.tagName === 'A'
  )[0];
  if (!anchor || anchor.target ||
    anchor.hasAttribute('download') ||
    anchor.getAttribute('rel') === 'external') return;

  const href = anchor.href;
  if (!href || href.indexOf('mailto:') !== -1) return;

  const location = window.location;
  const origin = location.origin || location.protocol + '//' + location.host;
  if (href.indexOf(origin) !== 0) return;

  e.preventDefault();
  if (href !== location.href) {
    page(anchor.getAttribute('href'));
  }


});


window.infiniaApp = new InfiniaApp();
