import page from 'page';

export { startRouting, addRoute, linkRequest, resetLink };

// addRoute polyfill
// From https://github.com/visionmedia/page.js/issues/296
page.addRoute = function(path) {
  const route = new page.Route(/** @type {string} */ (path), {}, {});
  for (let i = 1; i < arguments.length; ++i) {
    page.callbacks.splice(1,0,route.middleware(arguments[i]));
  }
};

// Define pages that can be accessed without authentication
let publicPages = ['/login'];

function startRouting(app) {
  // Utils - parse search params
  page('*', (ctx, next) => {

    const query = new URLSearchParams(ctx.querystring);
    ctx.query = {};
    for (let key of query.keys()) {
      ctx.query[key] = query.get(key);
    }
    next();
  });

  // Handles authentication
  page('*', (ctx, next) => {

    let isPublicPage = false;
    let path;
    for (path of publicPages) {
      if (ctx.path.startsWith(path)) {
        isPublicPage = true;
        break;
      }
    }

    const hasSession = utils.hasSession();
    const init = utils.getInit();
    
    if (hasSession && isPublicPage) {
      if(init) {
        page.redirect(init);
      } else {
        page.redirect('/dashboard');
      }
      
      // TODO: Change to Dashboard when finish: page.redirect('/dashboard');
    } else if (!hasSession && !isPublicPage) {
      if (window.location.pathname != '/login')
        localStorage.setItem('urlAttempt', window.location.pathname);
      page.redirect('/login');
    } else {
      next();
    }
  });

  // Redirections
  page('/', () => {

    const hasSession = utils.hasSession();
    const init = utils.getInit();
    if (hasSession && init) {
      page.redirect(init);
    } else if (hasSession) {
      page.redirect('/dashboard');
      // TODO: Change to Dashboard when finish: page.redirect('/dashboard');
    } else {
      page.redirect('/login');
    }
  });

  // Pages
  page('/login', (ctx) => {
    app.changePage(ctx, 'login');
  });
  page('/error/access', (ctx) => {
    app.changePage(ctx, 'error-access');
  });
  page('/clusters', (ctx) => {
    app.changePage(ctx, 'clusters');
  });
  page('/clusters/:id/profile', (ctx) => {
    app.changePage(ctx, 'cluster-profile');
  });
  page('/clusters/edition/:id', (ctx) => {
    app.changePage(ctx, 'cluster-edition');
  });
  page('/clusters/edition/', (ctx) => {
    app.changePage(ctx, 'cluster-edition');
  });
  page('/clusters/upload/', (ctx) => {
    app.changePage(ctx, 'cluster-edition-upload');
  });
  page('/clusters/combination/', (ctx) => {
    app.changePage(ctx, 'cluster-edition-intersection');
  });
  page('/agencies', (ctx) => {
    app.changePage(ctx, 'agencies');
  });
  page('/advertisers', (ctx) => {
    app.changePage(ctx, 'advertisers');
  });
  page('/publishers', (ctx) => {
    app.changePage(ctx, 'publishers');
  });
  page('/vendors', (ctx) => {
    app.changePage(ctx, 'vendors');
  });
  page('/trackers', (ctx) => {
    app.changePage(ctx, 'trackers');
  });
  page('/retailers', (ctx) => {
    app.changePage(ctx, 'retailers');
  });
  page('/retailers/:id/sections', (ctx) => {
    app.changePage(ctx, 'sections');
  });
  page('/creatives', (ctx) => {
    app.changePage(ctx, 'creatives');
  });
  page('/campaigns', (ctx) => {
    app.changePage(ctx, 'campaigns');
  });
  page('/campaigns/scheduledReports', (ctx) => {
    app.changePage(ctx, 'campaigns-report');
  });
  page('/campaigns/scheduledReports/new', (ctx) => {
    app.changePage(ctx, 'campaigns-report-new');
  });
  page('/campaigns/scheduledReports/:id', (ctx) => {
    app.changePage(ctx, 'campaigns-report-new');
  });
  page('/campaigns/reporting/:id', (ctx) => {
    app.changePage(ctx, 'campaigns-reporting');
  });
  page('/outdoorCampaigns', (ctx) => {
    app.changePage(ctx, 'campaigns-outdoor');
  });
  page('/outdoorCampaigns/new', (ctx) => {
    app.changePage(ctx, 'campaigns-outdoor-new');
  });
  page('/outdoorCampaigns/new/:id', (ctx) => {
    app.changePage(ctx, 'campaigns-outdoor-new');
  });
  page('/outdoorCampaigns/:id/reporting', (ctx) => {
    app.changePage(ctx, 'campaigns-outdoor-reporting');
  });
  page('/outdoorCampaigns/:id/briefing', (ctx) => {
    app.changePage(ctx, 'campaigns-outdoor-briefing');
  });
  page('/dashboard', (ctx) => {
    app.changePage(ctx, 'dashboard');
  });
  page('/areas', (ctx) => {
    app.changePage(ctx, 'areas');
  });
  page('/areas/edition/', (ctx) => {
    app.changePage(ctx, 'areas-edition');
  });
  page('/areas/edition/:id', (ctx) => {
    app.changePage(ctx, 'areas-edition');
  });
  page('/indoor-analytics', (ctx) => {
    app.changePage(ctx, 'indoor-analytics')
  });
  page('/campaigns/new', (ctx) => {
    app.changePage(ctx, 'campaigns-new')
  });
  page('/campaigns/new/:id', (ctx) => {
    app.changePage(ctx, 'campaigns-new');
  });
  page('/campaigns/:id/order-lines', (ctx) => {
    app.changePage(ctx, 'order-lines');
  });
  page('/campaigns/:id/briefing', (ctx) => {
    app.changePage(ctx, 'briefing');
  });
  page('/campaigns/inhouse/new', (ctx) => {
    app.changePage(ctx, 'campaigns-inhouse-new')
  });
  page('/campaigns/inhouse/new/:id', (ctx) => {
    app.changePage(ctx, 'campaigns-inhouse-new');
  });
  page('/creatives/edition/:id', (ctx) => {
    app.changePage(ctx, 'creatives-edition');
  });
  page('/creatives/edition/', (ctx) => {
    app.changePage(ctx, 'creatives-edition');
  });
  page('/advertisers/edition/:id', (ctx) => {
    app.changePage(ctx, 'advertisers-edition');
  });
  page('/advertisers/edition/', (ctx) => {
    app.changePage(ctx, 'advertisers-edition');
  });
  page('/agencies/edition/:id', (ctx) => {
    app.changePage(ctx, 'agencies-edition');
  });
  page('/agencies/edition/', (ctx) => {
    app.changePage(ctx, 'agencies-edition');
  });
  page('/publishers/edition/:id', (ctx) => {
    app.changePage(ctx, 'publishers-edition');
  });
  page('/publishers/edition/', (ctx) => {
    app.changePage(ctx, 'publishers-edition');
  });
  page('/publishers/reporting/', (ctx) => {
    app.changePage(ctx, 'publishers-reporting');
  });
  page('/publishers/ctv/', (ctx) => {
    app.changePage(ctx, 'publishers-ctv');
  });
  page('/campaigns/:id/order-lines/new', (ctx) => {
    app.changePage(ctx, 'order-lines-new');
  });
  page('/vendors/edition/:id', (ctx) => {
    app.changePage(ctx, 'vendors-edition');
  });
  page('/vendors/edition/', (ctx) => {
    app.changePage(ctx, 'vendors-edition');
  });
  page('/trackers/edition/:id', (ctx) => {
    app.changePage(ctx, 'trackers-edition');
  });
  page('/trackers/edition/', (ctx) => {
    app.changePage(ctx, 'trackers-edition');
  });
  page('/retailers/:retailerId/sections/:sectionId/trackers/edition/', (ctx) => {
    app.changePage(ctx, 'trackers-edition');
  });
  page('/retailers/:retailerId/sections/:id/trackers', (ctx) => {
    app.changePage(ctx, 'trackers');
  });
  page('/retailers/edition/:id', (ctx) => {
    app.changePage(ctx, 'retailers-edition');
  });
  page('/retailers/edition/', (ctx) => {
    app.changePage(ctx, 'retailers-edition');
  });
  page('/retailers/:retailerId/sections/edition/:id', (ctx) => {
    app.changePage(ctx, 'sections-edition');
  });
  page('/retailers/:retailerId/sections/edition', (ctx) => {
    app.changePage(ctx, 'sections-edition');
  });
  page('/campaigns/distribution', (ctx) => {
    app.changePage(ctx, 'campaigns-distribution');
  });
  page('/campaigns/distribution/reporting/:id', (ctx) => {
    app.changePage(ctx, 'campaigns-distribution-reporting');
  });
  page('/campaigns/distribution/attribution/:id', (ctx) => {
    app.changePage(ctx, 'campaigns-distribution-attribution');
  });
  page('/extrapolation', (ctx) => {
    app.changePage(ctx, 'extrapolation');
  });
  page('/outdoor_planner', (ctx) => {
    app.changePage(ctx, 'extrapolation-planner');
  });
  page('/extrapolation/daily', (ctx) => {
    app.changePage(ctx, 'extrapolation-daily');
  });
  page('/outdoor_planner/filters', (ctx) => {
    app.changePage(ctx, 'planner-filters');
  });
  page('/benchmark', (ctx) => {
    app.changePage(ctx, 'benchmark');
  });
  page('/digitalEvents', (ctx) => {
    app.changePage(ctx, 'digital-events');
  });
  page('/digitalEvents/edition/:id', (ctx) => {
    app.changePage(ctx, 'digital-events-edition');
  });
  page('/digitalEvents/edition/', (ctx) => {
    app.changePage(ctx, 'digital-events-edition');
  });
  page('/users', (ctx) => {
    app.changePage(ctx, 'users');
  });
  page('/users/edition/:id', (ctx) => {
    app.changePage(ctx, 'users-edition');
  });
  page('/users/edition/', (ctx) => {
    app.changePage(ctx, 'users-edition');
  });
  page('/myUser', (ctx) => {
    app.changePage(ctx, 'user-lite-edition');
  });
  page('/funnels/new', (ctx) => {
    app.changePage(ctx, 'funnel-new');
  });
  page('/conversionFunnels', (ctx) => {
    app.changePage(ctx, 'conversion-funnels');
  });
  page('/funnels/view/:id', (ctx) => {
    app.changePage(ctx, 'funnel-view');
  });
  page('/supportMeasurement', (ctx) => {
    app.changePage(ctx, 'support-measurement');
  });
  page('/orderPdf', (ctx) => {
    app.changePage(ctx, 'order-pdf');
  });
  page('/creativesAssets', (ctx) => {
    app.changePage(ctx, 'creatives-assets');
  });
  page('/creativesAssets/edition/:id', (ctx) => {
    app.changePage(ctx, 'creatives-assets-edition');
  });
  page('/promotions', (ctx) => {
    app.changePage(ctx, 'promotions');
  });
  page('/promotions/edition/', (ctx) => {
    app.changePage(ctx, 'promotions-edition');
  });
  page('/promotions/edition/:id', (ctx) => {
    app.changePage(ctx, 'promotions-edition');
  });
  page('/affinityAnalysis', (ctx) => {
    app.changePage(ctx, 'affinity-analysis');
  });
  page('/supportsManagement', (ctx) => {
    app.changePage(ctx, 'supports-management');
  });
  page('/supportManagement/:id/edit', (ctx) => {
    app.changePage(ctx, 'support-edition');
  });
  page('/oohCampaigns', (ctx) => {
    app.changePage(ctx, 'ooh-campaigns');
  });
  page('/oohCampaigns/edition/', (ctx) => {
    app.changePage(ctx, 'ooh-campaigns-edition');
  });
  page('/oohCampaigns/edition/:id', (ctx) => {
    app.changePage(ctx, 'ooh-campaigns-edition');
  });
  page('/oohCampaigns/:campaignId/:type/line/:id', (ctx) => {
    app.changePage(ctx, 'ooh-campaigns-line');
  });
  page('/oohCampaigns/:campaignId/line/:id/review', (ctx) => {
    app.changePage(ctx, 'ooh-campaigns-line-review');
  });
  page('/oohCampaigns/:campaignId/:type/line/', (ctx) => {
    app.changePage(ctx, 'ooh-campaigns-line');
  });
  page('/doohCampaign/:id/reporting', (ctx) => {
    app.changePage(ctx, 'campaigns-dooh-reporting');
  });
  page('/doohCampaign/:id/lines/:campaignLineId/reporting', (ctx) => {
    app.changePage(ctx, 'campaigns-dooh-reporting');
  });
  page('/oohCampaigns/briefing/:id', (ctx) => {
    app.changePage(ctx, 'ooh-campaigns-briefing');
  });
  page('/oohSubcampaigns', (ctx) => {
    app.changePage(ctx, 'ooh-subcampaigns');
  });
  page('/oohSubcampaigns/:campaignId/lines/:campaignLineId/subcampaigns/:subcampaignLineId/reporting', (ctx) => {
    app.changePage(ctx, 'ooh-subcampaigns-reporting');
  });
  page('/xandrCampaigns', (ctx) => {
    app.changePage(ctx, 'xandr-campaigns');
  });
  page('/xandrCampaigns/:id/reporting', (ctx) => {
    app.changePage(ctx, 'xandr-campaigns-reporting');
  });
  page('/creatives/advertisers', (ctx) => {
    app.changePage(ctx, 'creatives-dooh-advertisers');
  });
  page('/creatives/advertisers/:id', (ctx) => {
    app.changePage(ctx, 'creatives-dooh-folders');
  });
  page('/creatives/advertisers/:advertisersId/folder/new', (ctx) => {
    app.changePage(ctx, 'creatives-dooh-folders-edition');
  });
  page('/creatives/advertisers/:advertisersId/folder/:id', (ctx) => {
    app.changePage(ctx, 'creatives-dooh-folders-edition');
  });
  page('/creatives/advertisers/folder/:id', (ctx) => {
    app.changePage(ctx, 'creatives-dooh');
  });
  page('/creatives/advertisers/folder/:id/creative/new', (ctx) => {
    app.changePage(ctx, 'creatives-dooh-edition');
  });
  page('/creatives/advertisers/folder/:id/creative/:idCreative', (ctx) => {
    app.changePage(ctx, 'creatives-dooh-edition');
  });
  page('/downloadsManager', (ctx) => {
    app.changePage(ctx, 'downloads-manager');
  });
  page('/metrics', (ctx) => {
    app.changePage(ctx, 'metrics');
  });
  page('/inbox', (ctx) => {
    app.changePage(ctx, 'inbox');
  });
  page('/processStatus', (ctx) => {
    app.changePage(ctx, 'process-status');
  });
  page('/customReporting', (ctx) => {
    app.changePage(ctx, 'custom-reporting');
  });
  page('/attribution', (ctx) => {
    app.changePage(ctx, 'attribution');
  });
  page('/attribution/edition/', (ctx) => {
    app.changePage(ctx, 'attribution-edition');
  });
  page('/attribution/reporting/:id', (ctx) => {
    app.changePage(ctx, 'attribution-reporting');
  });
  page('/enrichment', (ctx) => {
    app.changePage(ctx, 'enrichment');
  });
  page('/campaignsDashboard', (ctx) => {
    app.changePage(ctx, 'campaigns-dashboard');
  });
  page('/audiencesDashboard', (ctx) => {
    app.changePage(ctx, 'audiences-dashboard');
  });
  page('/reportings', (ctx) => {
    app.changePage(ctx, 'reportings');
  });
  page('/segments', (ctx) => {
    app.changePage(ctx, 'segments');
  });
  page('/dts', (ctx) => {
    app.changePage(ctx, 'dts');
  });
  page('/dts/reporting/:id', (ctx) => {
    app.changePage(ctx, 'dts-reporting');
  });
  // Not found handler - for the time being, simply redirect to home page
  page('*', '/');

  page.start({
    click: false
  });
}

function addRoute({ route, isPublic = false, cb } = {}) {
  if (isPublic) {
    publicPages.push(route);
  }
  page.addRoute(route, cb);
}

function linkRequest() {
  return localStorage.getItem('urlAttempt');
}

function resetLink() {
  localStorage.setItem('urlAttempt', '');
}
